import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { differenceInYears, 
    addYears, 
    differenceInMonths, 
    addMonths, 
    differenceInDays,
    addDays
    } from 'date-fns';


const MuiGradCount = (props) => {

    const { date, name, start } = props;

    const [countdown, setCountdown] = React.useState({
        years: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
        millisec: 0,
    });

    React.useEffect(() => {

        const ndate = calculateCountdown(date, start);
        if(ndate){
            setCountdown(ndate);
        }
        /* const interval = setInterval(() => {
            const ndate = calculateCountdown(date, start);
            if(ndate){
                setCountdown(ndate);
            }
        }, 1000);
        return () => clearInterval(interval); */
    },[date, start, name]);


    const dateFnsCountdown = (endDate, startDate) => {
        var x = startDate;
        var y = endDate;        
        var temp;
        temp = differenceInYears(y, x);
        var result = temp + " Years ";
        x = addYears(x, temp);
        temp = differenceInMonths(y, x);
        result = result + temp + " Months ";
        x = addMonths(x, temp);
        /* temp = differenceInDays(y, x);
        result = result + temp + " Days"; */

        return result;
    }

    const calculateCountdown = (endDate, startDate) => {

        /* let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date(start))) / 1000; */

        let diff = (Date.parse(endDate) - Date.parse(startDate)) / 1000;

        if(diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
        }

        if (diff >= (365.25 * 86400)){
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400){
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600){
            timeLeft.hours = Math.floor(diff /3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60){
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        return timeLeft;
    }

    const addLeadingZeros = (value) => {
        value = String(value);
        while(value.length < 2) {
            value = '0' + value;
        }
        return value;
    }


    return(
        <Card style={{ margin: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.5', width: '350px' }}>
            <CardContent>
                <Typography variant='h3' style={{ fontSize: '1.5rem' }} >
                    {name}
                </Typography>
                 <Typography variant='h4' style={{ fontSize: '1.25rem' }}>
                    {dateFnsCountdown(date, start)}
                </Typography>
            </CardContent>
        </Card>
    )

}

export default MuiGradCount;