import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import CloseIcon from '@material-ui/icons/Close';


const GradDialog = (props) => {

    const { openMdcGrad, closeMdcGradCallback, semesters, mdcMaxTerm, mdcGrad, setMDCGrad } = props;

     
    const [selectedSem, setSelectedSem] = React.useState()

    React.useEffect(() => {
        if(mdcGrad){
            setSelectedSem(mdcGrad - 1);
        } else {
            setSelectedSem(mdcMaxTerm - 1);
        }
    }, [mdcMaxTerm, mdcGrad]);

    const handleSemesterChange = (event) => {
        setSelectedSem(event.target.value);
    } 

    const handleSetGradSem = () => {
        setMDCGrad(parseInt(selectedSem)+1);
        closeMdcGradCallback();
    }

    return(
        <Dialog open={openMdcGrad} onClose={closeMdcGradCallback} maxWidth='sm' fullWidth>
            <DialogTitle>
                <Grid container justify='space-between'>
                    <Grid item>
                        <Typography variant='h6'>
                            Miami Dade College 
                        </Typography>
                        <Typography variant='h6'>
                            Anticipated Graduation Term
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color='secondary' onClick={closeMdcGradCallback}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems="center" justify="center" style={{ marginBottom: '15px' }}>
                    <Grid item md={4} xs={12} />
                    <Grid item xs={12} md={4} style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <TextField 
                            select
                            /* label="Pathway" */
                            value={selectedSem}
                            onChange={handleSemesterChange}
                            fullWidth
                        >
                            {semesters.map((sem) => (
                                <MenuItem key={sem.index} value={sem.index}>
                                    {sem.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={4} xs={12} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSetGradSem} color='primary'>
                    Set Term
                </Button>
                <Button onClick={closeMdcGradCallback} color='secondary'>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default GradDialog;