import React, { useState, useRef } from 'react';
import axios from 'axios';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
//import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
/* import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'; */
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Remove';
import VisibleIcon from '@material-ui/icons/Visibility';
import HideIcon from '@material-ui/icons/VisibilityOff';
import ListIcon from '@material-ui/icons/List';
import ResetIcon from '@material-ui/icons/RotateLeft';
import RegisterIcon from '@material-ui/icons/AddToQueue';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
/* import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip'; */
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';

import LoginDialog from './logindialog';
import MuiLogin from './mui-login';
import ExemptDialog from './ExemptDialog';

import Video from './video';
/* import fiuwhitelogo from '../images/fiu-white-logo.png';
import mdclogo from '../images/mdc-logo.png'; */
import mdcDecoBlue from '../images/deco-stripes-blue.png';
import mdclogoblue from '../images/MDCLogo-Blue.png';
import fiuBlueLogo from '../images/fiu-main-logo.png';

/* import fiuClasses from './fiu_sample_bio';
import mdcAABio from './mdc_aa_bio'; */
//import fiuBABio from './fiu_ba_bio';
/* import MuiGradIcon from './mui-gradicon';
import mdcTestData from './mdc_bio_test'; */

import CostSpeedo from './cost-speedo';
import { Link } from 'react-router-dom';

import GradDialog from './GradDialog';
import ProgramDialog from './ProgramDialog/index';
import RemoveProgramDialog from './RemoveProgramDialog';
import LegendDialog from './LegendDialog';
import ResetDialog from './ResetDialog';
import GradIcon from './GradIcon';
/* import { set } from 'js-cookie'; */
import MuiSnackbar, { openMuiSnackbar } from './MuiSnackbar';
import { openMaxCreditAlertDialog } from './MaxCreditAlertDialog';
import { CreditCardSharp } from '@material-ui/icons';

/* const fabactions = [
    { icon: <AddIcon />, name: 'Course' },
    { icon: <AddIcon />, name: 'Semester' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <SaveIcon />, name: 'Save'}
]; */

const courseList = []

const fiuBABio = []

const semesterList = [
    {
        id: 's1',
        index: 0,
        name: 'Term 1',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's2',
        index: 1,
        name: 'Term 2',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's3',
        index: 2,
        name: 'Term 3',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's4',
        index: 3,
        name: 'Term 4',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's5',
        index: 4,
        name: 'Term 5',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's6',
        index: 5,
        name: 'Term 6',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's7',
        index: 6,
        name: 'Term 7',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's8',
        index: 7,
        name: 'Term 8',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    }, 
    {
        id: 's9',
        index: 8,
        name: 'Term 9',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's10',
        index: 9,
        name: 'Term 10',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's11',
        index: 10,
        name: 'Term 11',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
]

const longSemesterList = [
    {
        id: 's1',
        index: 0,
        name: 'Term 1',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's2',
        index: 1,
        name: 'Term 2',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's3',
        index: 2,
        name: 'Term 3',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's4',
        index: 3,
        name: 'Term 4',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's5',
        index: 4,
        name: 'Term 5',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's6',
        index: 5,
        name: 'Term 6',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's7',
        index: 6,
        name: 'Term 7',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's8',
        index: 7,
        name: 'Term 8',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    }, 
    {
        id: 's9',
        index: 8,
        name: 'Term 9',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's10',
        index: 9,
        name: 'Term 10',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's11',
        index: 10,
        name: 'Term 11',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's12',
        index: 10,
        name: 'Term 12',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
    {
        id: 's13',
        index: 10,
        name: 'Term 13',
        completed: false,
        hours: 0,
        cost: 0,
        removeable: false,
        termdate: new Date()
    },
]

const classes = {
    board: {
        display: 'flex',
        margin: '0 auto',
        fontFamily: 'Arial, "Helvetica Neue", sans-serif',
        width: '100%'
    },
    column: {
        minWidth: 210,
        minHeight: 1000,
        width: '10vw',
        height: '100%',
        margin: '0 auto',
        //backgroundColor: '#005dab',
        //backgroundImage: `url(${mdcDecoBlue})`
    },
    exemptColumn: {
        minWidth: 210,
        minHeight: 150,
        height: '100%',
        margin: '0 auto',
        paddingTop: 2,
        paddingBottom: 2,
        //backgroundColor: '#005dab',
        //backgroundImage: `url(${mdcDecoBlue})`
    },
    columnGrad: {
        minWidth: 210,
        minHeight: 1000,
        width: '10vw',
        height: '100%',
        margin: '0 auto',
        //backgroundColor: '#005dab',
        //backgroundImage: `url(${mdcDecoBlue})`,
        borderRight: '5px solid #f44336'
    },
    columnHead: {
        textAlign: 'center',
        padding: 0,
        paddingTop: 5,
        paddingBottom: 0,
        fontSize: '1.2rem',
        backgroundColor: '#222',
        color: 'white',
    },
    columnHeadDualE: {
        textAlign: 'center',
        padding:0,
        paddingTop: 5,
        paddingBottom: 0,
        fontSize: '1.2rem',
        /* backgroundColor: '#A9A9A9', */
        backgroundColor: '#757575',
        color: 'white',
    },
    columnFoot: {
        textAlign: 'center',
        padding: 10,
        fontSize: '1.2rem',
        backgroundColor: '#222',
        width: '10vw',
        position: 'absolute',
        bottom: 35,
        color: 'white'
    },
    item: {
        padding: 10,
        margin: 10,
        fontSize: '0.8em',
        cursor: 'pointer',
        backgroundColor: 'white',
        textAlign: 'center'
    },
}

const tstStyles = theme => ({
    button: {
        textTransform: 'capitalize', 
        fontSize: '0.75em',
        '&:focus': {
            fontWeight: 'bold',
            zIndex: '999'
        }
    },
    child: {
        //backgroundColor: '#BFD7E9',
        backgroundColor: 'rgba(191, 215, 233, .7)'
    }
});

const testButton = ({ classes, ...other }) => {
    const { button: buttonClass, ...rippleClasses } = classes;
    return(
        <Button className={buttonClass} TouchRippleProps={{ classes: rippleClasses }} {...other} />
    )
}

const SemItemButton = withStyles(tstStyles)(testButton);


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return(
        <div 
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </div>
    )
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ClassDialog = ({ course, open, close, courses, setCourses, basePathway, postReqLoop, completed, exempted, semesters, removedCourses, setRemovedCourses, startDate }) => {
    
    const [optSelect, setOptSelect] = React.useState('');
    const [thisCourse, setThisCourse] = React.useState(null);
    const [validSub, setValidSub] = React.useState(true);
    
    const closeCallback = () => {
        close();
        setThisCourse(null);
        setOptSelect('');
    }

    const selectChange = (event) => {
        setOptSelect(event.target.value);
        var cinfo = event.target.value;
        var cPrefix = getCoursePrefix(cinfo);
        var cNumber = getCourseNumber(cinfo);
        setThisCourse({ ...thisCourse, CoursePrefix: cPrefix, CourseNumber: cNumber });
        var tCourse = { ...course, CoursePrefix: cPrefix, CourseNumber: cNumber }
        var cArray = courses.filter(c => c.Title !== thisCourse.Title);
        cArray.push(tCourse);
        setCourses(cArray);
    }

    const selectSubChange = async (event) => {
        setOptSelect(event.target.value);
        let cinfo = event.target.value;
        let cPrefix = getCoursePrefix(cinfo);
        let cNumber = getCourseNumber(cinfo);
        const courseSubQuery = await axios({
            method: 'post',
            data: {
                CoursePrefix: cPrefix,
                CourseNumber: cNumber,
                BasePathway: basePathway
            },
            withCredentials: true,
            url: '/coursesub'
        });
        let courseSub = courseSubQuery.data;
        courseSub = {...courseSub, TERM: thisCourse.TERM, SheetName: 'ACSG' }
        let coursesWO = courses.filter((c) => c.id !== thisCourse.id);
        coursesWO.push(courseSub);

        let rCourse = { ...thisCourse, subCourse: { id: courseSub.id, CoursePrefix: courseSub.CoursePrefix, CourseNumber: courseSub.CourseNumber, Title: courseSub.Title, TERM: courseSub.TERM, SheetName: courseSub.SheetName }}
        setRemovedCourses([ ...removedCourses, rCourse ]);

        if(thisCourse.COREQ){
            var thisCoPrefix = getCoursePrefix(thisCourse.COREQ);
            var thisCoNumber = getCourseNumber(thisCourse.COREQ);

            const thisCoSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: thisCoPrefix,
                    CourseNumber: thisCoNumber,
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var thisCoSub = thisCoSubQuery.data;

            coursesWO = coursesWO.filter((c) => c.id !== thisCoSub.id);

        }

        if(courseSub.COREQ){
            var coPrefix = getCoursePrefix(courseSub.COREQ);
            var coNumber = getCourseNumber(courseSub.COREQ);

            const coSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: coPrefix,
                    CourseNumber: coNumber,
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var coSub = coSubQuery.data;

            coSub = { ...coSub, TERM: thisCourse.TERM, SheetName: 'ACSG' }

            coursesWO.push(coSub);
        }

        setCourses(coursesWO);
        closeCallback();

    }

    const otherSelectChange = async (event) => {
        var cinfo = event.target.value;
        var cPrefix = getCoursePrefix(cinfo);
        var cNumber = getCourseNumber(cinfo);

        if((cNumber === '2048' && cPrefix === 'PHY') || (cNumber === '2048L' && cPrefix === 'PHY')){
            //Check if Calc is in Pathway
            const calcCourse = courses.find(c => c.CoursePrefix === 'MAC' && c.CourseNumber === '2311');
            if(!calcCourse){
                openMaxCreditAlertDialog({ title: 'Course Requirements not Met', message: `MAC2311 is a required prereq for this class.  You'll need to add MAC2311 in order to take this course` });
                closeCallback();
                return;
            }

        }

        setOptSelect(event.target.value);
        

        const courseSubQuery = await axios({
            method: 'post',
            data: {
                CoursePrefix: cPrefix,
                CourseNumber: cNumber,
                BasePathway: basePathway
            },
            withCredentials: true,
            url: '/coursesub'
        })

        var courseSub = courseSubQuery.data;

        var courseSub = { ...courseSub, TERM: thisCourse.TERM, SheetName: 'ACSG' }

        var coursesWO = courses.filter((c) => c.id !== thisCourse.id);

        coursesWO.push(courseSub);

        if(thisCourse.COREQ){
            var thisCoPrefix = getCoursePrefix(thisCourse.COREQ);
            var thisCoNumber = getCourseNumber(thisCourse.COREQ);

            const thisCoSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: thisCoPrefix,
                    CourseNumber: thisCoNumber,
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var thisCoSub = thisCoSubQuery.data;

            coursesWO = coursesWO.filter((c) => c.id !== thisCoSub.id);
        }

        if(courseSub.COREQ){
            var coPrefix = getCoursePrefix(courseSub.COREQ);
            var coNumber = getCourseNumber(courseSub.COREQ);

            const coSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: coPrefix,
                    CourseNumber: coNumber,
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var coSub = coSubQuery.data;

            coSub = { ...coSub, TERM: thisCourse.TERM, SheetName: 'ACSG' }

            coursesWO.push(coSub);
        }

        if(courseSub.CourseNumber === '1140' && courseSub.CoursePrefix === 'MAC'){
            //Add MAC1114
            const macSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: 'MAC',
                    CourseNumber: '1114',
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var macSub = macSubQuery.data;
            
            macSub = { ...macSub, TERM: parseInt(thisCourse.TERM), SheetName: 'ACSG' }

            coursesWO.push(macSub);

            coursesWO = postReqLoop(coursesWO, courseSub.id);
        }

        if(courseSub.CourseNumber === '1147' && courseSub.CoursePrefix === 'MAC'){
            //Remove MAC1114 (if exists)
            const macSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: 'MAC',
                    CourseNumber: '1114',
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var macSub = macSubQuery.data;

            coursesWO = coursesWO.filter((c) => c.id !== macSub.id);
        }

        if(courseSub.CourseNumber === '1106' && courseSub.CoursePrefix === 'MAC'){
            const calcClass = courses.find(c => c.CourseNumber === '1147' && c.CoursePrefix === 'MAC');

            if(!calcClass){
                //Remove MAC1140 & MAC1114
                const mac1SubQuery = await axios({
                    method: 'post',
                    data: {
                        CoursePrefix: 'MAC',
                        CourseNumber: '1140',
                        BasePathway: basePathway
                    },
                    withCredentials: true,
                    url: '/coursesub'
                });

                var mac1140 = mac1SubQuery.data;

                const mac2SubQuery = await axios({
                    method: 'post',
                    data: {
                        CoursePrefix: 'MAC',
                        CourseNumber: '1114',
                        BasePathway: basePathway
                    },
                    withCredentials: true,
                    url: '/coursesub'
                });

                var mac1114 = mac2SubQuery.data;

                //Add MAC1147
                const mac3SubQuery = await axios({
                    method: 'post',
                    data: {
                        CoursePrefix: 'MAC',
                        CourseNumber: '1147',
                        BasePathway: basePathway
                    },
                    withCredentials: true,
                    url: '/coursesub'
                });

                var mac1147 = mac3SubQuery.data;

                mac1147 = { ...mac1147, TERM: parseInt(thisCourse.TERM) + 1, SheetName: 'ACSG' }

                coursesWO = coursesWO.filter((c) => c.id !== mac1140.id);
                coursesWO = coursesWO.filter((c) => c.id !== mac1114.id);

                coursesWO.push(mac1147);               

            }
        }

        setCourses(coursesWO);
        closeCallback();
    }

    const foreignSubSelectChange = async (event) => {
        setOptSelect(event.target.value);
        var cinfo = event.target.value;
        var cPrefix = getCoursePrefix(cinfo);
        var cNumber = getCourseNumber(cinfo);
        let cRemoveCourses = removedCourses;

        const courseSubQuery = await axios({
            method: 'post',
            data: {
                CoursePrefix: cPrefix,
                CourseNumber: cNumber,
                BasePathway: basePathway
            },
            withCredentials: true,
            url: '/coursesub'
        });

        let courseSub = courseSubQuery.data;

        courseSub = {...courseSub, TERM: thisCourse.TERM, SheetName: 'ACSG' }

        let coursesWO = courses.filter((c) => c.id !== thisCourse.id);

        coursesWO.push(courseSub);

        let rCourse = { ...thisCourse, subCourse: { id: courseSub.id, CoursePrefix: courseSub.CoursePrefix, CourseNumber: courseSub.CourseNumber, Title: courseSub.Title, TERM: courseSub.TERM, SheetName: courseSub.SheetName }}

        cRemoveCourses.push(rCourse);

        let req = courseSub.PREREQ ? courseSub.PREREQ : courseSub.POSTREQ;

        let cTitle = thisCourse.PREREQ ? thisCourse.PREREQ : thisCourse.POSTREQ;

        let rPrefix = getCoursePrefix(req);
        let rNumber = getCourseNumber(req);

        const reqSubQuery = await axios({
            method: 'post',
            data: {
                CoursePrefix: rPrefix,
                CourseNumber: rNumber,
                BasePathway: basePathway
            },
            withCredentials: true,
            url: '/coursesub'
        });

        let reqCourseSub = reqSubQuery.data;
        let reqFiller = null;
        if(cTitle.search('Foreign') >= 0){
            reqFiller = coursesWO.find(c => c.Title === cTitle);
        } else {
            reqFiller = coursesWO.find(c => `${c.CoursePrefix}${c.CourseNumber}` === cTitle);
        }

        if(!reqFiller){
            openMaxCreditAlertDialog({ message: 'The complimentary course is either exempt or locked.  Can not change this course.', title: 'Invalid Course Change' })
            closeCallback();
            return;
        }

        let reqFillSem = semesters.find(s => parseInt(s.id.substr(1)) === parseInt(reqFiller.TERM));
        if(reqFillSem.completed){
            openMaxCreditAlertDialog({ message: 'The complimentary course is either exempt or locked.  Can not change this course.', title: 'Invalid Course Change' })
            closeCallback();
            return;
        }

        reqCourseSub = { ...reqCourseSub, TERM: reqFiller.TERM, SheetName: 'ACSG' }

        let rCSub = { ...reqFiller, subCourse: { id: reqCourseSub.id, CoursePrefix: reqCourseSub.CoursePrefix, CourseNumber: reqCourseSub.CourseNumber, Title: reqCourseSub.Title, TERM: reqCourseSub.TERM, SheetName: reqCourseSub.SheetName }}

        cRemoveCourses.push(rCSub)

        coursesWO = coursesWO.filter(c => c.id !== reqFiller.id);

        coursesWO.push(reqCourseSub);

        setCourses(coursesWO);
        setRemovedCourses(cRemoveCourses);
        closeCallback();

    }

    const foreignSelectChange = (event) => {
        setOptSelect(event.target.value);
        var cinfo = event.target.value;
        var cPrefix = getCoursePrefix(cinfo);
        var cNumber = getCourseNumber(cinfo);    
        setThisCourse({ ...thisCourse, CoursePrefix: cPrefix, CourseNumber: cNumber });

        var tCourse = { ...course, CoursePrefix: cPrefix, CourseNumber: cNumber};

        var cArray = courses.filter(c => c.Title !== thisCourse.Title);

        cArray.push(tCourse);
        
        //Handle PreReq 
        if(thisCourse.PREREQ){
            var preReq = findPreReq();
            if(preReq){
                var preSub = preReq.CourseSub.split(';');
                var pReqInfo = preSub.find(c => getCoursePrefix(c) === cPrefix);
                var prePrefix = getCoursePrefix(pReqInfo);
                var preCNumber = getCourseNumber(pReqInfo);
                preReq = { ...preReq, CoursePrefix: prePrefix, CourseNumber: preCNumber }
                cArray = cArray.filter(c => c.Title !== preReq.Title);
                cArray.push(preReq);
            } else {
                closeCallback();
                openMaxCreditAlertDialog({ title: 'Invalid Foreign Language Selection', message: 'Sorry, you can not change this foreign langauge due to the other course being in an exempted course' });
                return;                
            }
        }

        //Handle PostReq
        if(thisCourse.POSTREQ){
            var postReq = findPostReq();
            if(postReq){
                var cSub = postReq.CourseSub.split(';');
                var poReqInfo = cSub.find(c => getCoursePrefix(c) === cPrefix);
                var poPrefix = getCoursePrefix(poReqInfo);
                var poCourseNumber = getCourseNumber(poReqInfo);
                postReq = { ...postReq, CoursePrefix: poPrefix, CourseNumber: poCourseNumber }
                cArray = cArray.filter(c => c.Title !== postReq.Title);
                cArray.push(postReq);
            } else {
                closeCallback();
                openMaxCreditAlertDialog({ title: 'Invalid Foreign Language Selection', message: 'Sorry, you can not change this foreign langauge due to the other course being in an exempted course' });
                return;                
            }

        }

        setCourses(cArray);

    }

    const getCoursePrefix = (cid) => {
        return cid.trim().substr(0,3);
    }

    const getCourseNumber = (cid) => {
        return cid.trim().substr(3, cid.trim().length - 3);
    }
    
    const findPreReq = () => {
        var preReq = courses.find(c => c.Title === thisCourse.PREREQ);
        return preReq;
    }

    const findPostReq = () => {
        var postReq = courses.find(c => c.Title === thisCourse.POSTREQ);
        return postReq;
    }

    const getCourseSub = (subs) => {
        var cSubs = subs.split(';');
        return cSubs;
    }

    const handleBehav1 = (aBehav, bBehav, fBehav, startDate) => {
        var beh2 = courses.find(c => c.Title === 'Behavioral/Social Science 2');
        var returnArray = [];
        var aBehavArray = aBehav.split(';');
        var bBehavArray = bBehav.split(';');
        var fBehavArray = fBehav.split(';');
        var route;

        if(startDate.getFullYear() > 2018){
            fBehavArray.map((f) => {
                returnArray.push(f.trim());
            });            
        } else {
            if(beh2.CoursePrefix !== null && beh2.CourseNumber !== null){
                var aBeh2 = beh2.ABEHAV.split(';');
                var bBeh2 = beh2.BBEHAV.split(';');
                aBeh2.map((a) => {
                    if(a.trim() === `${beh2.CoursePrefix}${beh2.CourseNumber}`.trim()) route = 'a'
                });
                bBeh2.map((b) => {
                    if(b.trim() === `${beh2.CoursePrefix}${beh2.CourseNumber}`.trim()) route = 'b'
                });
    
                if(route === 'a'){
                    aBehavArray.map((a) => {
                        returnArray.push(a.trim());
                    });
                } else {
                    bBehavArray.map((b) => {
                        returnArray.push(b.trim());
                    });
                }
            } else {
                aBehavArray.map((a) => {
                    returnArray.push(a.trim());
                });
                bBehavArray.map((b) => {
                    returnArray.push(b.trim());
                });
            }            
        }
        
        
        return returnArray;
    }

    const handleBehav2 = (aBehav, bBehav, fBehav, startDate) => {
        var beh1 = courses.find(c => c.Title === 'Behavioral/Social Science 1');
        var returnArray = [];
        var aBehavArray = aBehav.split(';');
        var bBehavArray = bBehav.split(';');
        var fBehavArray = fBehav.split(';');
        var route;

        if(startDate.getFullYear() > 2018){
            fBehavArray.map((f) => {
                returnArray.push(f.trim());
            });
        } else {
            if(beh1.CoursePrefix !== null && beh1.CourseNumber !== null){
                var aBeh1 = beh1.ABEHAV.split(';');
                var bBeh1 = beh1.BBEHAV.split(';');
                aBeh1.map((a) => {
                    if(a.trim() === `${beh1.CoursePrefix}${beh1.CourseNumber}`.trim()) route = 'a'
                });
                bBeh1.map((b) => {
                    if(b.trim() === `${beh1.CoursePrefix}${beh1.CourseNumber}`.trim()) route = 'b'
                });
                if(route === 'a'){
                    aBehavArray.map((a) => {
                        returnArray.push(a.trim());
                    });
                } else {
                    bBehavArray.map((b) => {
                        returnArray.push(b.trim());
                    });
                }
            } else {
                aBehavArray.map((a) => {
                    returnArray.push(a.trim());
                });
                bBehavArray.map((b) => {
                    returnArray.push(b.trim());
                });
            }
        }

        
        return returnArray;
    }

    const handleCostSavingsCheck = (event) => {
        //CLEP will keep credits, reduce MDC cost 89 testing fee 39 proctor exam
        //so ClassCost - 89 - 39

        //CART Remove Credits, Add all to Cost Savings, Remove all from MDC Cost

        let tCourse = course;

        //Handle CART
        if(thisCourse.CostSaving === 'CART'){
            if(event.target.checked){
                setThisCourse({ ...thisCourse, cartActive: true });
                tCourse = ({ ...tCourse, cartActive: true });
            } else {
                setThisCourse({ ...thisCourse, cartActive: undefined });
                tCourse = ({ ...tCourse, cartActive: undefined });
            }
        }

        //Handle CLEP
        if(course.CostSaving === 'CLEP'){
            if(event.target.checked){
                setThisCourse({ ...thisCourse, clepActive: true });
                tCourse = ({ ...tCourse, clepActive: true });
            } else {
                setThisCourse({ ...thisCourse, clepActive: undefined });
                tCourse = ({ ...tCourse, clepActive: undefined });
            }            
        }

        let coursesWO = courses.filter(c => c.id !== tCourse.id);

        coursesWO.push(tCourse);
        setCourses(coursesWO);
        closeCallback();
    }

    React.useEffect(() => {
        setThisCourse(course);
        if(course.Title && (course.Title.includes('Oral Comm') || course.Title.includes('Humanities') || course.Title.includes('Foreign'))){
            if(course.CoursePrefix !== 'NULL' && course.CourseNumber !== 'NULL'){
                setOptSelect(`${course.CoursePrefix}${course.CourseNumber}`);
            }
        }
        if(course.Title && course.Title === 'Behavioral/Social Science 1'){
            if(course.CoursePrefix !== 'NULL' && course.CourseNumber !== 'NULL'){
                setOptSelect(`${course.CoursePrefix}${course.CourseNumber}`);
            }
        }
        if(course.Title && course.Title === 'Behavioral/Social Science 2'){
            if(course.CoursePrefix !== 'NULL' && course.CourseNumber !== 'NULL'){
                setOptSelect(`${course.CoursePrefix}${course.CourseNumber}`);
            }
        }
        if(course.CoursePrefix === 'MAC' && course.CourseNumber === '1147'){
            var validMathCourse = courses.find(c => c.CourseNumber === '1106' && c.CoursePrefix === 'MAC');
            if(validMathCourse){
                setValidSub(false);
            }else{
                setValidSub(true);
            }
        }else{
            setValidSub(true);
        }
        
    }, [course]);

    if(thisCourse === null){
        return (
            <div />
        )
    }

    if(course.Title && course.Title.includes('Behavioral/Social')){
        //console.log(startDate);
    }

    return(
        <Dialog open={open} onClose={closeCallback} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="h6" style={{ textTransform: 'none' }}>{thisCourse.Title} ({thisCourse.CoursePrefix} {thisCourse.CourseNumber})</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    <IconButton color="secondary" onClick={closeCallback} aria-label='close'><CloseIcon /></IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            {course.ABEHAV && course.Title.trim() === 'Behavioral/Social Science 1' && (
                <React.Fragment>
                    <TextField disabled={completed || exempted} fullWidth select label={course.Title} value={optSelect} onChange={selectChange} helperText='Select your course option'>
                    {handleBehav1(course.ABEHAV, course.BBEHAV, course.FBEHAV, startDate).map((sub) => {
                        var bbeh = courses.find(c => c.Title === 'Behavioral/Social Science 2')
                        sub = sub.trim();
                        if(sub === `${bbeh.CoursePrefix}${bbeh.CourseNumber}`){
                            return;
                        } else {
                            return(
                                <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                            )
                        }
                       })}
                       
                    </TextField>
                </React.Fragment>
            )}
            {course.ABEHAV && course.Title.trim() === 'Behavioral/Social Science 2' && (
                <React.Fragment>
                    <TextField disabled={completed || exempted} fullWidth select label={course.Title} value={optSelect} onChange={selectChange} helperText='Select your course option'>                    
                    {handleBehav2(course.ABEHAV, course.BBEHAV, course.FBEHAV, startDate).map((sub) => {
                        var abeh = courses.find(c => c.Title === 'Behavioral/Social Science 1');
                        sub = sub.trim();
                        if(sub === `${abeh.CoursePrefix}${abeh.CourseNumber}`){
                            return;
                        } else {
                            return(
                                <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                            )
                        }
                        })}
                    </TextField>
                </React.Fragment>
            )}
            {course.CourseSub && course.Title.includes('Oral Comm') && (
                <React.Fragment>
                    <TextField disabled={completed || exempted} fullWidth select label={course.Title} value={optSelect} onChange={selectSubChange} helperText='Select your course option'>                    
                    {getCourseSub(course.CourseSub).map((sub) => {
                        sub = sub.trim();
                        return(
                        <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                    )})}
                    </TextField>
                </React.Fragment>
            )}
            {course.CourseSub && course.Title.includes('Humanities') && (
                <React.Fragment>
                    <TextField disabled={completed || exempted} fullWidth select label={course.Title} value={optSelect} onChange={selectSubChange} helperText='Select your course option'>                    
                    {getCourseSub(course.CourseSub).map((sub) => {
                        sub = sub.trim();
                        return (
                        <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                    )})}
                    </TextField>
                </React.Fragment>
            )}
            {course.CourseSub && course.Title.includes('Foreign') && (
                <React.Fragment>
                    <TextField disabled={completed || exempted} fullWidth select label={course.Title} value={optSelect} onChange={foreignSubSelectChange} helperText='Select your course option'>                    
                    {getCourseSub(course.CourseSub).map((sub) => {
                        sub = sub.trim();
                        return(
                        <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                    )})}
                    </TextField>
                </React.Fragment>
            )}
            {course.CourseSub && course.CoursePrefix && (course.CoursePrefix.includes('ASL') || course.CoursePrefix.includes('CHI') || course.CoursePrefix.includes('FRE') || course.CoursePrefix.includes('GER') || course.CoursePrefix.includes('ITA') || course.CoursePrefix.includes('JPN') || course.CoursePrefix.includes('POR') || course.CoursePrefix.includes('SPN') || course.CoursePrefix.includes('RUS')) && (
                <React.Fragment>
                    <TextField disabled={completed || exempted} fullWidth select label={course.Title} value={optSelect} onChange={foreignSubSelectChange} helperText='Select your course option'>                    
                    {getCourseSub(course.CourseSub).map((sub) => {
                        sub = sub.trim();
                        return(
                        <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                    )})}
                    </TextField>
                </React.Fragment>
            )}            
            {course.CourseSub && !course.Title.includes('Oral Comm') && !course.Title.includes('Humanities') && !course.Title.includes('Foreign') && validSub && !course.CoursePrefix.includes('ASL') && !course.CoursePrefix.includes('CHI') && !course.CoursePrefix.includes('FRE') && !course.CoursePrefix.includes('GER') && !course.CoursePrefix.includes('ITA') && !course.CoursePrefix.includes('JPN') && !course.CoursePrefix.includes('POR') && !course.CoursePrefix.includes('RUS') && !course.CoursePrefix.includes('SPN') && (
                <React.Fragment>
                    <TextField disabled={completed || exempted} fullWidth select label='Course Subsititutions' value={optSelect} onChange={otherSelectChange} helperText='Select an alternative course'>
                        {getCourseSub(course.CourseSub).map((sub) => {
                            sub = sub.trim();
                            return(
                                <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                            )
                        })}
                    </TextField>
                </React.Fragment>
            )}
            {course.Exemption &&
            <React.Fragment>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Exemptions:
            </Typography>
            <Typography variant="body1">
                {course.Exemption}
            </Typography>
            </React.Fragment>}
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Prerequistes:
            </Typography>
            <Typography variant="body1">
                {course.Prerequisite}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Corequistes:
            </Typography>
            <Typography variant="body1">
                {course.Corequisite}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Description: 
            </Typography>
            <Typography variant="body1">
                {course.Description}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Cost Savings:
            </Typography>
            {
                course.DE && <Typography variant="body1">
                    Dual Enrollment (DE)
                </Typography>
            }
            {
                course.AICE && <Typography variant="body1">
                    Association of International Credential Evaluators (AICE)
                </Typography>
            }
            {
                course.AP && <Typography variant="body1">
                   Advanced Placement (AP)
                </Typography>
            }
            {
                course.CART && <Typography variant="body1">
                    Center for Advanced Research and Technology (CART)
                </Typography>
            }
            {
                course.CLEP && <Typography variant="body1">
                    College-Level Examination Program (CLEP)
                </Typography>
            }
            {
                course.CSP && <Typography variant="body1">
                    Computer Skills Placement (CSP)
                </Typography>
            }
            {
                course.DLPT && <Typography variant="body1">
                    Defense Language Proficiency Test (DLPT)
                </Typography>
            }
            {
                course.DSST && <Typography variant="body1">
                    DSST (DANTES)
                </Typography>
            }
            {
                course.IB && <Typography variant="body1">
                    International Baccalaureate (IB)
                </Typography>
            }
            {
                course.Uexcel && <Typography variant="body1">
                    UExcel Examinations (Uexcel)
                </Typography>
            }
            {
                course.EXEMPT && <Typography variant="body1">
                    Exemption (Exempt)
                </Typography>
            }
            {
                course.ACTFL && <Typography variant="body1">
                    American Council on the Teaching of Foreign Languages (ACTFL)
                </Typography>
            }
            {
                course.EXEMPT && <Typography variant="body1">
                    Caribbean Advanced Proficiency Examination Program (CAPE)
                </Typography>
            }
            {
            /* (course.CostSaving === 'CLEP' || course.CostSaving === 'CART') &&
            <FormControlLabel control={<Checkbox color='primary' name='CLEP' onChange={handleCostSavingsCheck} checked={course.CostSaving === 'CLEP' ? thisCourse.clepActive : thisCourse.cartActive} />} label={<React.Fragment><span>{course.CostSaving === 'CLEP' && 'CLEP'}{course.CostSaving === 'CART' && 'CART'} - </span>{course.URL && <Button color='primary' variant='contained' target='_blank' href={course.URL} aria-label='learn more'>Learn More</Button>}</React.Fragment>} />
             */}
             { (course.DE || course.AICE || course.AP || course.CART || course.CSP || course.DLPT || course.DSST || course.IB || course.Uexcel || course.CLEP || course.ACTFL || course.CAPE) && 
              <Button color='primary' variant='contained' target='_blank' href='https://www.mdc.edu/main/testing/criteria/credit-by-exam.aspx' aria-label='learn more'>Learn More</Button>
                }   
        </DialogContent>
    </Dialog>
    );     
}

const FiuClassDialog = ({ course, open, close, courses, setCourses, basePathway, completed }) => {

    const [optSelect, setOptSelect] = React.useState('');
    const [thisCourse, setThisCourse] = React.useState(null);
    
    const closeCallback = () => {
        close();
        setThisCourse(null);
        setOptSelect('');
    }

    const otherSelectChange = async (event) => {

        setOptSelect(event.target.value);
        var cinfo = event.target.value;
        var cPrefix = getCoursePrefix(cinfo);
        var cNumber = getCourseNumber(cinfo);

        const courseSubQuery = await axios({
            method: 'post',
            data: {
                CoursePrefix: cPrefix,
                CourseNumber: cNumber,
                BasePathway: basePathway
            },
            withCredentials: true,
            url: '/coursesub'
        });

        var courseSub = courseSubQuery.data;

        var courseSub = { ...courseSub, TERM: thisCourse.TERM, SheetName: 'TRANSFER' }

        var coursesWO = courses.filter((c) => c.id !== thisCourse.id);

        coursesWO.push(courseSub);

        if(thisCourse.COREQ){
            var thisCoPrefix = getCoursePrefix(thisCourse.COREQ);
            var thisCoNumber = getCourseNumber(thisCourse.COREQ);

            const thisCoSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: thisCoPrefix,
                    CourseNumber: thisCoNumber,
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var thisCoSub = thisCoSubQuery.data;

            coursesWO = coursesWO.filter((c) => c.id !== thisCoSub.id);
        }

        if(courseSub.COREQ){
            var coPrefix = getCoursePrefix(courseSub.COREQ);
            var coNumber = getCourseNumber(courseSub.COREQ);

            const coSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: coPrefix,
                    CourseNumber: coNumber,
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var coSub = coSubQuery.data;

            var coSub = { ...coSub, TERM: thisCourse.TERM, SheetName: 'TRANSFER' }

            coursesWO.push(coSub);
        }

        if(courseSub.CourseNumber === '2312' && courseSub.CoursePrefix === 'MAC'){
            //Remove STA 3123
            const staSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: 'STA',
                    CourseNumber: '3123',
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var staSub = staSubQuery.data;

            coursesWO = coursesWO.filter((c) => c.id !== staSub.id);            
        }

        if(courseSub.CourseNumber === '2023' && courseSub.CoursePrefix === 'STA'){
            //Add STA 3123
            const staSubQuery = await axios({
                method: 'post',
                data: {
                    CoursePrefix: 'STA',
                    CourseNumber: '3123',
                    BasePathway: basePathway
                },
                withCredentials: true,
                url: '/coursesub'
            });

            var staSub = staSubQuery.data;

            staSub = { ...staSub, SheetName: 'BCSG' }

            coursesWO.push(staSub);
        }

       

        setCourses(coursesWO);
        closeCallback();
    }

    const getCoursePrefix = (cid) => {
        return cid.trim().substr(0,3);
    }

    const getCourseNumber = (cid) => {
        return cid.trim().substr(3, cid.trim().length - 3);
    }

    const getCourseSub = (subs) => {
        var cSubs = subs.split(';');
        return cSubs;
    }

    React.useEffect(() => {
        setThisCourse(course);
    }, [course])

    if(thisCourse === null){
        return (
            <div />
        )
    }

    return(
        <Dialog open={open} onClose={closeCallback} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h6">{course.Title} ({course.CoursePrefix} {course.CourseNumber})</Typography>
                    <Typography variant="body1">{course.Units} credits</Typography>
                </Grid>
                <Grid item>
                    <IconButton color="secondary" onClick={closeCallback} aria-label='close'><CloseIcon /></IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            {course.CourseSub && !course.Title.includes('Oral Comm') && !course.Title.includes('Humanities') && !course.Title.includes('Foreign') && (
                <React.Fragment>
                    <TextField disabled={completed} fullWidth select label='Course Subsititutions' value={optSelect} onChange={otherSelectChange} helperText='Select an alternative course'>
                        {getCourseSub(course.CourseSub).map((sub) => {
                            sub = sub.trim();
                            return(
                                <MenuItem key={sub} value={sub}>{sub}</MenuItem>
                            )
                        })}
                    </TextField>
                </React.Fragment>
            )}
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Prerequistes:
            </Typography>
            <Typography variant='body1'>
                {course.Prerequisite}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Corequistes:
            </Typography>
            <Typography variant='body1'>
                {course.Corequisite}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Description: 
            </Typography>
            <Typography variant="body1">
                {course.Description}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Cost Savings:
            </Typography>
            {
                course.DE && <Typography variant="body1">
                    Dual Enrollment (DE)
                </Typography>
            }
            {
                course.AICE && <Typography variant="body1">
                    Association of International Credential Evaluators (AICE)
                </Typography>
            }
            {
                course.AP && <Typography variant="body1">
                   Advanced Placement (AP)
                </Typography>
            }
            {
                course.CART && <Typography variant="body1">
                    Center for Advanced Research and Technology (CART)
                </Typography>
            }
            {
                course.CLEP && <Typography variant="body1">
                    College-Level Examination Program (CLEP)
                </Typography>
            }
            {
                course.CSP && <Typography variant="body1">
                    Computer Skills Placement (CSP)
                </Typography>
            }
            {
                course.DLPT && <Typography variant="body1">
                    Defense Language Proficiency Test (DLPT)
                </Typography>
            }
            {
                course.DSST && <Typography variant="body1">
                    DSST (DANTES)
                </Typography>
            }
            {
                course.IB && <Typography variant="body1">
                    International Baccalaureate (IB)
                </Typography>
            }
            {
                course.Uexcel && <Typography variant="body1">
                    UExcel Examinations (Uexcel)
                </Typography>
            }
            {
                course.ACTFL && <Typography variant="body1">
                    American Council on the Teaching of Foreign Languages (ACTFL)
                </Typography>
            }
            {
                course.CAPE && <Typography variant="body1">
                    Caribbean Advanced Proficiency Examination Program (CAPE)
                </Typography>
            }
        </DialogContent>
    </Dialog>
    );     
}

const VideoDialog = ({ course, open, close }) => {
    const closeCallback = () => {
        close();
    }


    return (
    <Dialog open={open} onClose={closeCallback} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h6">{course.name}</Typography>
                </Grid>
                <Grid item>
                    <IconButton color="secondary" onClick={closeCallback} aria-label='close'><CloseIcon /></IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Video 
                videoSrcUrl={course.videoUrl}
                title={course.name}
            />
        </DialogContent>
    </Dialog>
    );
}

const FiuVideoDialog = ({ course, open, close }) => {
    const closeCallback = () => {
        close();
    }


    return (
    <Dialog open={open} onClose={closeCallback} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    {/* <Typography variant="h6">{course.FIUname} ({course.FIUcourse})</Typography> */}
                    <Typography variant="h6">{course.Title} ({course.CoursePrefix} {course.CourseNumber})</Typography>
                </Grid>
                <Grid item>
                    <IconButton color="secondary" onClick={closeCallback} aria-label='close'><CloseIcon /></IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Video 
                videoSrcUrl="https://www.youtube.com/embed/t4qnu-p-xn0"
                title={course.Title}
            />
        </DialogContent>
    </Dialog>
    );
}

const AddManualClass = ({ open, close, addManualCourse, semesters, handleTermName, mdcGrad, mdcMaxSemester }) => {
    const closeCallback = () => {
        setManualCourse({
            CoursePrefix: '',
            Units: 0,
            TERM: 1,
            institution: 'Miami Dade College'
        })
        close();
    }

    const gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;

    const handleManChange = name => event => {
        setManualCourse({ ...manualCourse, [name]: event.target.value });
    }

    const handleSemesterChange = event => {
        setManualCourse({ ...manualCourse, TERM: event.target.value });
    }

    const addCourse = () => {
        addManualCourse(manualCourse);
        closeCallback();
    }

    const [manualCourse, setManualCourse] = React.useState({
        CoursePrefix: '',
        Units: 0,
        TERM: 1,
        institution: 'Miami Dade College'
    });

    return (
        <Dialog open={open} onClose={closeCallback} maxWidth="sm" fullWidth>
            <DialogTitle disableTypography>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">Add Outside Class</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton style={{ color: 'red' }} onClick={closeCallback} aria-label='close'><CloseIcon /></IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <TextField 
                            label="Institution"
                            value={manualCourse.institution}
                            onChange={handleManChange('institution')}
                            placeholder='Florida International University'
                            fullWidth
                            select
                        >
                            <MenuItem key='Miami Dade College' value='Miami Dade College'>Miami Dade College</MenuItem>
                            <MenuItem key='Florida International University' value='Florida International University'>Florida International University</MenuItem>
                            <MenuItem key='Other' value='Other'>Other</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <TextField 
                            label="Course"
                            value={manualCourse.value}
                            onChange={handleManChange('CoursePrefix')}
                            placeholder="i.e. ABC0001"
                            id='course'
                        />
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <TextField 
                            select
                            label="Term"
                            value={manualCourse.semester}
                            onChange={handleSemesterChange}
                            fullWidth
                        >
                            {semesters.map((sem)=> { 
                                
                                    if(manualCourse.institution === 'Florida International University'){
                                        if(parseInt(sem.id.substr(1)) > gradLine){
                                            return !sem.completed && <MenuItem key={sem.id} value={sem.id.substr(1)}>{handleTermName(sem.termdate)}</MenuItem>
                                        }
                                    } else {
                                        if(parseInt(sem.id.substr(1)) < gradLine){
                                            return (
                                                !sem.completed && <MenuItem key={sem.id} value={sem.id.substr(1)}>{handleTermName(sem.termdate)}</MenuItem>
                                            );
                                        }                                        
                                    }
                                }
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <TextField label="Credits" type="number" placeholder={3} value={manualCourse.hours} onChange={handleManChange('Units')} inputProps={{'aira-label': 'credits'}} />
                    </Grid>
                </Grid>                
            </DialogContent>
            <DialogActions>
                <Button onClick={addCourse} aria-label='add course'>Add Course</Button>
            </DialogActions>
        </Dialog>
    )
}


const SemesterColumn = ({ semester, completed, changeCourses, changeFiuCourses, children, PreReqCheck, PostReqCheck, mdcGrad, mdcMaxSemester, setExemptType }) => {
    const ref = useRef(null);
    const gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;
    //console.log(parseInt(semester.substring(1)));
    const [, drop] = useDrop({
        accept: 'card',
        canDrop(item){
            setExemptType('');
            if(item.fiu){
                if(!item.transfer){
                    return !completed && gradLine < parseInt(semester.substr(1));
                } else {
                    return !completed && !PreReqCheck(item.id, parseInt(semester.substring(1)));
                }                
            } else {
                return !completed && !PreReqCheck(item.id, parseInt(semester.substring(1))); //&& PostReqCheck(item.postreqs, parseInt(semester.substring(1)));
            }
        },
        drop(item) {
            item = {...item, de: false }
            if(item.fiu){
                changeFiuCourses(item.id, semester);
            }else{
                changeCourses(item.id, semester);
            }
        }
    });
    drop(ref);
    return <div ref={ref}> {children}</div>
}

const ExemptColumn = ({ semester, completed, changeExemptCourses, children, PreReqCheck, PostReqCheck, mdcGrad, mdcMaxSemester, exemptType, setExemptType }) => {

    const ref = useRef(null);
    const gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;

    const [{ exemptionType }, drop] = useDrop({
        accept: 'card',
        canDrop(item, monitor){
            setExemptType(exemptType);
            let checkExemptDrop = false;
            switch(exemptType){
                case 'DE':
                    if(item.citem.DE){
                        checkExemptDrop = true;
                    }
                    break;
                case 'AICE':
                    if(item.citem.AICE){
                        checkExemptDrop = true;
                    }
                    break;
                case 'AP':
                    if(item.citem.AP){
                        checkExemptDrop = true;
                    }
                    break;
                case 'CART':
                    if(item.citem.CART){
                        checkExemptDrop = true;
                    }
                    break;
                case 'CSP':
                    if(item.citem.CSP){
                        checkExemptDrop = true;
                    }
                    break;
                case 'DLPT':
                    if(item.citem.DLPT){
                        checkExemptDrop = true;
                    }
                    break;
                case 'DSST':
                    if(item.citem.DSST){
                        checkExemptDrop = true;
                    }
                    break;
                case 'IB':
                    if(item.citem.IB){
                        checkExemptDrop = true;
                    }
                    break;
                case 'Uexcel':
                    if(item.citem.Uexcel){
                        checkExemptDrop = true;
                    }
                    break;
                case 'CLEP':
                    if(item.citem.CLEP){
                        checkExemptDrop = true;
                    }
                    break;
                case 'Exempt':
                    if(item.citem.EXEMPT){
                        checkExemptDrop = true;
                    }
                    break;
                case 'ACTFL':
                    if(item.citem.ACTFL){
                        checkExemptDrop = true;
                    }
                    break;
                case 'CAPE':
                    if(item.citem.CAPE){
                        checkExemptDrop = true;
                    }
                    break;
            }
            return !completed && !item.fiu && checkExemptDrop && item.citem.CoursePrefix !== null && item.citem.CoursePrefix !== null && gradLine >= parseInt(semester.substr(1));
        },
        drop(item, monitor){
            changeExemptCourses(item.id, semester, exemptType);
        }
    });
    drop(ref);
    return <div ref={ref}>{children}</div>
}

/* const SemesterItem = ({ id, citem, semcomp, videoClick, courseClick, delClick }) => {
    const ref = useRef(null);
    const [{ isDragging, canDrag }, drag] = useDrag({
        item: { type: 'card', id, fiu: false},
        canDrag: !semcomp,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });
    const opacity = isDragging ? 0 : 1;
    drag(ref);
    return(
        <Card ref={ref} style={{ opacity, margin: '10px' }}>
            <CardHeader style={{ paddingBottom: '0px', borderTop: '8px solid #51B858' }} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.id}</Typography>} subheader={<Typography variant="caption">{citem.hours} hours</Typography>} action={citem.videoUrl ? <IconButton onClick={videoClick}><PlayCircleOutlineIcon/></IconButton> : <IconButton color='secondary' onClick={() => delClick(citem)}><DeleteIcon /></IconButton>} />
            <CardHeader style={{ paddingBottom: '0px', borderTop: '8px solid #51B858', textAlign: 'left' }} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.id}</Typography>} subheader={<Typography variant="caption">{citem.hours} hours</Typography>} action={citem.videoUrl ? '' : <IconButton color='secondary' onClick={() => delClick(citem)}><DeleteIcon /></IconButton>} />
            <CardActions style={{ paddingTop: '0px' }}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Grid item>
                        <Button disabled={!citem.description} style={{ textTransform: 'capitalize', fontSize: '0.75em'}} color="primary" onClick={courseClick}>Learn More</Button>
                    </Grid>
                    <Grid item>
                        {citem.logo && <img src={citem.logo} style={{ width: '28px', height: '15px' }} alt="MDC" />}
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
} */

const SemesterItem = ({ id, citem, semcomp, videoClick, courseClick, delClick, exemptType }) => {
    const courseAriaLabel = citem.CoursePrefix !== null ? `${citem.CoursePrefix} ${citem.CourseNumber}` : `${citem.Title}`
    const prearray = [];
    if(citem.PREREQ !== undefined && citem.PREREQ !== null){
        citem.PREREQ.split(';').map((item) => {
            prearray.push(item.trim());
        })
    }
    const ref = useRef(null);
    const [{ isDragging, canDrag }, drag] = useDrag({
        item: { type: 'card', id, fiu: false, prereqs: prearray, cterm: citem.TERM, citem: citem },
        canDrag: !semcomp,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            if(!monitor.didDrop()){
                var courseData = item.citem.CoursePrefix !== null ? `${item.citem.CoursePrefix} ${citem.CourseNumber}` : `${item.citem.Title}`;
                var getDescription = () => {
                    switch(exemptType){
                        case 'DE':
                            return `${courseData} is not valid for Dual Enrollment`;
                        case 'AICE':
                            return `${courseData} is not valid for Association of International Credential Evaluators`;
                        case 'AP':
                            return `${courseData} is not valid for Advanced Placement`;
                        case 'CART':
                            return `${courseData} is not valid for Center for Advanced Research and Technology`;
                        case 'CSP':
                            return `${courseData} is not valid for Computer Skills Placement`;
                        case 'DLPT':
                            return `${courseData} is not valid for Defense Language Proficiency Test`;
                        case 'DSST':
                            return `${courseData} is not valid for DSST (DANTES)`;
                        case 'IB':
                            return `${courseData} is not valid for International Baccalaureate`;
                        case 'Uexcel':
                            return `${courseData} is not valid for UExcel Examinations`;
                        case 'Exempt':
                            return `${courseData} is not valid for Exemption`;
                        case 'ACTFL':
                            return `${courseData} is not valid for ACTFL`;
                        case 'CAPE':
                            return `${courseData} is not valid for CAPE`;
                        default:
                            return `${courseData} is not allowed to be dropped here`;
                    }
                }
                openMaxCreditAlertDialog({ title: courseData, message: getDescription() });
            }
        }
    });
    const opacity = isDragging ? 0 : 1;
    drag(ref);
    return(
        <Card ref={ref} style={{ opacity, margin: '10px' }}>
            {/* <CardHeader style={{ paddingBottom: '0px', borderTop: '8px solid #51B858' }} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.id}</Typography>} subheader={<Typography variant="caption">{citem.hours} hours</Typography>} action={citem.videoUrl ? <IconButton onClick={videoClick}><PlayCircleOutlineIcon/></IconButton> : <IconButton color='secondary' onClick={() => delClick(citem)}><DeleteIcon /></IconButton>} /> */}
            <CardHeader style={{ paddingBottom: '0px', borderTop: `8px solid ${getCourseColor(citem.Color)}`, textAlign: 'left' }} title={<Typography variant="h6" style={{ fontSize: '.9em' }}>{citem.CoursePrefix !== null ? `${citem.CoursePrefix} ${citem.CourseNumber}` : `${citem.Title}`}</Typography>} subheader={<Typography variant="caption">{citem.Units} credits</Typography>} /* action={!citem.del ? '' : <IconButton color='secondary' onClick={() => delClick(citem)} aria-label='remove course'><DeleteIcon /></IconButton>} */ />
            <CardActions style={{ paddingTop: '0px' }}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Grid item>
                        <SemItemButton disabled={!citem.Description} color='primary' onClick={courseClick} aria-label={`learn more ${getCourseColorAria(citem.Color)} ${courseAriaLabel}`}>Learn More</SemItemButton>
                        {/* <Button TouchRippleProps={{ style: { child: { backgroundColor: 'yellow' }} }} disabled={!citem.Description} style={{ textTransform: 'capitalize', fontSize: '0.75em' }} color="primary" onClick={courseClick} aria-label='learn more'>Learn More</Button> */}
                    </Grid>
                    <Grid item>
                        {citem.logo && citem.SheetName === 'TRANSFER' ? (<React.Fragment><img src={mdclogoblue} style={{ width: '28px', height: '15px' }} alt="MDC" /> <img src={fiuBlueLogo} style={{ width: '24px', height: '13px' }} alt="FIU" /></React.Fragment>) : citem.logo ? <img src={mdclogoblue} style={{ width: '28px', height: '15px' }} alt="MDC" /> : '' }
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
}

const FiuSemesterItem = ({ id, citem, semcomp, videoClick, courseClick, delClick, exemptType }) => {
    const courseAriaLabel = citem.CoursePrefix !== null ? `${citem.CoursePrefix} ${citem.CourseNumber}` : `${citem.Title}`
    const ref = useRef(null);
    const transferCourse = citem.SheetName === 'TRANSFER' ? true : false;
    const [{ isDragging, canDrag }, drag] = useDrag({
        item: { type: 'card', id, fiu: true, transfer: transferCourse, cterm: citem.TERM, citem: citem },
        canDrag: !semcomp,
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        end: (item, monitor) => {
            if(!monitor.didDrop()){
                var courseData = item.citem.CoursePrefix !== null ? `${item.citem.CoursePrefix} ${citem.CourseNumber}` : `${item.citem.Title}`;
                var getDescription = () => {
                    switch(exemptType){
                        case 'DE':
                            return `${courseData} is not valid for Dual Enrollment`;
                        case 'AICE':
                            return `${courseData} is not valid for Association of International Credential Evaluators`;
                        case 'AP':
                            return `${courseData} is not valid for Advanced Placement`;
                        case 'CART':
                            return `${courseData} is not valid for Center for Advanced Research and Technology`;
                        case 'CSP':
                            return `${courseData} is not valid for Computer Skills Placement`;
                        case 'DLPT':
                            return `${courseData} is not valid for Defense Language Proficiency Test`;
                        case 'DSST':
                            return `${courseData} is not valid for DSST (DANTES)`;
                        case 'IB':
                            return `${courseData} is not valid for International Baccalaureate`;
                        case 'Uexcel':
                            return `${courseData} is not valid for UExcel Examinations`;
                        case 'Exempt':
                            return `${courseData} is not valid for Exemption`;
                        default:
                            return `${courseData} is not allowed to be dropped here`;
                    }
                }
                openMaxCreditAlertDialog({ title: courseData, message: getDescription() });
            }
        }
    });
    const opacity = isDragging ? 0 : 1;
    drag(ref);
    return(
        <Card ref={ref} style={{ opacity, margin: '10px' }}>
            <CardHeader style={{ paddingBottom: '0px', borderTop: `8px solid ${getCourseColor(citem.Color)}`, textAlign: 'left' }} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.CoursePrefix !== null ? `${citem.CoursePrefix} ${citem.CourseNumber}` : `${citem.Title}`}</Typography>} subheader={<Typography variant="caption">{citem.Units} credits</Typography>} /* action={!citem.del ? '' : <IconButton color='secondary' onClick={() => delClick(citem)} aria-label='remove course'><DeleteIcon /></IconButton>} */ />
            {/* <CardHeader style={{ paddingBottom: '0px', borderTop: `8px solid ${getCourseColor(citem.Color)}`, textAlign: 'left' }} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.CoursePrefix !== null ? `${citem.CoursePrefix} ${citem.CourseNumber}` : `${citem.Title}`}</Typography>} subheader={<Typography variant="caption">{citem.Units} credits</Typography>} action={citem.videoUrl ? '' : <IconButton onClick={videoClick}><PlayCircleOutlineIcon/></IconButton>} /> */}
            {/* <CardHeader style={{ paddingBottom: '0px', borderTop: `8px solid #d3d3d3`, textAlign: 'left' }} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.FIUcourse}</Typography>} subheader={<Typography variant="caption">{citem.FIUhours} credits</Typography>} action={citem.videoUrl && <IconButton onClick={videoClick}><PlayCircleOutlineIcon/></IconButton>} /> */}
            <CardActions style={{ paddingTop: '0px' }}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Grid item>
                        <SemItemButton disabled={!citem.Description} color='primary' onClick={courseClick} aria-label={`learn more ${getCourseColorAria(citem.Color)} ${courseAriaLabel}`}>Learn More</SemItemButton>
                        {/* <Button disabled={!citem.Description} style={{ textTransform: 'capitalize', fontSize: '0.75em'}} color="primary" onClick={courseClick} aria-label={`learn more ${getCourseColorAria(citem.Color)} ${courseAriaLabel}`}>Learn More</Button> */}
                    </Grid>
                    <Grid item>
                        {citem.logo && citem.SheetName === 'TRANSFER' || citem.SheetName === 'ALT' ? (<React.Fragment><img src={mdclogoblue} style={{ width: '28px', height: '15px' }} alt="MDC" /> <img src={fiuBlueLogo} style={{ width: '24px', height: '13px' }} alt="FIU" /></React.Fragment>) : citem.logo ? <img src={fiuBlueLogo} style={{ width: '28px', height: '15px' }} alt="MDC" /> : '' }
                        {/* {fiuBlueLogo && <img src={fiuBlueLogo} style={{ width: '24px', height: '13px' }} alt="FIU" />} */}
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
}

const getCourseColor = (ind) => {
    var colorhex = '#d3d3d3';

    switch (ind){
        case 1:
            colorhex = '#FC2C7E';
            break;
        case 2:
            colorhex = '#2CEAFC';
            break;
        case 3:
            colorhex = '#2CFC52';
            break;
        case 4: 
            colorhex = '#E732E7';
            break;
        case 5:
            colorhex = '#fff701';
            break;
        case 6:
            colorhex = '#970097';
            break;
        case 7:
            colorhex = '#f59a22';
            break;
        default:
            colorhex = '#d3d3d3';
    }
 
    return colorhex;
}

const getCourseColorAria = (ind) => {
    var aLabel = 'Course';

    switch (ind){
        case 1:
            aLabel = 'Science Course';
            break;
        case 2:
            aLabel = 'Math Course';
            break;
        case 3:
            aLabel = 'English Course';
            break;
        case 4: 
            aLabel = 'Course Selection Available';
            break;
        case 5:
            aLabel = 'Transferable Course';
            break;
        case 6:
            aLabel = 'Transfer Institution Course';
            break;
        default:
            aLabel = 'Course';
    }
 
    return aLabel;
}

const generateNewId = () => {
    return uuidv4();
}

const CourseMatrix = (props) => {

    const { checkUser, currentUser, loginOpen, setLoginOpen, loading, setLoading, additionalCost, setAdditionalCost, additionalSavings, setAdditionalSavings } = props;

    const [userPathways, setUserPathways] = useState([{
        id: '111-1111-111-11111',
        basePathway: 'BIO_BA_DATA', 
        index: 0,
        name: 'Bachelor of Arts Biology',
        semesters: semesterList,
        mdcCourses: [],
        fiuCourses: []
    }]);
    const [currentPathway, setCurrentPathway] = useState('111-1111-111-11111');

    const [courses, setCourses] = useState(courseList);
    const [semesters, setSemesters] = useState(semesterList);
    const [exemptHours, setExemptHours] = useState(0);
   /*  const [fiucourses, setFiuCourses] = useState(fiuClasses); */
    const [fiucourses, setFiuCourses] = useState(fiuBABio);
    const [openVideo, setOpenVideo] = useState(false);
    const [openCourse, setOpenCourse] = useState(false);
    const [openFiuVideo, setOpenFiuVideo] = useState(false);
    const [openFiuCourse, setOpenFiuCourse] = useState(false);
    const [openManualAdd, setOpenManualAdd] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [selectedCourseCompleted, setSelectedCourseCompleted] = useState(false);
    const [selectedCourseExempt, setSelectedCourseExempt] = useState(false);
    const [completed, setCompleted] = useState(50);
    const [accumHours, setAccumHours] = useState(0);
    const [accumCost, setAccumCost] = useState(0.00);
    const [startDialog, setStartDialog] = useState(true);
    const [fiupathway, setFiuPathway] = React.useState('BIO_BA_DATA');
    const [basePathway, setBasePathway] = React.useState('BIO_BA_DATA');
    const [pathwayName, setPathwayName] = React.useState('BIO_BA_DATA');
    const [mdcMaxSemester, setMDCMaxSemester] = React.useState(1);
    const [mdcGrad, setMDCGrad] = React.useState(null);
    const [mdcGradDate, setMDCGradDate] = React.useState(new Date());
    const [compDate, setCompDate] = React.useState(new Date());
    
    const [startdate, setStartDate] = useState(new Date());
    const [enddate, setEndDate] = useState(new Date());
    const [dualenroll, setDualEnroll] = useState(false);
    const [floridares, setFloridaRes] = useState(true);
    const [foreignlangexempt, setForeignLangExempt] = useState(false);
    const [dualterms, setDualTerms] = useState(1);

    const [test, setTest] = React.useState('ACT');
    const [readingScore, setReadingScore] = React.useState(0);
    const [writingScore, setWritingScore] = React.useState(0);
    const [mathScore, setMathScore] = React.useState(0);
    const [aafScore, setAafScore] = React.useState(0);
    const [testExempt, setTestExempt] = React.useState(false);
    const [testArray, setTestArray] = React.useState([]);

    const [showdash, setShowDash] = useState(true);
    const [showlegend, setShowLegend] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [value, setValue] = React.useState(0);
    const [exemptTab, setExemptTab] = React.useState(0);
    const [openAddProgram, setOpenAddProgram] = React.useState(false);
    const [openRemoveProgram, setOpenRemoveProgram] = React.useState(false);
    const [openMdcGrad, setOpenMdcGrad] = React.useState(false);
    const [semWarn, setSemWarn] = React.useState([]);
    const [exemptType, setExemptType] = React.useState('');
    const [openAddSavings, setOpenAddSavings] = React.useState(false);

    const [dualEnrollCourses, setDualEnrollCourses] = React.useState([]);
    const [aiceCourses, setAiceCourses] = React.useState([]);
    const [apCourses, setApCourses] = React.useState([]);
    const [cartCourses, setCartCourses] = React.useState([]);
    const [cspCourses, setCspCourses] = React.useState([]);
    const [dlptCourses, setDlptCourses] = React.useState([]);
    const [dsstCourses, setDsstCourses] = React.useState([]);
    const [ibCourses, setIbCourses] = React.useState([]);
    const [uexcelCourses, setUexcelCourses] = React.useState([]);
    const [exemptCourses, setExemptCourses] = React.useState([]);
    const [clepCourses, setClepCourses] = React.useState([]);
    const [actflCourses, setActflCourses] = React.useState([]);
    const [capeCourses, setCapeCourses] = React.useState([]);

    const [activeCostSavings, setActiveCostSavings] = React.useState([]);

    const [partnerPathways, setPartnerPathways] = React.useState([]); 

    
    
    const [removedCourses, setRemovedCourses] = React.useState([]);

    const [allowWarn, setAllowWarn] = React.useState(false);

    const [debugApp, setDebugApp] = React.useState(false);

    const getPathways = async () => {
        const partnerPathQuery = await axios({
            method: 'post',
            data: {},
            withCredentials: true,
            url: 'getpartnerpathways'
        });
    
        let recPartnerPaths = partnerPathQuery.data;
    
        let formattedPaths = []
    
        recPartnerPaths.map((p) => {
            let pathObj = {
                value: p.instValue,
                label: p.instLabel
            }
            formattedPaths.push(pathObj);
        })
    
        return formattedPaths;
    }

     const getCurrentUserPathway = () => {
        return userPathways.find(p => p.id === currentPathway);
    }

    const getPathwayLessCur = () => {
        return userPathways.filter(p => p.id !== currentPathway);
    }

    const updateUserPathwayWCur = (nmdccourses, nfiucourses, nsemesters) => {
        const curUserPathway = getCurrentUserPathway();
        const pathwayArray = getPathwayLessCur();

        if(curUserPathway){
            curUserPathway.mdcCourses = nmdccourses;
            curUserPathway.fiuCourses = nfiucourses;
            curUserPathway.semesters = nsemesters;
            curUserPathway.id = currentPathway;

            pathwayArray.push(curUserPathway);

            setUserPathways(pathwayArray);
        }
    }



     const activeProgramCount = () => {
        var atab = userPathways.length;        
        return atab;
     }

     React.useEffect(() => {
        const foreignLangArray = getForeignLangCourses(basePathway);
        var curCourses = courses.filter(c => c.Title && !c.Title.includes('Foreign'));

        if(foreignlangexempt){
            updateUserPathwayWCur(curCourses, fiucourses, semesters);
            setCourses(curCourses);
        } else {
            foreignLangArray.then((res) => {
                res.map((flc) => {
                curCourses.push(flc);
            });
            //updateUserPathwayWCur(curCourses, fiucourses, semesters);
            setCourses(curCourses);
            })
        }
        
     }, [foreignlangexempt]);

     const getForeignLangCourses = async (varBasePathway) => {
        const pathways = await axios({
            method: 'get',
            withCredentials: true,
            url: `/getbasepathway/${varBasePathway}`
        });
        const foreignLangCourses = pathways.data.filter(c => c.Title && c.Title.includes('Foreign'));
        return foreignLangCourses;
     }

     const handleForeignLangExempt = async (varBasePathway, mdcCourses) => {
        const foreignLangArray = await getForeignLangCourses(varBasePathway);
        var curCourses = mdcCourses.filter(c => c.Title && !c.Title.includes('Foreign'));

        if(!foreignlangexempt){
            foreignLangArray.map((flc) => {
                curCourses.push(flc);
            });
            return curCourses;
        } else {
            return curCourses;
        }
     }

     React.useEffect(() => {
        var currUserPath = userPathways.find(u => u.id === currentPathway);

        var ncurUserPath = userPathways.filter(u => u.id !== currentPathway);
        currUserPath.mdcGrad = mdcGrad;
        ncurUserPath.push(currUserPath);
        setUserPathways(ncurUserPath);

     }, [mdcGrad]);

     const getSLS = async () => {
        const slsCourseQuery = await axios({
            method: 'post',
            data: {
                CoursePrefix: 'SLS',
                CourseNumber: '1106',
                BasePathway: basePathway
            },
            withCredentials: true,
            url: '/coursesub'
        });

        var courseSub = slsCourseQuery.data;

        return courseSub;
    }

     React.useEffect(() => {
        //checkSLS();        
         
     }, [testExempt]);

     React.useEffect(() => {
         const runSlsCheck = async () => {
            let slsCourse = await getSLS();
            let nCourses = courses;
            if(dualenroll){
                nCourses = nCourses.filter(n => n.id !== slsCourse.id);
            } else {
                let slsCheck = nCourses.find(n => n.id === slsCourse.id);
                if(!slsCheck){
                    nCourses.push(slsCourse);
                }
            }
            setCourses(nCourses);
         }
         runSlsCheck();
        
     }, [dualenroll]);

     React.useEffect(() => {
         checkUser();
         if(testArray.length>0){
         //if(readingScore !== 0 && writingScore !== 0 && mathScore !== 0){
            const testableCourseArray = getTestCourses(basePathway);
            var curCoursesLessTest = courses.filter(c => !c.TESTP || c.TESTP === null);

            let testCoursesArray = []
            testableCourseArray.then(async (res) => {

                testCoursesArray = res;

                res.map((tc) => {
                    testArray.map((test) => {
                        var lowestScore = 0;
                        var tests = tc.TESTP.split(';')
    
                        tests.forEach((t, i) => {
                            if(i===0){
                                switch(t.trim()){
                                    case 'M':
                                        lowestScore=parseFloat(test.mathScore);
                                        break;
                                    case 'R':
                                        lowestScore=parseFloat(test.readingScore);
                                        break;
                                    case 'W':
                                        lowestScore=parseFloat(test.writingScore);
                                        break;
                                    default:
                                        lowestScore=parseFloat(test.mathScore);
                                }
                            } else {
                                switch(t.trim()){
                                    case 'M':
                                        lowestScore = lowestScore > parseFloat(test.mathScore) ? parseFloat(test.mathScore) : lowestScore;
                                        break;
                                    case 'R':
                                        lowestScore = lowestScore > parseFloat(test.readingScore) ? parseFloat(test.readingScore) : lowestScore;
                                        break;
                                    case 'W':
                                        lowestScore = lowestScore > parseFloat(test.writingScore) ? parseFloat(test.writingScore) : lowestScore;
                                        break;
                                    default:
                                        lowestScore=test.mathScore;
                                }
                            }
                        })
                       
                        
                        //if(test.Test === 'ACT' && tc.ACT){
                        //    var actObj = getTestNumbers(tc.ACT);
                        //    if(parseFloat(actObj.high) >= lowestScore){
                        //        if(parseFloat(actObj.low) <= lowestScore){
                                    /* if(tc.TERM > 1){
                                        tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                    } */
                        //        }
                        //        curCoursesLessTest.push(tc);
                                //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                        //    }
                       // }

                        if(test.Test === 'ACT' && tc.ACT){
                            var actObj = getTestNumbers(tc.ACT);
                            if(parseFloat(actObj.high) >= lowestScore){
                                if(parseFloat(actObj.low) <= lowestScore){
                                    /* if(tc.TERM > 1){
                                        tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                    } */
                                } 
                                //curCoursesLessTest.push(tc);
                                //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                            } else {
                                testCoursesArray = testCoursesArray.filter((t) => t.id != tc.id);
                            }
                        }

                        if(test.Test === 'SAT' && tc.SAT){
                            var satObj = getTestNumbers(tc.SAT);
                            if(parseFloat(satObj.high) >= lowestScore){
                                if(parseFloat(satObj.low) <= lowestScore){
                                    /* if(tc.TERM > 1){
                                        tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                    } */
                                } else {
                                    //tc = { ...tc, TERM: tc.TERM }
                                }                            
                                //curCoursesLessTest.push(tc);
                                //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                            } else {
                                testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                            }
                        } 

                        if(test.Test === 'PERT' && tc.PERT){
                            var pertObj = getTestNumbers(tc.PERT);
                            if(parseFloat(pertObj.high) >= lowestScore){
                                if(parseFloat(pertObj.low) <= lowestScore){
                                    /* if(tc.TERM > 1){
                                        tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                    } */
                                } else {
                                    //tc = { ...tc, TERM: tc.TERM }
                                }
                                //curCoursesLessTest.push(tc);
                                //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                            } else {
                                testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                            }
                        }
                        if(test.Test === 'ACCUPLACER' && (tc.ACCP || tc.ACCPaaf)){
    
                            let accuObj = tc.ACCP !== null ? getTestNumbers(tc.ACCP) : getTestNumbers(tc.ACCPaaf);

                            
                            if(tc.ACCPaaf && tc.ACCPaaf !== null && tc.TESTP.trim() === 'M'){
                                if(test.aafScore >= 250){
                                    lowestScore = test.aafScore;
                                    accuObj = getTestNumbers(tc.ACCPaaf)
                                }
                            }
    
                            if(parseInt(accuObj.high) >= lowestScore){
                                if(parseInt(accuObj.low) <= lowestScore){
                                    /* if(tc.TERM > 1){
                                        tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                    } */
                                } else {
                                    //tc = { ...tc, TERM: tc.TERM }
                                }                                
                                //curCoursesLessTest.push(tc);
                                //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                            } else {
                                testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                            }
                        }

                        if(tc.ACCP && !tc.ACT && !tc.SAT && !tc.PERT){
                            if(test !== 'ACCUPLACER'){
                                //curCoursesLessTest.push(tc);
                                //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                            }
                        }

                });
                    
                })

                testCoursesArray.map((tca) => {
                    curCoursesLessTest.push(tca);
                });
                

                let allCourses = curCoursesLessTest;
                let fiuTransCourses = fiucourses;
                Array.prototype.push.apply(allCourses, fiuTransCourses);

                //const cleanedPostReqs = postReqTermCheck(allCourses);
                const cleanCoReqs = coReqFix(allCourses);

                var slsCourse = await getSLS();
                
                var nMdcCourses = cleanCoReqs.filter(c => c.SheetName === 'ACSG');
                if(!testExempt){
                    nMdcCourses = cleanCoReqs.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');
                    var validSLSCourse = cleanCoReqs.find(c => c.id === slsCourse.id);
                    if(!validSLSCourse){
                        nMdcCourses.push(slsCourse);
                    }
                } else {
                    var validSLSCourse = nMdcCourses.find(c => c.id === slsCourse.id);
                    if(validSLSCourse){
                        nMdcCourses = nMdcCourses.filter(c => c.id !== slsCourse.id);
                    }
                }
                if(!dualenroll){
                    nMdcCourses = cleanCoReqs.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');
                    var validSLSCourse = cleanCoReqs.find(c => c.id === slsCourse.id);
                    if(!validSLSCourse){
                        nMdcCourses.push(slsCourse);
                    }
                } else {
                    var validSLSCourse = nMdcCourses.find(c => c.id === slsCourse.id);
                    if(validSLSCourse){
                        nMdcCourses = nMdcCourses.filter(c => c.id !== slsCourse.id);
                    }
                }
                var nFiuCourses = cleanCoReqs.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER');

                updateUserPathwayWCur(nMdcCourses, nFiuCourses, semesters);
                setCourses(nMdcCourses);
                setFiuCourses(nFiuCourses);
            }).catch((err) => {
                console.log(err);
            })
            
         } else {
             let allCourses = getBaseCourses(basePathway);

             //Here is where it is switching over 
            //and not switching back
            if(currentUser){
                allCourses = getUserCourses(currentPathway);
            }

             allCourses.then(async (res) => {

                var allCoursesRes = res;

                var nMdcCourses = allCoursesRes.filter(c => c.SheetName.trim() === 'ACSG');

                var slsCourse = await getSLS();

                /* if(!testExempt){
                    nMdcCourses = allCoursesRes.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');
                    var validSLSCourse = allCoursesRes.find(c => c.id === slsCourse.id);
                    if(!validSLSCourse){
                        nMdcCourses.push(slsCourse);
                    }
                } else {
                    var validSLSCourse = allCoursesRes.find(c => c.id === slsCourse.id);
                    if(validSLSCourse){
                        nMdcCourses = nMdcCourses.filter(c => c.id !== slsCourse.id);
                    }
                }    */     
                var nFiuCourses = allCoursesRes.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER');

                updateUserPathwayWCur(nMdcCourses, nFiuCourses, semesters);
                setCourses(nMdcCourses);
                setFiuCourses(nFiuCourses);

             }).catch((err) => {
                 console.log(err);
             })
         }
     }, [JSON.stringify(testArray), testExempt]);

     React.useEffect(() => {
        if(testExempt){
            setTestArray([]);
        }
     }, [testExempt])

     const getTestNumbers = (testNumber) => {
        var splitNumbers = testNumber.split('-');
        var testObj = {
            low: splitNumbers[0],
            high: splitNumbers[1]
        }
        return testObj;
     }

     const getDevCourses = async (varBasePathway) => {
         const devPathways = await axios({
             method: 'post',
             data: {
                 BasePathway: varBasePathway
             },
             withCredentials: true,
             url: '/getdev'
         });

         const devCourses = devPathways.data;
         return devCourses;
     }

     const getBaseCourses = async (varBasePathway) => {
         const pathways = await axios({
             method: 'get',
             withCredentials: true,
             url: `/getbasepathway/${varBasePathway}`
         });

         const baseCourses = pathways.data;
         return baseCourses;
     }

     const getUserCourses = async (pathway) => {
        const pathways = await axios({
            method: 'get',
            withCredentials: true,
            url: `/getpathways/${pathway}`
        });

        const userCourses = pathways.data;
        return userCourses;
    }

     const getTestCourses = async (varBasePathway) => {
        try{
         const pathways = await axios({
             method: 'get',
             withCredentials: true,
             url: `/getbasepathway/${varBasePathway}`
         });

         const newpathways = await axios({
            method: 'get',
            withCredentials: true,
            url: `/getbasepathway/${varBasePathway}`
        });

         //const testableCourses = pathways.data.filter(c => c.ACCP && c.ACCP !== 'NULL')
         const testableCourses = newpathways.data.filter(c => c.TESTP && c.TESTP !== 'NULL')
         return testableCourses;
        } catch (e) {
            console.log(e);
        }
     }

     const handleTestEvalCourses = async (newPathwayBase, npMdcCourses, npFiuCourses) => {
         return new Promise((resolve, reject) => {
             if(testArray.length > 0){
                 let testableCourseArray = getTestCourses(newPathwayBase);
                 var curCoursesLessTest = npMdcCourses.filter(c => !c.TESTP || c.TESTP === null);
                 let testCoursesArray = [];
                 testableCourseArray.then((res) => {
                    testCoursesArray = res;

                    res.map((tc) => {
                        
                        let appTests = tc.TEST.split(';');
                        
                        testArray.some((test) => {
                            let lScore = 0;
                            
                            appTests.forEach((t, i) => {
                                if(i===0){
                                    switch(t.trim()){
                                        case 'M':
                                            lScore=test.mathScore;
                                            break;
                                        case 'R':
                                            lScore=test.readingScore;
                                            break;
                                        case 'W':
                                            lScore=test.writingScore;
                                            break;
                                        default:
                                            lScore=test.mathScore;
                                    }
                                } else {
                                    switch(t.trim()){
                                        case 'M':
                                            lScore = lScore < test.mathScore ? test.mathScore : lScore;
                                            break;
                                        case 'R':
                                            lScore = lScore < test.readingScore ? test.readingScore : lScore;
                                            break;
                                        case 'W':
                                            lScore = lScore < test.writingScore ? test.writingScore : lScore;
                                            break;
                                        default:
                                            lScore=test.mathScore;
                                    }
                                }
                            });
                            if(test.Test === 'ACT' && tc.ACT){
                                var actObj = getTestNumbers(tc.ACT);
                                if(parseFloat(actObj.high) >= lScore){
                                    if(parseFloat(actObj.low) <= lScore){
                                       //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                    }
                                } else {
                                    testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }
                            if(test.Test === 'SAT' && tc.SAT){
                                var satObj = getTestNumbers(tc.SAT);
                                if(parseFloat(satObj.high) >= lScore){
                                    if(parseFloat(satObj.low) <= lScore){
                                        //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                    }                           
                                } else {
                                    testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }

                            if(test.Test === 'PERT' && tc.PERT){
                                var pertObj = getTestNumbers(tc.PERT);
                                if(parseFloat(pertObj.high) >= lScore){
                                    if(parseFloat(pertObj.low) <= lScore){
                                        //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                    }
                                } else {
                                    testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }
                            if(test.Test === 'ACCUPLACER' && (tc.ACCP || tc.ACCPaaf)){
                                //console.log(tc);
        
                                var accuObj = tc.ACCP !== null ? getTestNumbers(tc.ACCP) : getTestNumbers(tc.ACCPaaf);
                                
                                if(tc.ACCPaaf && tc.ACCPaaf !== null && tc.TESTP.trim() === 'M'){
                                    if(test.aafScore >= 250){
                                        lScore = test.aafScore;
                                        accuObj = getTestNumbers(tc.ACCPaaf)
                                    }
                                }
        
                                if(parseInt(accuObj.high) >= lScore){
                                    if(parseInt(accuObj.low) <= lScore){
                                        //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                    } else {
                                        tc = { ...tc, TERM: tc.TERM }
                                    }
                                } else {
                                    testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }
                            if(tc.ACCP && !tc.ACT && !tc.SAT && !tc.PERT){
                                if(test !== 'ACCUPLACER'){

                                }
                            }                            

                        });

                    });
                 }).catch((err) => {
                     reject(err);
                     console.log(err);
                 });

                 testCoursesArray.map((tca) => {
                    curCoursesLessTest.push(tca);
                });

                    var allCourses = curCoursesLessTest;
                    var fiuTransCourses = npFiuCourses;

                    Array.prototype.push.apply(allCourses, fiuTransCourses);
                    
                    var nMdcCourses = allCourses.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');
                    if(testExempt){
                        nMdcCourses = allCourses.filter(c => c.SheetName === 'ACSG');
                    } 
                    
                    var nFiuCourses = allCourses.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER')
                    
                    var nCourses = {
                        mdc: nMdcCourses,
                        fiu: nFiuCourses
                    }
                    resolve(nCourses);

             } else {
                var nCourses = {
                    mdc: npMdcCourses,
                    fiu: npFiuCourses
                }
                resolve(nCourses);
             }

         });
     }

     const handleOldTestEvalCourses = async (newPathwayBase, npMdcCourses, npFiuCourses ) => {
         return new Promise((resolve, reject) => {
            if(testArray.length > 0){
                const testableCourseArray = getTestCourses(newPathwayBase);
                var curCoursesLessTest = npMdcCourses.filter(c => !c.TESTP || c.TESTP === null);
                testableCourseArray.then((res) => {

                    let testCoursesArray = res;

                    testArray.map((test) => {
                        res.map((tc) => {

                            var lowestScore = 0;
                            var tests = tc.TESTP.split(';')
    
                            tests.forEach((t, i) => {
                                if(i===0){
                                    switch(t.trim()){
                                        case 'M':
                                            lowestScore=test.mathScore;
                                            break;
                                        case 'R':
                                            lowestScore=test.readingScore;
                                            break;
                                        case 'W':
                                            lowestScore=test.writingScore;
                                            break;
                                        default:
                                            lowestScore=test.mathScore;
                                    }
                                } else {
                                    switch(t.trim()){
                                        case 'M':
                                            lowestScore = lowestScore > test.mathScore ? test.mathScore : lowestScore;
                                            break;
                                        case 'R':
                                            lowestScore = lowestScore > test.readingScore ? test.readingScore : lowestScore;
                                            break;
                                        case 'W':
                                            lowestScore = lowestScore > test.writingScore ? test.writingScore : lowestScore;
                                            break;
                                        default:
                                            lowestScore=test.mathScore;
                                    }
                                }
                            })
    
                            if(test.Test === 'ACT' && tc.ACT){
                                var actObj = getTestNumbers(tc.ACT);
                                if(parseFloat(actObj.high) >= lowestScore){
                                    if(parseFloat(actObj.low) <= lowestScore){
                                        /* if(tc.TERM > 1){
                                            tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                        } */
                                    }
                                    //curCoursesLessTest.push(tc);
                                    //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                } else {
                                    testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }
                            if(test.Test === 'SAT' && tc.SAT){
                                var satObj = getTestNumbers(tc.SAT);
                                if(parseFloat(satObj.high) >= lowestScore){
                                    if(parseFloat(satObj.low) <= lowestScore){
                                        /* if(tc.TERM > 1){
                                            tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                        } */
                                    } else {
                                       // tc = { ...tc, TERM: tc.TERM }
                                    }                            
                                    //curCoursesLessTest.push(tc);
                                    //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                } else {
                                    testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }

                            if(test.Test === 'PERT' && tc.PERT){
                                var pertObj = getTestNumbers(tc.PERT);
                                if(parseFloat(pertObj.high) >= lowestScore){
                                    if(parseFloat(pertObj.low) <= lowestScore){
                                        /* if(tc.TERM > 1){
                                            tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                        } */
                                    } else {
                                       // tc = { ...tc, TERM: tc.TERM }
                                    }
                                    //curCoursesLessTest.push(tc);
                                    //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                } else {
                                    testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }
                            if(test.Test === 'ACCUPLACER' && (tc.ACCP || tc.ACCPaaf)){
                                //console.log(tc);
        
                                var accuObj = tc.ACCP !== null ? getTestNumbers(tc.ACCP) : getTestNumbers(tc.ACCPaaf);
                                
                                if(tc.ACCPaaf && tc.ACCPaaf !== null && tc.TESTP.trim() === 'M'){
                                    if(aafScore >= 250){
                                        lowestScore = aafScore;
                                        accuObj = getTestNumbers(tc.ACCPaaf)
                                    }
                                }
        
                                if(parseInt(accuObj.high) >= lowestScore){
                                    if(parseInt(accuObj.low) <= lowestScore){
                                        /* if(tc.TERM > 1){
                                            tc = { ...tc, TERM: parseInt(tc.TERM) - 1 }
                                        } */
                                    } else {
                                       // tc = { ...tc, TERM: tc.TERM }
                                    }                                
                                    //curCoursesLessTest.push(tc);
                                    //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                } else {
                                    testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }
                            if(tc.ACCP && !tc.ACT && !tc.SAT && !tc.PERT){
                                if(test !== 'ACCUPLACER'){
                                    //curCoursesLessTest.push(tc);
                                    //testCoursesArray = testCoursesArray.filter((t) => t.id !== tc.id);
                                }
                            }
                        });

                    });

                    testCoursesArray.map((tca) => {
                        curCoursesLessTest.push(tca);
                    })
                    
    
                    var allCourses = curCoursesLessTest;
                    var fiuTransCourses = npFiuCourses;

                    Array.prototype.push.apply(allCourses, fiuTransCourses);
                    
                    var nMdcCourses = allCourses.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');
                    if(testExempt){
                        nMdcCourses = allCourses.filter(c => c.SheetName === 'ACSG');
                    } 
                    
                    var nFiuCourses = allCourses.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER')
                    var nCourses = {
                        mdc: nMdcCourses,
                        fiu: nFiuCourses
                    }
                    /* updateUserPathwayWCur(nMdcCourses, nFiuCourses, semesters);
                    setCourses(nMdcCourses);
                    setFiuCourses(nFiuCourses); */
                    resolve(nCourses);
                }).catch((err) => {
                    reject(err);
                    console.log(err);
                })
                
             } else {
                 var nCourses = {
                     mdc: npMdcCourses,
                     fiu: npFiuCourses
                 }
                 resolve(nCourses);
             }

         })
        
     }

     const performTestEmail = async () => {
         const testEmail = await axios({
             method: 'get',
             withCredentials: true,
             url: '/testwelcomeemail'
         });
     }

     const checkCourseCompleted = (sem) => {
        var courseComp = false;
        
        if(sem !== 0){
            const courseSem = semesters.find(s => s.id === `s${sem}`);
            courseComp = courseSem.completed;
         }
         
         return courseComp;
     }

     const preReqLoop = (courseArray, courseId) => {
         var allCourseArray = courseArray;

         var nCid = courseId;

         let counter = 0;

         /* do {
            let course = allCourseArray.find(c => c.id === nCid);
            if(!course){
                course = dualEnrollCourses.find(d => d.id === nCid);
            }
            if(course.PREREQ){
                let allPreReqs = course.PREREQ.split(';');

                let activePreReqs = [];

                allPreReqs.forEach(p => {
                    let preReq = allCourseArray.find(c => `${c.CoursePrefix}${c.CourseNumber}` === p.trim());
                    if(preReq){
                        activePreReqs.push(preReq);
                    }
                });

                activePreReqs.sort((a, b) => (a.TERM > b.TERM) ? 1 : -1);
                activePreReqs.reverse();

                let bumpTerm = course.TERM;

                activePreReqs.forEach(function (a, i){
                    if(i === 0){
                        if(a.TERM >= course.TERM){
                            this[i] = { ...a, TERM: course.TERM - 1 }
                        }
                    } else {
                        let p = i - 1;
                        let prePreReq = this[p];
                        if(prePreReq.COREQ === `${a.CoursePrefix}${a.CourseNumber}`){
                            this[i] = { ...a, TERM: prePreReq.TERM }
                        } else {
                            if(prePreReq.TERM <= a.TERM){
                                this[i] = { ...a, TERM: prePreReq.TERM - 1 }
                            }
                        }
                    }
                }, activePreReqs);

                activePreReqs.forEach(function(a, i){
                    allCourseArray = allCourseArray.filter(c => c.id !== a.id);
                    allCourseArray.push(a); 
                });

                nCid = null;
            } else {
                nCid = null;
            }
         }
         while(nCid !== null); */


         do {
            var course = allCourseArray.find(c => c.id === nCid);
            if(!course){
                let activeArray = [];
                for(let i=0; i < activeCostSavings.length; i++){
                    switch(activeCostSavings[i].value){
                        case 'DE':
                            activeArray = dualEnrollCourses;
                            break;
                        case 'AICE':
                            activeArray = aiceCourses;
                            break;
                        case 'AP':
                            activeArray = apCourses;
                            break;
                        case 'CART':
                            activeArray = cartCourses;
                            break;
                        case 'CSP':
                            activeArray = cspCourses;
                            break;
                        case 'DLPT':
                            activeArray = dlptCourses;
                            break;
                        case 'DSST':
                            activeArray = dsstCourses;
                            break;
                        case 'IB':
                            activeArray = ibCourses;
                            break;
                        case 'Uexcel':
                            activeArray = uexcelCourses;
                            break;
                        case 'CLEP':
                            activeArray = clepCourses;
                            break;
                        case 'Exempt':
                            activeArray = exemptCourses;
                            break;
                        case 'ACTFL':
                            activeArray = actflCourses;
                            break;
                        case 'CAPE':
                            activeArray = capeCourses;
                            break;
                    }

                    course = activeArray.find(d => d.id === nCid);
                    if(course){
                        break;
                    }
                }
            }
            if(course.PREREQ){
                var mainPreReqs = course.PREREQ.split(';');
                mainPreReqs.map((pr, index) => {
                    if(pr.toUpperCase().includes(' OR ')){
                        var preReqs = pr.toUpperCase().split(' OR ');
                        var checkId = nCid;
                        preReqs.map((pr) => {
                            var preReq = preReqLookup(allCourseArray, pr.trim());
                            if(preReq){
                                var preCoReq = null;
                                if(preReq.COREQ){
                                    preCoReq = coReqLookup(allCourseArray, preReq.COREQ.trim());
                                }
                                if(parseInt(preReq.TERM) >= parseInt(course.TERM)){
                                    if(course.TERM !== 1){
                                        preReq = { ...preReq, TERM: parseInt(course.TERM) - 1 }
                                    } else {
                                        preReq = { ...preReq, TERM: 1 }
                                    }
                                    allCourseArray = allCourseArray.filter(c => c.id !== preReq.id);
                                    allCourseArray.push(preReq);
                                    if(preCoReq){
                                        if(course.TERM !== 1){
                                            preCoReq = { ...preCoReq, TERM: parseInt(course.TERM) - 1 }
                                        } else {
                                            preCoReq = { ...preCoReq, TERM: 1 }
                                        }
                                        allCourseArray = allCourseArray.filter(c => c.id !== preCoReq.id);
                                        allCourseArray.push(preCoReq);
                                    }
                                    nCid=preReq.id;
                                }
                                nCid=preReq.id;
                            } else {
                                
                            }
                        });
                        if(nCid === checkId){
                            nCid=null;
                        }                    
                    } else {
                        //var preReqs = course.PREREQ.split(';');
                        //preReqs.map((pr) => {
                            var preReq = preReqLookup(allCourseArray, pr.trim());
                            if(preReq){
                                var preCoReq = null;
                                if(preReq.COREQ){
                                    preCoReq = coReqLookup(allCourseArray, preReq.COREQ.trim());
                                }
                                if(parseInt(preReq.TERM) >= parseInt(course.TERM)){
                                    let nTerm = getPrevTerm(parseInt(course.TERM) - 1);
                                    if(course.TERM !== 1){
                                        preReq = { ...preReq, TERM: nTerm }
                                    } else {
                                        preReq = { ...preReq, TERM: 1 }
                                    }
                                    allCourseArray = allCourseArray.filter(c => c.id !== preReq.id);
                                    allCourseArray.push(preReq);
                                    if(preCoReq){
                                        if(course.TERM !== 1){
                                            preCoReq = { ...preCoReq, TERM: nTerm }
                                        } else {
                                            preCoReq = { ...preCoReq, TERM: 1 }
                                        }
                                        allCourseArray = allCourseArray.filter(c => c.id !== preCoReq.id);
                                        allCourseArray.push(preCoReq);
                                    }
                                    allCourseArray = preReqLoop(allCourseArray, preReq.id)
                                    nCid=preReq.id;
                                }
                                nCid=preReq.id;
                            } else {
                                //Add Dual Enrollment Course Loop Check
                                preReq = preReqLookup(dualEnrollCourses, pr.trim());
                                if(preReq){
                                    if(preReq.PREREQ){
                                        allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                    } else {
                                        nCid=null;
                                    }                                    
                                } else {
                                    preReq = preReqLookup(aiceCourses, pr.trim());
                                    if(preReq){
                                        if(preReq.PREREQ){
                                            allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                        } else {
                                            nCid=null;
                                        }
                                    } else {
                                        preReq = preReqLookup(apCourses, pr.trim());
                                        if(preReq){
                                            if(preReq.PREREQ){
                                                allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                            } else {
                                                nCid=null;
                                            }
                                        } else {
                                            preReq = preReqLookup(cartCourses, pr.trim());
                                            if(preReq){
                                                if(preReq.PREREQ){
                                                    allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                } else {
                                                    nCid=null;
                                                }
                                            } else {
                                                preReq = preReqLookup(cspCourses, pr.trim());
                                                if(preReq){
                                                    if(preReq.PREREQ){
                                                        allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                    } else {
                                                        nCid=null;
                                                    }
                                                } else {
                                                    preReq = preReqLookup(dlptCourses, pr.trim());
                                                    if(preReq){
                                                        if(preReq.PREREQ){
                                                            allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                        } else {
                                                            nCid=null;
                                                        }
                                                    } else {
                                                        preReq = preReqLookup(dsstCourses, pr.trim());
                                                        if(preReq){
                                                            if(preReq.PREREQ){
                                                                allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                            } else {
                                                                nCid=null;
                                                            }
                                                        } else {
                                                            preReq = preReqLookup(ibCourses, pr.trim());
                                                            if(preReq){
                                                                if(preReq.PREREQ){
                                                                    allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                                } else {
                                                                    nCid=null;
                                                                }
                                                            } else {
                                                                preReq = preReqLookup(uexcelCourses, pr.trim());
                                                                if(preReq){
                                                                    if(preReq.PREREQ){
                                                                        allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                                    } else {
                                                                        nCid=null;
                                                                    }
                                                                } else {
                                                                    preReq = preReqLookup(exemptCourses, pr.trim());
                                                                    if(preReq){
                                                                        if(preReq.PREREQ){
                                                                            allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                                        } else {
                                                                            nCid=null;
                                                                        }
                                                                    } else {
                                                                        preReq = preReqLookup(clepCourses, pr.trim());
                                                                        if(preReq){
                                                                            if(preReq.PREREQ){
                                                                                allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                                            } else {
                                                                                nCid=null;
                                                                            }
                                                                        } else {
                                                                            preReq = preReqLookup(actflCourses, pr.trim());
                                                                            if(preReq){
                                                                                if(preReq.PREREQ){
                                                                                    allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                                                } else {
                                                                                    nCid=null;
                                                                                }
                                                                            } else {
                                                                                preReq = preReqLookup(capeCourses, pr.trim());
                                                                                if(preReq){
                                                                                    if(preReq.PREREQ){
                                                                                        allCourseArray = preReqLoop(allCourseArray, preReq.id);
                                                                                    } else {
                                                                                        nCid=null;
                                                                                    }
                                                                                } else {
                                                                                    if(nCid === pr.id){
                                                                                        nCid=null;
                                                                                    }
                                                                                    if(mainPreReqs.length - 1 === index){
                                                                                        nCid=null;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }                                                                        
                                                                    }
                                                                }                                                                
                                                            }
                                                        }                                                        
                                                    }
                                                }                                                
                                            }                                            
                                        }                                        
                                    }
                                    
                                }                                
                            }
                        //});
                    }
                    counter++;
                    if(counter>mainPreReqs.length){
                        nCid=null;
                    }
                });                
            } else {
                nCid=null;
            }
         }
         while(nCid !== null);

         return allCourseArray;
     }

     const postReqLoop = (courseArray, courseId) => {
        var allCourseArray = courseArray;

        var nCid = courseId;

        let counter = 0;

        

        //New Failed

        /* 
        let course = allCourseArray.find(c => c.id === nCid);
        if(course.POSTREQ){
            let mainPostReqs = course.POSTREQ.split(';');
            let activePostReqs = [];
            mainPostReqs.forEach((pr, i) => {
                let postReq = postReqLookup(allCourseArray, pr.trim());
                if(postReq){
                    activePostReqs.push(postReq);
                }
            });

            activePostReqs.sort((a, b) => parseInt(a.TERM) > parseInt(b.TERM) ? 1 : -1);
            //activePostReqs.reverse();

            let curTerm = course.TERM;

            activePostReqs.forEach(function(pr, i){
                if(pr.POSTREQ){
                    let postPostReqs = pr.POSTREQ.split(';');


                }



                if(i !== 0){
                    let p = i -1;
                    let prePostReq = this[p];
                    if(prePostReq.COREQ && prePostReq.COREQ.trim() === `${pr.CoursePrefix}${pr.CourseNumber}`.trim()){
                        this[i].TERM = prePostReq.TERM;
                    } else {
                        if(parseInt(course.TERM) >= parseInt(pr.TERM) || parseInt(prePostReq.TERM) >= parseInt(pr.TERM)){
                            let nTerm = parseInt(course.TERM) > parseInt(prePostReq.TERM) ? parseInt(course.TERM) : parseInt(prePostReq.TERM);
                            nTerm = parseInt(pr.TERM) + 1;
                            this[i].TERM = nTerm;                        }
                    }
                } else {
                    if(parseInt(pr.TERM) <= parseInt(course.TERM)){
                        this[i].TERM = parseInt(course.TERM) + 1;
                    }
                }
                allCourseArray = allCourseArray.filter(a => a.id !== pr.id);
                allCourseArray.push(pr);
            }, activePostReqs);

        } */

        do {
            let course = allCourseArray.find(c => c.id === nCid);
            if(!course){
                let activeArray = [];
                for(let i=0; i < activeCostSavings.length; i++){
                    switch(activeCostSavings[i].value){
                        case 'DE':
                            activeArray = dualEnrollCourses;
                            break;
                        case 'AICE':
                            activeArray = aiceCourses;
                            break;
                        case 'AP':
                            activeArray = apCourses;
                            break;
                        case 'CART':
                            activeArray = cartCourses;
                            break;
                        case 'CSP':
                            activeArray = cspCourses;
                            break;
                        case 'DLPT':
                            activeArray = dlptCourses;
                            break;
                        case 'DSST':
                            activeArray = dsstCourses;
                            break;
                        case 'IB':
                            activeArray = ibCourses;
                            break;
                        case 'Uexcel':
                            activeArray = uexcelCourses;
                            break;
                        case 'CLEP':
                            activeArray = clepCourses;
                            break;
                        case 'Exempt':
                            activeArray = exemptCourses;
                            break;
                        case 'ACTFL':
                            activeArray = actflCourses;
                            break;
                        case 'CAPE':
                            activeArray = capeCourses;
                            break;
                    }

                    course = activeArray.find(d => d.id === nCid);
                    if(course){
                        break;
                    }
                }
            }
            if(course.POSTREQ){
                var mainPostReqs = course.POSTREQ.split(';');
                mainPostReqs.forEach((pr, i) => {
                    if(pr.toUpperCase().includes(' OR ')){
                        var postReqs = pr.toUpperCase().split(' OR ');
                        postReqs.forEach((pr, i) => {
                            var postReq = postReqLookup(allCourseArray, pr.trim());
                            if(postReq){
                                var postCoReq = null;
                                if(postReq.COREQ){
                                    postCoReq = coReqLookup(allCourseArray, postReq.COREQ.trim());
                                }
                                if(parseInt(postReq.TERM) <= parseInt(course.TERM)){

                                    postReq = { ...postReq, TERM: parseInt(course.TERM) + 1 }
                                    allCourseArray = allCourseArray.filter(c => c.id !== postReq.id);
                                    allCourseArray.push(postReq);
                                    if(postCoReq){
                                        postCoReq = { ...postCoReq, TERM: parseInt(course.TERM) + 1 }
                                        allCourseArray = allCourseArray.filter(c => c.id !== postCoReq.id);
                                        allCourseArray.push(postCoReq);
                                    }
                                    allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                    nCid=postReq.id;
                                }
                                nCid=postReq.id;
                            } else {

                                if(postReqs.length - 1 === i){
                                    nCid=null;
                                }
                            }                            
                        });
                    } else {
                        //var postReqs = course.POSTREQ.split(';');
                        //postReqs.map((pr) => {
                            var postReq = postReqLookup(allCourseArray, pr.trim());
                            if(postReq){
                                var postCoReq = null;
                                if(postReq.COREQ){
                                    postCoReq = coReqLookup(allCourseArray, postReq.COREQ.trim());
                                }
                                if(parseInt(postReq.TERM) <= parseInt(course.TERM)){
                                    let newTerm = getNextTerm(parseInt(course.TERM) + 1);
                                    //postReq = { ...postReq, TERM: parseInt(course.TERM) + 1 }
                                    postReq = { ...postReq, TERM: newTerm }
                                    allCourseArray = allCourseArray.filter(c => c.id !== postReq.id);
                                    allCourseArray.push(postReq);
                                    if(postCoReq){
                                        //postCoReq = { ...postCoReq, TERM: parseInt(course.TERM) + 1 }
                                        postCoReq = { ...postCoReq, TERM: newTerm }
                                        allCourseArray = allCourseArray.filter(c => c.id !== postCoReq.id);
                                        allCourseArray.push(postCoReq);
                                    }
                                    allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                    nCid=postReq.id;
                                }
                                nCid=postReq.id;
                                
                            } else {
                                //Add Dual Enrollment Logic Here
                                postReq = postReqLookup(dualEnrollCourses, pr.trim());
                                if(postReq){
                                    allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                } else {
                                    postReq = postReqLookup(aiceCourses, pr.trim());
                                    if(postReq){
                                        allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                    } else {
                                        postReq = postReqLookup(apCourses, pr.trim());
                                        if(postReq){
                                            allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                        } else {
                                            postReq = postReqLookup(cartCourses, pr.trim());
                                            if(postReq){
                                                allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                            } else {
                                                postReq = postReqLookup(cspCourses, pr.trim());
                                                if(postReq){
                                                    allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                } else {
                                                    postReq = postReqLookup(dlptCourses, pr.trim());
                                                    if(postReq){
                                                        allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                    } else {
                                                        postReq = postReqLookup(dsstCourses, pr.trim());
                                                        if(postReq){
                                                            allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                        } else {
                                                             postReq = postReqLookup(ibCourses, pr.trim());
                                                             if(postReq){
                                                                allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                             } else {
                                                                 postReq = postReqLookup(uexcelCourses, pr.trim());
                                                                 if(postReq){
                                                                    allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                                 } else {
                                                                     postReq = postReqLookup(exemptCourses, pr.trim());
                                                                     if(postReq){
                                                                        allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                                     } else {
                                                                         postReq = postReqLookup(clepCourses, pr.trim());
                                                                         if(postReq){
                                                                            allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                                         } else {
                                                                            postReq = postReqLookup(actflCourses, pr.trim());
                                                                            if(postReq){
                                                                                allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                                            } else {
                                                                                postReq = postReqLookup(capeCourses, pr.trim());
                                                                                if(postReq){
                                                                                    allCourseArray = postReqLoop(allCourseArray, postReq.id);
                                                                                } else {
                                                                                    if(pr.length - 1 === i){
                                                                                        nCid=null;
                                                                                    } 
                                                                                } 
                                                                            }
                                                                         }
                                                                     }
                                                                 }
                                                             }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }                         
                            }
                        //});
                    }
                });
                if(counter>mainPostReqs.length){
                    nCid=null;
                }          
            } else {
                nCid = null;
            }
            counter++;
        }
        while(nCid !== null);

        return allCourseArray;
     }

     const getNextTerm = (term) => {
        let found = true;
        let nTerm = term;
        do{
            let nextSem = semesters.find(s => parseInt(s.id.substr(1)) === parseInt(nTerm));
            if(nextSem && nextSem.completed){
                nTerm++;
            }else{
                found=false;
            }
        }
        while(found);
        return nTerm;
     }

     const getPrevTerm = (term) => {
        let found = true;
        let nTerm = term;
        do{
            let prevSem = semesters.find(s => parseInt(s.id.substr(1)) === parseInt(nTerm));

            if(prevSem && prevSem.completed){
                nTerm--;
            }else{
                found=false;
            }
        }
        while(found);
        return nTerm;
     }

     const postReqTermCheck = (courseArray) => {
        var allCourseArray = courseArray;
        const postReqArray = courseArray.filter(c => c.POSTREQ && c.POSTREQ !== 'NULL')
        postReqArray.map((course) => {
            var postReqTerm = parseInt(course.TERM) + 1;
            if(course.POSTREQ.includes('Foreign') || course.POSTREQ.includes('Behavioral') || course.POSTREQ.includes('Humanities')){

            } else {
                if(course.POSTREQ.includes(' OR ')){
                    var postReqs = course.POSTREQ.split(' OR ');
                    postReqs.map((pr) => {
                        var postReq = postReqLookup(courseArray, pr.trim());
                        if(postReq){
                            if(parseInt(postReq.TERM) !== postReqTerm){
                                postReq = { ...postReq, TERM: postReqTerm }
                                allCourseArray = allCourseArray.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== pr.trim());
                                allCourseArray.push(postReq);
                            }
                        }
                    });

                } else {
                        var postReqNum = course.POSTREQ;
                        
                        var postReq = postReqLookup(allCourseArray, postReqNum.trim());

                        if(postReq){
                            if(parseInt(postReq.TERM) <= course.TERM){
                                postReq = { ...postReq, TERM: postReqTerm }
                                allCourseArray = allCourseArray.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== postReqNum.trim());
                                allCourseArray.push(postReq);
                            }
                        }
                }
            }
        });
        
        return allCourseArray;
     }

     const coReqFix = (cArray) => {
         const coReqArray = cArray.filter(c => c.COREQ && c.COREQ !== ' NULL' && c.COREQ !== '');

         coReqArray.map((course) => {
             var coReq = coReqLookup(cArray, course.COREQ);
             
             if(coReq.TERM !== course.TERM){
                 if(coReq.TERM > course.TERM){
                     coReq = { ...coReq, TERM: course.TERM }
                     cArray = cArray.filter(c => c.id !== coReq.id);
                     cArray.push(coReq);
                 }
             }
         });

         return cArray;
     }

     const coReqLookup = (courseArray, coReqNum) => {
         var coReq = null;
         if(coReqNum.includes('Foreign') || coReqNum.includes('Behavioral') || coReqNum.includes('Humanities')){
            coReq = courseArray.find(c => c.Title === coReqNum);
        } else {
            coReq = courseArray.find(c => `${c.CoursePrefix && c.CoursePrefix.trim()}${c.CourseNumber && c.CourseNumber.trim()}` === coReqNum.trim());
        }
         return coReq;
     }

    const postReqLookup = (courseArray, postReqNum) => {
        var postReq = null;
        if(postReqNum.includes('Foreign') || postReqNum.includes('Behavioral') || postReqNum.includes('Humanities')){
            postReq = courseArray.find(c => c.Title === postReqNum);
        } else {
            postReq = courseArray.find(c => `${c.CoursePrefix}${c.CourseNumber}` === postReqNum.trim());
        }
         return postReq;
    }

    const preReqLookup = (courseArray, preReqNum) => {
        var preReq = null;
        if(preReqNum.includes('Foreign') || preReqNum.includes('Behavioral') || preReqNum.includes('Humanities')){
            preReq = courseArray.find(c => c.Title === preReqNum);
        } else {
            preReq = courseArray.find(c => `${c.CoursePrefix}${c.CourseNumber}` === preReqNum.trim());
        }
        return preReq;
    }


     React.useEffect(() => {
        const changeData = async () => {

            var nfiuCourses;
            var nmdcCourses;

            const pathways = await axios({
                method: 'get',
                withCredentials: true,
                url: `/getpathways/${fiupathway}`
            });

                nfiuCourses = pathways.data.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER')
                nmdcCourses = pathways.data.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV')
                if(testExempt){
                    nmdcCourses = pathways.data.filter(c => c.SheetName === 'ACSG');
                }

                const currentUserPath = userPathways.find(p => p.id === currentPathway);

                var pathNaming = '';

                let pathChosen = partnerPathways.find((p) => p.value === fiupathway);

                if(pathChosen){
                    pathNaming = pathChosen.label
                } else {
                    pathNaming = currentUserPath.name;
                }

                /* switch(fiupathway){
                    case 'BIO_BA_DATA':
                        pathNaming = 'Bachelor of Arts Biology';
                        break;
                    case 'BIO_BS_DATA':
                        pathNaming = 'Bachelor of Science Biology';
                        break;
                    case 'FIU_BS_BIOMEDICAL-ENGINEERING':
                        pathNaming = 'Bachelor of Science Biomedical Engineering';
                        break;
                    case 'FIU_BA_CHEMISTRY':
                        pathNaming = 'Bachelor of Arts Chemistry';
                        break;
                    case 'FIU_BS_CHEMISTRY':
                        pathNaming = 'Bachelor of Science Chemistry';
                        break;
                    default:
                        pathNaming = currentUserPath.name;
                }  */               

                setBasePathway(fiupathway);
                currentUserPath.basePathway = fiupathway;
                currentUserPath.name = pathNaming;

                var pathwaysLessCurrent = userPathways.filter(p => p.id !== currentPathway);
                pathwaysLessCurrent.push(currentUserPath);
                setUserPathways(pathwaysLessCurrent);


            updateUserPathwayWCur(nmdcCourses, nfiuCourses, semesters);
            setCourses(nmdcCourses);
            setFiuCourses(nfiuCourses);
        }
        
        changeData();

     }, [fiupathway]);

     const handleShowReset = () => {
        setShowReset(true);
     }

     const handleCloseReset = async (reset, pathway) => {
         if(reset){
                let resetPathway = pathway.value;
                let resetPathwayName = pathway.label;
                const newPathwayId = generateNewId();
                const newUserPathwayBasePathway = resetPathway;
                const newUserPathwayName = resetPathwayName;
                const newUserFullPathway = await axios({
                    method: 'GET',
                    withCredentials: true,
                    url: `/getbasepathway/${resetPathway}`
                });

                var allCurrentCourses = [...courses];
                var addPartCourses = [...fiucourses];
                var newFullCourses = newUserFullPathway.data;
                var newSemesters = [...semesterList];


                var newFlMdcCourses = newFullCourses.filter(nfc => nfc.SheetName === 'ACSG');
                if(!testExempt){
                    newFlMdcCourses = newFullCourses.filter(nfc => nfc.SheetName === 'ACSG' || nfc.SheetName === 'DEV');
                }

                var newTestFiuCourses = newFullCourses.filter(nfc => nfc.SheetName === 'BCSG' || nfc.SheetName === 'TRANSFER');

                //Check Foreign Language Criteria
                var afterForeignLangMdc = await handleForeignLangExempt(newUserPathwayBasePathway, newFlMdcCourses);

                //Check Testing Criteria
                var revisedCourses = await handleTestEvalCourses(newUserPathwayBasePathway, afterForeignLangMdc, newTestFiuCourses);
/*                 revisedCourses.mdc = revisedCourses.mdc.filter(m => {
                    let courseExempt = dualEnrollCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    if(!courseExempt){
                        return m;
                    }
                }); */

                newFullCourses = [ ...revisedCourses.mdc ];
                var fiuPostTest = [ ...revisedCourses.fiu ];

                Array.prototype.push.apply(newFullCourses, fiuPostTest);

                Array.prototype.push.apply(allCurrentCourses, addPartCourses);

                const originalArray = [ ...newFullCourses ];

                newSemesters.map((s) => {
                    if(s.completed){
                        var semCourses = allCurrentCourses.filter(c => parseInt(c.TERM) === parseInt(s.id.substr(1)));
                        var newSemCourses = newFullCourses.filter(c => parseInt(c.TERM) === parseInt(s.id.substr(1)));

                        newSemCourses.map((nSem) => {
                            nSem = { ...nSem, TERM: parseInt(s.id.substr(1)) + 1 }
                            newFullCourses = newFullCourses.filter(n => n.id !== nSem.id);
                            newFullCourses.push(nSem);
                        });

                        semCourses.map((cSem) => {
                            var eqNew;
                            if(cSem.Title.includes('Humanities') || cSem.Title.includes('Foreign') || cSem.Title.includes('Behavioral') || cSem.Title.includes('Oral ')){
                                eqNew = newFullCourses.find(n => n.Title === cSem.Title);
                                if(eqNew){
                                    eqNew = { ...eqNew, TERM: parseInt(s.id.substr(1)), CoursePrefix: cSem.CoursePrefix, CourseNumber: cSem.CourseNumber }
                                    newFullCourses = newFullCourses.filter(n => n.id !== eqNew.id);
                                    newFullCourses.push(eqNew);
                                } else {
                                    newFullCourses.push(cSem);
                                }
                            } else {
                                eqNew = newFullCourses.find(n => `${n.CourseNumber}${n.CoursePrefix}` === `${cSem.CourseNumber}${cSem.CoursePrefix}`);
                                if(eqNew){
                                    eqNew = { ...eqNew, TERM: parseInt(s.id.substr(1)) }
                                    newFullCourses = newFullCourses.filter(n => n.id !== eqNew.id);
                                    newFullCourses.push(eqNew);
                                } else {
                                    newFullCourses.push(cSem);
                                }
                            }
                        })
                    }
                });

                if(testExempt){
                    //newFullCourses = newFullCourses.filter(n => n.CoursePrefix !== 'SLS' && n.CourseNumber !== '1106')
                    newFullCourses = newFullCourses.filter(n => n.SheetName !== 'DEV');
                }

                const npMdcCourses = await newFullCourses.filter(nfc => {return nfc.SheetName === 'ACSG' || nfc.SheetName === 'DEV'});
                const npFiuCourses = await newFullCourses.filter(nfc => {return nfc.SheetName === 'BCSG' || nfc.SheetName === 'TRANSFER'});
                
                var newPathway = {
                    id: newPathwayId,
                    basePathway: resetPathway,
                    index: 0,
                    name: resetPathwayName,
                    semesters: newSemesters,
                    mdcCourses: npMdcCourses,
                    fiuCourses: npFiuCourses,
                    mdcGrad: null
                }

                setUserPathways([ newPathway ]);
                setCurrentPathway(newPathwayId);
                setPathwayName(newPathway.name);
                setBasePathway(newPathway.basePathway);
                setMDCGrad(newPathway.mdcGrad);
                //setFiuPathway(newPathway.basePathway);

                setOpenAddProgram(false);
                setSemesters(newPathway.semesters);
                setCourses(npMdcCourses);
                setFiuCourses(npFiuCourses);
                setDualEnrollCourses([]);
                setAdditionalCost(0);
                setAdditionalSavings(0);
                
                setValue(newPathway.index);

            let pathwayReset = await axios({
                method: 'post',
                data: {
                    userId: currentUser.userId,
                    newPathway: newPathway
                },
                withCredentials: true,
                url: '/resetpath'
            });

            let resetResp = pathwayReset.data;

            if(resetResp === 'Reset Complete'){
                openMuiSnackbar({ message: 'Reset Complete!', variant: 'success' })
            } else {
                openMuiSnackbar({ message: 'Reset Failed', variant: 'error' });
            }            
            
         }
        setShowReset(false);
     }

     const handleShowLegend = async () => {
        setShowLegend(true);
        setDebugApp(true);
     }

     const handleCloseLegend = () => {
        setShowLegend(false);
     }

    const handleAddProgramClose = () => {
        setOpenAddProgram(false);
    }

    const handleAddProgramOpen = () => {
        setOpenAddProgram(true);
    }

    const handleAddSavingsOpen = () => {
        setOpenAddSavings(true);
    }

    const handleAddSavingsClose = () => {
        setOpenAddSavings(false);
    }

    const handleMuiLoginClose = () => {
        checkUser();
        setLoginOpen(false);
    }

    const handleMuiLoginOpen = () => {
        setStartDialog(false);
        setLoginOpen(true);
    }

    const changeFloridaRes = (res) => {
        setFloridaRes(res);
    }

    const changeForeignLangExempt = (exempt) => {
        setForeignLangExempt(exempt);
    }

    const changeDualEnroll = (enroll) => {
        setDualEnroll(enroll);
        let dualE = {
            id: 0,
            value: 'DE',
            label: 'Dual Enrollment (DE)'
        }
        if(enroll){
            handleAddSavings(dualE);
        } else {
            if(dualEnrollCourses.length > 0){
                openMuiSnackbar({ message: 'All courses need to be removed from Dual Enrollment before removing', variant: 'warning' });
            } else {
                let newArr = [];
                if(activeCostSavings.length > 1){
                    setExemptTab(0);
                    newArr = activeCostSavings.filter((a) => a.id !== dualE.id);
                    setActiveCostSavings(newArr);
                } else {
                    setActiveCostSavings(newArr);
                }
            }
        }        
    }

    const changeDualTerms = (term) => {
        setDualTerms(term);
    }

    const getUserSemesterData = async () => {
        const semesterData = await axios({
            method: 'get',
            withCredentials: true,
            url: `/getsemesters/${currentUser.userPathways[0]}`
        });

        return semesterData;
    }

    const handleProgram = (term, year) => {

        var start = new Date(year, term);
        var ststart = new Date(year, term);
        var narray = [];
        setStartDate(ststart);
        setCompDate(ststart);
        var end = new Date();

        var activePathway = userPathways.find(p => p.id === currentPathway);

        let maxTerms = checkPathwayMaxTerm(fiucourses);

        if(maxTerms>11 && maxTerms<=13){
            activePathway.semesters = longSemesterList;
        }

        activePathway.semesters.map((s) => {
            var ndate = new Date(start);
            /* ndate.setDate(start); */
            var termname = '';

            switch(start.getMonth()){
                case 0:
                    termname = 'Spring';
                    break;
                case 4:
                    termname = 'Summer';
                    break;
                case 8:
                    termname = 'Fall'
                    break;
            }

            narray.push(
                {
                    id: s.id,
                    index: s.index,
                    name: `${termname} ${start.getFullYear()}`,
                    hours: s.hours,
                    cost: s.cost,
                    removeable: s.removeable,
                    userPathwayId: s.userPathwayId,
                    completed: s.completed,
                    termdate: ndate
                }
            )
            end.setFullYear(start.getFullYear(), start.getMonth(), start.getDate());
            start.setMonth(start.getMonth() + 4);
        });
       
        setEndDate(start);

        updateUserPathwayWCur(courses, fiucourses, narray);
        setSemesters(narray);
    }

    const handleTermName = (termdate) => {

        var termname = '';

        termdate = new Date(termdate);

        switch(termdate.getMonth()){
            case 0:
                termname = 'Spring';
                break;
            case 4:
                termname = 'Summer';
                break;
            case 8:
                termname = 'Fall'
                break;
        }

        return `${termname} ${termdate.getFullYear()}`;

    }

    const videoClick = (course) => {
        setSelectedCourse(course);
        setOpenVideo(true);
    }

    const videoFiuClick = (course) => {
        setSelectedCourse(course);
        setOpenFiuVideo(true);
    }

    const courseClick = (course, completed, exempted) => {
        setSelectedCourse(course);
        setSelectedCourseCompleted(completed);
        setOpenCourse(true);
        setSelectedCourseExempt(exempted);
    }

    const courseFiuClick = (course, completed, exempted) => {
        setSelectedCourse(course);
        setSelectedCourseCompleted(completed);
        setOpenFiuCourse(true);
        setSelectedCourseExempt(exempted);
    }

    const handleClose = () => {
        setOpenVideo(false);
        setOpenCourse(false);
        setSelectedCourse({});
        setSelectedCourseExempt(false);
    }

    const handleFiuClose = () => {
        setOpenFiuVideo(false);
        setOpenFiuCourse(false);
        setSelectedCourse({});
    }

    const handleManOpen = () => {
        setOpenManualAdd(true);
    }

    const handleManClose = () => {
        setOpenManualAdd(false);
    }

    const getMaxId = () => {
        var newId = 0;
        var mdcMaxId = courses ? courses.reduce((max, c) => { /* console.log(c);  */return c.id > max ? c.id : max, courses[0].id}) : 0;
        var fiuMaxId = courses ? fiucourses.reduce((max, c) => c.id > max ? c.id : max, fiucourses[0].id) : 0;

        if(mdcMaxId>fiuMaxId){
            newId = mdcMaxId;
        } else {
            newId = fiuMaxId;
        }

        newId = parseInt(new Date().valueOf()*Math.random());

        return newId;
    }

    const getSemMax = () => {
        var semMax = semesters.reduce((max, s) => parseInt(s.id.substr(1)) > max ? parseInt(s.id.substr(1)) : max, parseInt(semesters[0].id.substr(1)));
        return semMax;
    }

    const handleManAdd = (nclass) => {
        const newid = getMaxId()+1;
        const nSheetName = nclass.institution === 'Florida International University' ? 'BCSG' : 'ACSG';
        const nResCost = nclass.institution === 'Florida International University' ? (205.57*parseInt(nclass.Units)).toFixed(2) : (118.22*parseInt(nclass.Units)).toFixed(2);
        const nNonResCost = nclass.institution === 'Florida International Univeristy' ? (618.87*parseInt(nclass.Units)).toFixed(2) : (402.51*parseInt(nclass.Units)).toFixed(2);
        const newclass = { 
            id: newid,
            CoursePrefix: nclass.CoursePrefix,
            CourseNumber: '',
            PathwayName: pathwayName,
            SheetName: nSheetName,
            PERT: null,
            ACCP: null,
            ACT: null,
            SAT: null,
            CostSaving: null,
            CourseSub: null,
            ABEHAV: null,
            BBEHAV: null,
            REQGRAD: null,
            EXEMPT: false,
            PREREQ: null,
            COREQ: null,
            POSTREQ: null,
            RESCOST: nResCost,
            NONRESCOST: nNonResCost,
            Component: 'LEC',
            TERM: nclass.TERM, 
            Transfer: false,
            Color: 7,
            Title: nclass.institution,
            CourseID: null,
            Description: null,
            Units: parseInt(nclass.Units),
            Prerequisite: null,
            Corequisite: null,
            URL: null,
            Exemption: null,
            logo: false,
            videoUrl: null,
            BasePathway: basePathway,
            CLEP: false,
            TESTP: null,
            DE: false,
            AICE: false,
            AP: false,
            CART: false,
            CSP: false,
            DLPT: false,
            DSST: false,
            IB: false,
            Uexcel: false,
            del: true,
            ACTFL: false,
            CAPE: false
         }
         if(nclass.institution === 'Florida International University'){
            setFiuCourses([ ...fiucourses, newclass ]);
         } else {
            setCourses([ ...courses, newclass ]);
         }        
    }

    const handleManRemove = (nclass) => {
        var newarray = []; 


        if(nclass.SheetName === 'BCSG'){
            fiucourses.map((course) => {
                if(course.id !== nclass.id){
                    newarray.push(course);
                }
            });
        } else {
            courses.map((course) => {
                if(course.id !== nclass.id){
                    newarray.push(course);
                } else {
                    if(course.logo){
                        if(floridares){
                            let nSavings = additionalSavings + course.RESCOST;
                            let nCosts = additionalCost + course.RESCOST;
                            setAdditionalCost(nCosts);
                            setAdditionalSavings(nSavings);
                        } else {
                            let nSavings = additionalSavings + course.NONRESCOST;
                            let nCosts = additionalCost + course.NONRESCOST;
                            setAdditionalCost(nCosts);
                            setAdditionalSavings(nSavings);
                        }
                        setExemptCourses([ ...exemptCourses, course ])
                    }
                }
            });
        }
        if(currentUser !== null && currentUser !== ''){
            axios({
                method: 'post',
                data: {
                    course: nclass,
                    currentPathway: currentPathway
                },
                withCredentials: true,
                url: '/deletecourse'
            }).then((res) => {
                if(res.data === 'Course Removed'){
                    openMuiSnackbar({ message: 'Course Removed Successfully!', variant: 'success' });
                } else {
                    openMuiSnackbar({ message: 'Error removing Course!', variant: 'warning' });
                }
            })
        }

        if(nclass.SheetName === 'BCSG'){
            setFiuCourses(newarray);
        } else {
            setCourses(newarray);
        }
        
    }

    const getMaxIndex = () => {
        return semesters.reduce((max, s) => s.index > max ? s.index : max, semesters[0].index);
    }

    const handleManTermAdd = () => {
        const newindex =  getMaxIndex() + 1;

        var nedate = new Date(enddate);

        var edate = nedate.setMonth(nedate.getMonth() + 4);

        var termname = '';

        switch(new Date(edate).getMonth()){
            case 0:
                termname = 'Spring';
                break;
            case 4:
                termname = 'Summer';
                break;
            case 8:
                termname = 'Fall'
                break;
        }

        const newsemester = {
                id: 's' + (newindex + 1),
                index: newindex,
                name: `${termname} ${new Date(edate).getFullYear()}`,
                completed: false,
                hours: 0,
                cost: 0,
                removeable: true,
                termdate: new Date(enddate)
        };

        setEndDate(new Date(edate));

        const nsemesters = [ ...semesters, newsemester ];

        updateUserPathwayWCur(courses, fiucourses, nsemesters);        
        setSemesters([ ...semesters, newsemester ]);
    }

    const handleManTermRemove = (s) => {
        var newarray = [];
        if(s.hours === 0){
            var maxDate = startdate;
        semesters.map((sem) => {
            if(sem.id !== s.id){
                if(sem.termdate>maxDate){
                    maxDate = sem.termdate;
                }
                newarray.push(sem);
            }
        });

        if(currentUser !== null && currentUser !== ''){
            axios({
                method: 'post',
                data: {
                    term: s,
                    currentPathway: fiupathway
                },
                withCredentials: true,
                url: '/deleteterm'
            }).then((res) => {
                if(res.data === 'Term Removed'){
                    openMuiSnackbar({ message: 'Term Removed!', variant: 'success' });
                } else {
                    openMuiSnackbar({ message: 'Cannot remove Term!', variant: 'warning' });
                }
            })
        }

        setEndDate(new Date(maxDate));
        updateUserPathwayWCur(courses, fiucourses, newarray);
        setSemesters(newarray);
    } else {
        openMuiSnackbar({ message: 'All Courses must be removed off Term prior to removal!', variant: 'warning' });
    }
    }

    const changeCourses = (id, semester) => {
            setAllowWarn(true);
            let TERM = parseInt(semester.substr(1));
            let course = courses.find(course => course.id === id);
            if(!course){
                course = dualEnrollCourses.find(d => d.id === id);
                if(course){
                    let dualECourses = dualEnrollCourses.filter(d => d.id !== course.id);
                    if(course.COREQ){
                        dualECourses = dualECourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                    }
                    setDualEnrollCourses(dualECourses);
                } else {
                    course = aiceCourses.find(d => d.id === id);
                    if(course){
                        let tempAiceCourses = aiceCourses.filter(d => d.id !== course.id);
                        if(course.COREQ){
                            tempAiceCourses = tempAiceCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                        }
                        setAiceCourses(tempAiceCourses)
                    } else {
                        course = apCourses.find(d => d.id === id);
                        if(course){
                            let tempApCourses = apCourses.filter(d => d.id !== course.id);
                            if(course.COREQ){
                                tempApCourses = tempApCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                            }
                            setApCourses(tempApCourses);
                        } else {
                            course = cartCourses.find(d => d.id === id);
                            if(course){
                                let tempCartCourses = cartCourses.filter(d => d.id !== course.id);
                                if(course.COREQ){
                                    tempCartCourses = tempCartCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                                }
                                setCartCourses(tempCartCourses)
                            } else {
                                course = cspCourses.find(d => d.id === id);
                                if(course){
                                    let tempCspCourses = cspCourses.filter(d => d.id !== course.id);
                                    if(course.COREQ){
                                        tempCspCourses = tempCspCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                                    }
                                    setCspCourses(tempCspCourses)
                                } else {
                                    course = dlptCourses.find(d => d.id === id);
                                    if(course){
                                        let tempDlptCourses = dlptCourses.filter(d => d.id !== course.id);
                                        if(course.COREQ){
                                            tempDlptCourses = tempDlptCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                                        }
                                        setDlptCourses(tempDlptCourses)
                                    } else {
                                        course = dsstCourses.find(d => d.id === id);
                                        if(course){
                                            let tempDsstCourses = dsstCourses.filter(d => d.id !== course.id);
                                            if(course.COREQ){
                                                tempDsstCourses = tempDsstCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                                            }
                                            setDsstCourses(tempDsstCourses)
                                        } else {
                                            course = ibCourses.find(d => d.id === id);
                                            if(course){
                                                let tempIbCourses = ibCourses.filter(d => d.id !== course.id);
                                                if(course.COREQ){
                                                    tempIbCourses = tempIbCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                                                }
                                                setIbCourses(tempIbCourses)
                                            } else {
                                                course = uexcelCourses.find(d => d.id === id);
                                                if(course){
                                                    let tempUexcelCourses = uexcelCourses.filter(d => d.id !== course.id);
                                                    if(course.COREQ){
                                                        tempUexcelCourses = tempUexcelCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ)
                                                    }
                                                    setUexcelCourses(tempUexcelCourses);
                                                } else {
                                                    course = exemptCourses.find(d => d.id === id);
                                                    if(course){
                                                        let tempExemptCourses = exemptCourses.filter(d => d.id !== course.id);
                                                        if(course.COREQ){
                                                            tempExemptCourses = tempExemptCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ);
                                                        }
                                                        setExemptCourses(tempExemptCourses);
                                                    } else {
                                                        course = clepCourses.find(d => d.id === id);
                                                        if(course){
                                                            let tempClepCourses = clepCourses.filter(d => d.id !== course.id);
                                                            if(course.COREQ){
                                                                tempClepCourses = tempClepCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ);
                                                            }
                                                            setClepCourses(tempClepCourses);
                                                        } else {
                                                            course = actflCourses.find(d => d.id === id);
                                                            if(course){
                                                                let tempActflCourses = actflCourses.filter(d => d.id !== course.id);
                                                                if(course.COREQ){
                                                                    tempActflCourses = tempActflCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ);
                                                                }
                                                                setActflCourses(tempActflCourses);
                                                            } else {
                                                                course = capeCourses.find(d => d.id === id);
                                                                if(course){
                                                                    let tempCapeCourses = capeCourses.filter(d => d.id !== course.id);
                                                                    if(course.COREQ){
                                                                        tempCapeCourses = tempCapeCourses.filter(c => `${c.CoursePrefix}${c.CourseNumber}` !== course.COREQ);
                                                                    }
                                                                    setCapeCourses(tempCapeCourses);
                                                                } else {
                                                                    console.log('Not Found');
                                                                }
                                                            }
                                                        }                                                        
                                                    }
                                                }                                                
                                            }
                                        }
                                        
                                    }                                    
                                }
                                
                            }
                        }   
                    }
                }
            }
            let newarr = [];
            let narr = [];
            course = { ...course, TERM };
            newarr = courses.filter(item => item.id !== id);
            newarr.push(course);
            
            if(course.COREQ){
                let coreq = courses.find(c => `${c.CoursePrefix}${c.CourseNumber}` === course.COREQ);
                if(!coreq){
                    let activeArray = [];
                    for(let i=0; i < activeCostSavings.length; i++){
                        switch(activeCostSavings[i].value){
                            case 'DE':
                                activeArray = dualEnrollCourses;
                                break;
                            case 'AICE':
                                activeArray = aiceCourses;
                                break;
                            case 'AP':
                                activeArray = apCourses;
                                break;
                            case 'CART':
                                activeArray = cartCourses;
                                break;
                            case 'CSP':
                                activeArray = cspCourses;
                                break;
                            case 'DLPT':
                                activeArray = dlptCourses;
                                break;
                            case 'DSST':
                                activeArray = dsstCourses;
                                break;
                            case 'IB':
                                activeArray = ibCourses;
                                break;
                            case 'Uexcel':
                                activeArray = uexcelCourses;
                                break;
                            case 'CLEP':
                                activeArray = clepCourses;
                                break;
                            case 'Exempt':
                                activeArray = exemptCourses;
                                break;
                            case 'ACTFL':
                                activeArray = actflCourses;
                                break;
                            case 'CAPE':
                                activeArray = capeCourses;
                                break;
                        }

                        coreq = activeArray.find(c => `${c.CoursePrefix}${c.CourseNumber}` === course.COREQ)
                        if(coreq){
                            break;
                        }
                    }

                }
                coreq = { ...coreq, TERM };
                narr = newarr.filter(ai => ai.id !== coreq.id);
                narr.push(coreq);
            } else {
                narr = newarr;
            }

            var allCourses = narr;
            var fiuTransCourses = fiucourses;

            Array.prototype.push.apply(allCourses, fiuTransCourses);

            if(course.PREREQ){
                allCourses = preReqLoop(allCourses, course.id);
            }

            if(course.POSTREQ){
                allCourses = postReqLoop(allCourses, course.id);
            }

            var nMdcCourses = allCourses.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');
            if(testExempt){
                nMdcCourses = allCourses.filter(c => c.SheetName === 'ACSG');
            }
            var nFiuCourses = allCourses.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER');

            setCourses(nMdcCourses);
            setFiuCourses(nFiuCourses);
        }

    const changeFiuCourses = (id, semester) => {
        let TERM = parseInt(semester.substr(1));
        let course = fiucourses.find(course => course.id === id);
        let newarr = [];
        let narr = [];
        course = { ...course, TERM };
        newarr = fiucourses.filter(item => item.id !== course.id);
        newarr.push(course);


        if(course.COREQ){
            let coreq = fiucourses.find(c => `${c.CoursePrefix}${c.CourseNumber}` === course.COREQ.trim());
            coreq = { ...coreq, TERM };
            narr = newarr.filter(ai => ai.id !== coreq.id);
            narr.push(coreq);
        } else {
            narr = newarr;
        }

        var allCourses = narr;
        var mdcTransCourses = courses;

        Array.prototype.push.apply(allCourses, mdcTransCourses);

        if(course.PREREQ){
            allCourses = preReqLoop(allCourses, course.id);
        }

        if(course.POSTREQ){
            allCourses = postReqLoop(allCourses, course.id);
        }
        
        var nMdcCourses = allCourses.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');
        if(testExempt){
            nMdcCourses = allCourses.filter(c => c.SheetName === 'ACSG');
        }
        var nFiuCourses = allCourses.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER')

        setCourses(nMdcCourses);
        setFiuCourses(nFiuCourses);
    }

    const changeExemptCourses = (id, semester, exempt) => {
        let course = courses.find(c => c.id === id);

        let exemptArray = [];


        switch(exempt){
            case 'DE':
                exemptArray = dualEnrollCourses;
                break;
            case 'AICE':
                exemptArray = aiceCourses;
                break;
            case 'AP':
                exemptArray = apCourses;
                break;
            case 'CART':
                exemptArray = cartCourses;
                break;
            case 'CSP':
                exemptArray = cspCourses;
                break;
            case 'DLPT':
                exemptArray = dlptCourses;
                break;
            case 'DSST':
                exemptArray = dsstCourses;
                break;
            case 'IB':
                exemptArray = ibCourses;
                break;
            case 'Uexcel':
                exemptArray = uexcelCourses;
                break;
            case 'CLEP':
                exemptArray = clepCourses;
                break;
            case 'Exempt':
                exemptArray = exemptCourses;
                break;
            case 'ACTFL':
                exemptArray = actflCourses;
                break;
            case 'CAPE':
                exemptArray = capeCourses;
                break;
        }

        if(!course){
            course = exemptArray.find(e => e.id === id);
        }
    
        let TERM = parseInt(semester.substr(1));
        let newCourseArray = [];

        newCourseArray = courses.filter(c => parseInt(c.id) !== parseInt(id));

        let newArr = exemptArray.filter(d => parseInt(d.id) !== parseInt(id));

        course = { ...course, TERM: TERM }

        if(course.COREQ){
            let coReqFullId = course.COREQ.trim();
            let coReqPrefix = coReqFullId.substr(0,3);
            let coReqNumber = coReqFullId.substr(3);
            let coReq = newCourseArray.find(course => course.CoursePrefix === coReqPrefix && course.CourseNumber === coReqNumber);
            if(!coReq){
                coReq = newArr.find(na => na.CoursePrefix === coReqPrefix && na.CourseNumber === coReqNumber);
                newArr = newArr.filter(d => parseInt(d.id) !== parseInt(coReq.id));
            } else {
                newCourseArray = newCourseArray.filter(c => parseInt(c.id) !== parseInt(coReq.id));
                newArr = newArr.filter(d => parseInt(d.id) !== parseInt(coReq.id));
            }

            coReq = { ...coReq, TERM: TERM }

            newArr.push(coReq);
            
        }

        setCourses(newCourseArray);
        newArr.push(course);

        switch(exempt){
            case 'DE':
                setDualEnrollCourses(newArr);
                break;
            case 'AICE':
                setAiceCourses(newArr);
                break;
            case 'AP':
                setApCourses(newArr);
                break;
            case 'CART':
                setCartCourses(newArr);
                break;
            case 'CSP':
                setCspCourses(newArr);
                break;
            case 'DLPT':
                setDlptCourses(newArr);
                break;
            case 'DSST':
                setDsstCourses(newArr);
                break;
            case 'IB':
                setIbCourses(newArr);
                break;
            case 'Uexcel':
                setUexcelCourses(newArr);
                break;
            case 'CLEP':
                setClepCourses(newArr);
                break;
            case 'Exempt':
                setExemptCourses(newArr);
                break;
            case 'ACTFL':
                setActflCourses(newArr);
                break;
            case 'CAPE':
                setCapeCourses(newArr);
                break;
        }
        
        
    }


    const handleSemesterComplete = semester => event => {
        const newsemarray = [];
        semesters.map((s) => {
            if(s.id !== semester.id){
                newsemarray.push(s);
            }
        });
        newsemarray.push({ id: semester.id, name: semester.name, completed:event.target.checked, hours: semester.hours, cost: semester.cost, index: semester.index, removeable: semester.removeable, termdate: semester.termdate });
        updateUserPathwayWCur(courses, fiucourses, newsemarray);
        setSemesters(newsemarray);
    }

    const handleDashToggle = () => {
        setShowDash(!showdash);
    }

    React.useEffect(() => {
        if(currentUser !== null && currentUser !== ''){

            try {
                setStartDialog(false);
                var userPath;
                var userPaths;
                var userSemesters = [];
                var pathway;


                const requests = async () => {

                    const pathwayObj = await axios({
                        method: 'get',
                        withCredentials: true,
                        url: '/getuserpathways'
                    });
        
                    pathwayObj.data[0].semesters.map((s) => {
                        userSemesters.push({ ...s, termdate: new Date(s.termdate)});
                    });

                    userPaths = pathwayObj.data;
                };

                requests().then(() => {

                    setUserPathways(userPaths);

                    const nfiuCourses = userPaths[0].fiuCourses;
                    const nmdcCourses = userPaths[0].mdcCourses;
                    const ncsCourses = userPaths[0].csCourses;
                    const ndeCourses = userPaths[0].deCourses;

                    const nBasePathway = userPaths[0].basePathway;
                    const nPathwayName = userPaths[0].name;

                    //console.log(ncsCourses);

                    setCurrentPathway(userPaths[0].id);
                    setValue(userPaths[0].index);
                    setBasePathway(nBasePathway);
                    setPathwayName(nPathwayName);

                    setSemesters(userSemesters);
                    setCourses(nmdcCourses);
                    setFiuCourses(nfiuCourses);

                    let tempDEArray = [];
                    let tempAiceArray = [];
                    let tempApArray = [];
                    let tempCartArray = [];
                    let tempCspArray = [];
                    let tempDlptArray = [];
                    let tempDsstArray = [];
                    let tempIbArray = [];
                    let tempUexcelArray = [];
                    let tempExemptArray = [];
                    let tempClepArray = [];
                    let tempActflArray = [];
                    let tempCapeArray = [];

                    ncsCourses.map((cs) => {
                        switch(cs.csArray){
                            case 'DE':
                                delete cs.csArray;
                                tempDEArray.push(cs);
                                break;
                            case 'AICE':
                                delete cs.csArray;
                                tempAiceArray.push(cs);
                                break;
                            case 'AP':
                                delete cs.csArray;
                                tempApArray.push(cs);
                                break;
                            case 'CART':
                                delete cs.csArray;
                                tempCartArray.push(cs);
                                break;
                            case 'CSP':
                                delete cs.csArray;
                                tempCspArray.push(cs);
                                break;
                            case 'DLPT':
                                delete cs.csArray;
                                tempDlptArray.push(cs);
                                break;
                            case 'DSST':
                                delete cs.csArray;
                                tempDsstArray.push(cs);
                                break;
                            case 'IB':
                                delete cs.csArray;
                                tempIbArray.push(cs);
                                break;
                            case 'UEXCEL':
                                delete cs.csArray;
                                tempUexcelArray.push(cs);
                                break;
                            case 'Exempt':
                                delete cs.csArray;
                                tempExemptArray.push(cs);
                                break;
                            case 'CLEP':
                                delete cs.csArray;
                                tempClepArray.push(cs);
                                break;
                            case 'ACTFL':
                                delete cs.csArray;
                                tempActflArray.push(cs);
                                break;
                            case 'CAPE':
                                delete cs.csArray;
                                tempCapeArray.push(cs);
                                break;
                        }
                    });

                    let aCostSavingsArray = [];

                    if(tempDEArray.length>0){
                        aCostSavingsArray.push({id: 0, label: 'Dual Enrollment (DE)', value: 'DE' })
                    }
                    if(tempAiceArray.length>0){
                        aCostSavingsArray.push({
                            id: 1,
                            value: 'AICE',
                            label: 'Association of International Credential Evaluators (AICE)'
                        })
                    }
                    if(tempApArray.length>0){
                        aCostSavingsArray.push({
                            id: 2,
                            value: 'AP',
                            label: 'Advanced Placement (AP)'
                        })   
                    }
                    if(tempCartArray.length>0){
                        aCostSavingsArray.push({
                            id: 3,
                            value: 'CART',
                            label: 'Center for Advanced Research and Technology (CART)'
                        })
                    }
                    if(tempCspArray.length>0){
                        aCostSavingsArray.push({
                            id: 5,
                            value: 'CSP',
                            label: 'Computer Skills Placement (CSP)'
                        })
                    }
                    if(tempDlptArray.length>0){
                        aCostSavingsArray.push({
                            id: 6,
                            value: 'DLPT',
                            label: 'Defense Language Proficiency Test (DLPT)'
                        })
                    }
                    if(tempDsstArray.length>0){
                        aCostSavingsArray.push({
                            id: 7,
                            value: 'DSST',
                            label: 'DSST (DANTES)'
                        })
                    }
                    if(tempIbArray.length>0){
                        aCostSavingsArray.push({
                            id: 8,
                            value: 'IB',
                            label: 'International Baccalaureate (IB)'
                        })
                    }
                    if(tempUexcelArray.length>0){
                        aCostSavingsArray.push({
                            id: 9,
                            value: 'Uexcel',
                            label: 'UExcel Examinations'
                        })
                    }
                    if(tempExemptArray.length>0){
                        aCostSavingsArray.push({
                            id: 10,
                            value: 'Exempt',
                            label: 'Exemption (Exempt)'
                        })
                    }
                    if(tempClepArray.length>0){
                        aCostSavingsArray.push({
                            id: 4,
                            value: 'CLEP',
                            label: 'College-Level Examination Program (CLEP)'
                        })
                    }
                    if(tempActflArray.length>0){
                        aCostSavingsArray.push({
                            id: 11,
                            value: 'ACTFL',
                            label: 'American Council on the Teaching of Foreign Languages (ACTFL)'
                        })
                    }
                    if(tempCapeArray.length>0){
                        aCostSavingsArray.push({
                            id: 12,
                            value: 'CAPE',
                            label: 'Caribbean Advanced Proficiency Examination Program (CAPE)'
                        })
                    }




                    setDualEnrollCourses(tempDEArray);
                    setAiceCourses(tempAiceArray);
                    setApCourses(tempApArray);
                    setCartCourses(tempCartArray);
                    setCspCourses(tempCspArray);
                    setDlptCourses(tempDlptArray);
                    setDsstCourses(tempDsstArray);
                    setIbCourses(tempIbArray);
                    setUexcelCourses(tempUexcelArray);
                    setExemptCourses(tempExemptArray);
                    setClepCourses(tempClepArray);
                    setActflCourses(tempActflArray);
                    setCapeCourses(tempCapeArray);
                    setActiveCostSavings(aCostSavingsArray);
                    if(aCostSavingsArray.length-1>0){
                        setExemptTab(parseInt(aCostSavingsArray.length - 1));
                    }

                    setMDCGrad(userPaths[0].mdcGrad);
                    //setTest(currentUser.profile.testTaken);
                    setTestArray(currentUser.profile.testsTaken);
                    setTestExempt(currentUser.profile.testExempt);
                    //setWritingScore(currentUser.profile.writingScore);
                    //setMathScore(currentUser.profile.mathScore);
                    //setReadingScore(currentUser.profile.readingScore);
                    //setAafScore(currentUser.profile.aafScore);
                    //setDualEnroll(currentUser.profile.dualEnrollment);
                    //setDualTerms(currentUser.profile.dualTerms);
                    setFloridaRes(currentUser.profile.floridaResidency);
                    setForeignLangExempt(currentUser.profile.foreignLangExempt);
                    setAdditionalCost(currentUser.profile.addCost);
                    setAdditionalSavings(currentUser.profile.addSave);
                    setRemovedCourses(currentUser.profile.removedCourses);

                    /* setSemesters(userSemesters);
                    setCourses(nmdcCourses);
                    setFiuCourses(nfiuCourses); */

                    //updateUserPathwayWCur(nmdcCourses, nfiuCourses, userSemesters);
                    
                })
            } catch (e) {
                console.log(e);
            }
        } 
    }, [currentUser]);

    React.useEffect(() => {


    },[userPathways])


    React.useEffect(() => {
        const newArray = [];

        semesters.map((s) => {
            var shours = 0;
            var scost = 0;
            courses.filter(item => `s${item.TERM}` && `s${item.TERM}` === s.id).map((c) => {
                let exemptCourse = dualEnrollCourses.find(d => d.id === c.id);
                if(!exemptCourse){
                    var ccost = floridares ? c.RESCOST : c.NONRESCOST;
                    shours = shours + parseInt(c.Units);
                    scost = scost + parseFloat(ccost);
                }                
            });

            fiucourses.filter(item => `s${item.TERM}` && `s${item.TERM}` === s.id).map((c) => {
                let exemptCourse = dualEnrollCourses.find(d => d.id === c.id);
                if(!exemptCourse){
                    var ccost = floridares ? c.RESCOST : c.NONRESCOST;
                    shours = shours + parseInt(c.Units);
                    scost = scost + parseFloat(ccost);
                }                
            });

            
            newArray.push({ id: s.id, name: s.name, completed: s.completed, hours: shours, cost: scost, index: s.index, removeable: s.removeable, termdate: s.termdate });
        });

        var deHours = 0;
        var deCost = 0;
        dualEnrollCourses.forEach((c) => {
            var dcost = floridares ? c.RESCOST : c.NONRESCOST;
            deHours = deHours + parseInt(c.Units);
            deCost = deCost + parseFloat(dcost);
        });

        //setExemptHours(deHours);

        updateUserPathwayWCur(courses, fiucourses, newArray);
        setSemesters(newArray);

        const mdcMaxTerm = checkMDCMaxSemester();
        if(!mdcGrad){
            setMDCMaxSemester(mdcMaxTerm);
        }


        
        handleFiuRemoveElective();
        handleFiuAddElective();
        handleMdcRemoveElective();
        handleMdcAddElective();

        setAllowWarn(true);
        
    }, [courses, fiucourses]);

    React.useEffect(() => {
        //console.log(userPathways);
    },[userPathways]);

    React.useEffect(() => {
        //console.log(currentPathway);
    }, [currentPathway])

    React.useEffect(() => {

        var nFiuCourses = fiucourses.filter((f) => f.SheetName === 'BCSG');
        var nonFiuCourses = fiucourses.filter((f) => f.SheetName !== 'BCSG');
        var gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;

        nFiuCourses.map((fc) => {
            if(fc.TERM <= gradLine){
                fc = { ...fc, TERM: parseInt(gradLine) + 1 }
                nonFiuCourses.push(fc);
            } else {
                nonFiuCourses.push(fc);
            }
        });

        nonFiuCourses.map((nfc) => {
           nonFiuCourses = postReqLoop(nonFiuCourses, nfc.id);
        })

        setFiuCourses(nonFiuCourses);
        

    }, [mdcMaxSemester, mdcGrad])

    React.useEffect(() => {
        var ttlhours = 0;
        var comphours = 0;
        var compcost = 0;
        var compindex = 0;
        var gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;
        var tcDate;

        semesters.map((s) => {
            if(s.completed){
                comphours = comphours + s.hours;
                compcost = compcost + s.cost;
                compindex = s.index;
                tcDate = s.termdate;
            }
            if(gradLine === s.index){
                setMDCGradDate(s.termdate);
            }
            ttlhours = ttlhours + s.hours;
        });

        if(tcDate){
            setCompDate(tcDate);
        }

        let dehours = 0;
        dualEnrollCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        aiceCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        apCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        dlptCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        dsstCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        cartCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        cspCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        ibCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        uexcelCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        clepCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        exemptCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        actflCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        capeCourses.forEach((d) => {
            dehours = dehours + d.Units;
        });

        comphours = comphours + dehours;
        ttlhours = ttlhours + dehours;

        var compper = Math.round((comphours/ttlhours)*100);
       
        setAccumHours(comphours);
        setAccumCost(compcost);
        if(compper > 50 && compindex < gradLine){
            setCompleted(50);
        } else {
            setCompleted(Math.round((comphours/ttlhours)*100));
        }
        
    }, [semesters]);

    React.useEffect(() => {

        setAllowWarn(false);
        var newPathway = {}
        checkUser();

        const getPathways = async () => {
            const partnerPathQuery = await axios({
                method: 'post',
                data: {},
                withCredentials: true,
                url: 'getpartnerpathways'
            });
        
            let recPartnerPaths = partnerPathQuery.data;
        
            let formattedPaths = []
        
            recPartnerPaths.map((p) => {
                let pathObj = {
                    value: p.instValue,
                    label: p.instLabel
                }
                formattedPaths.push(pathObj);
            })
        
            return formattedPaths;
        }

        getPathways().then((r) => {
            setPartnerPathways(r);
        });

        

        if(currentUser !== null && currentUser !== ''){

            setStartDialog(false);
            var userPath;
            var userPaths;
            var userSemesters = [];
            var pathway;


            const requests = async () => {

                const pathwayObj = await axios({
                    method: 'get',
                    withCredentials: true,
                    url: '/getuserpathways'
                });
    
                //console.log(pathwayObj.data);

                pathwayObj.data[0].semesters.map((s) => {
                    userSemesters.push({ ...s, termdate: new Date(s.termdate)});
                });

                userPaths = pathwayObj.data;
            };

            requests().then(() => {

                setUserPathways(userPaths);
                setCurrentPathway(userPaths[0].id);

                const nfiuCourses = userPaths[0].fiuCourses;
                const nmdcCourses = userPaths[0].mdcCourses;
                const ncsCourses = userPaths[0].csCourses;
                const ndeCourses = userPaths[0].deCourses;

                const nBasePathway = userPaths[0].basePathway;
                const nPathwayName = userPaths[0].name;

                setSemesters(userSemesters);
                
                setValue(userPaths[0].index);
                setBasePathway(nBasePathway);
                setPathwayName(nPathwayName);
                setMDCGrad(userPaths[0].mdcGrad);
                setTestArray(currentUser.profile.testsTaken);
                //setTest(currentUser.profile.testTaken);
                setTestExempt(currentUser.profile.testExempt);
                //setWritingScore(currentUser.profile.writingScore);
                //setMathScore(currentUser.profile.mathScore);
                //setReadingScore(currentUser.profile.readingScore);
                //setAafScore(currentUser.profile.aafScore);
                //setDualEnroll(currentUser.profile.dualEnrollment);
                //setDualTerms(currentUser.profile.dualTerms);

                let tempDEArray = [];
                let tempAiceArray = [];
                let tempApArray = [];
                let tempCartArray = [];
                let tempCspArray = [];
                let tempDlptArray = [];
                let tempDsstArray = [];
                let tempIbArray = [];
                let tempUexcelArray = [];
                let tempExemptArray = [];
                let tempClepArray = [];
                let tempActflArray = [];
                let tempCapeArray = [];

                ncsCourses.map((cs) => {
                    switch(cs.csArray){
                        case 'DE':
                            delete cs.csArray;
                            tempDEArray.push(cs);
                            break;
                        case 'AICE':
                            delete cs.csArray;
                            tempAiceArray.push(cs);
                            break;
                        case 'AP':
                            delete cs.csArray;
                            tempApArray.push(cs);
                            break;
                        case 'CART':
                            delete cs.csArray;
                            tempCartArray.push(cs);
                            break;
                        case 'CSP':
                            delete cs.csArray;
                            tempCspArray.push(cs);
                            break;
                        case 'DLPT':
                            delete cs.csArray;
                            tempDlptArray.push(cs);
                            break;
                        case 'DSST':
                            delete cs.csArray;
                            tempDsstArray.push(cs);
                            break;
                        case 'IB':
                            delete cs.csArray;
                            tempIbArray.push(cs);
                            break;
                        case 'UEXCEL':
                            delete cs.csArray;
                            tempUexcelArray.push(cs);
                            break;
                        case 'Exempt':
                            delete cs.csArray;
                            tempExemptArray.push(cs);
                            break;
                        case 'CLEP':
                            delete cs.csArray;
                            tempClepArray.push(cs);
                            break;
                        case 'ACTFL':
                            delete cs.csArray;
                            tempActflArray.push(cs);
                            break;
                        case 'CAPE':
                            delete cs.csArray;
                            tempCapeArray.push(cs);
                            break;
                    }
                });

                let aCostSavingsArray = [];

                if(tempDEArray.length>0){
                    aCostSavingsArray.push({id: 0, label: 'Dual Enrollment (DE)', value: 'DE' })
                }
                if(tempAiceArray.length>0){
                    aCostSavingsArray.push({
                        id: 1,
                        value: 'AICE',
                        label: 'Association of International Credential Evaluators (AICE)'
                    })
                }
                if(tempApArray.length>0){
                    aCostSavingsArray.push({
                        id: 2,
                        value: 'AP',
                        label: 'Advanced Placement (AP)'
                    })   
                }
                if(tempCartArray.length>0){
                    aCostSavingsArray.push({
                        id: 3,
                        value: 'CART',
                        label: 'Center for Advanced Research and Technology (CART)'
                    })
                }
                if(tempCspArray.length>0){
                    aCostSavingsArray.push({
                        id: 5,
                        value: 'CSP',
                        label: 'Computer Skills Placement (CSP)'
                    })
                }
                if(tempDlptArray.length>0){
                    aCostSavingsArray.push({
                        id: 6,
                        value: 'DLPT',
                        label: 'Defense Language Proficiency Test (DLPT)'
                    })
                }
                if(tempDsstArray.length>0){
                    aCostSavingsArray.push({
                        id: 7,
                        value: 'DSST',
                        label: 'DSST (DANTES)'
                    })
                }
                if(tempIbArray.length>0){
                    aCostSavingsArray.push({
                        id: 8,
                        value: 'IB',
                        label: 'International Baccalaureate (IB)'
                    })
                }
                if(tempUexcelArray.length>0){
                    aCostSavingsArray.push({
                        id: 9,
                        value: 'Uexcel',
                        label: 'UExcel Examinations'
                    })
                }
                if(tempExemptArray.length>0){
                    aCostSavingsArray.push({
                        id: 10,
                        value: 'Exempt',
                        label: 'Exemption (Exempt)'
                    })
                }
                if(tempClepArray.length>0){
                    aCostSavingsArray.push({
                        id: 4,
                        value: 'CLEP',
                        label: 'College-Level Examination Program (CLEP)'
                    })
                }
                if(tempActflArray.length>0){
                    aCostSavingsArray.push({
                        id: 11,
                        value: 'ACTFL',
                        label: 'American Council on the Teaching of Foreign Languages (ACTFL)'
                    })
                }
                if(tempCapeArray.length>0){
                    aCostSavingsArray.push({
                        id: 12,
                        value: 'CAPE',
                        label: 'Caribbean Advanced Proficiency Examination Program (CAPE)'
                    })
                }

                setDualEnrollCourses(tempDEArray);
                setAiceCourses(tempAiceArray);
                setApCourses(tempApArray);
                setCartCourses(tempCartArray);
                setCspCourses(tempCspArray);
                setDlptCourses(tempDlptArray);
                setDsstCourses(tempDsstArray);
                setIbCourses(tempIbArray);
                setUexcelCourses(tempUexcelArray);
                setExemptCourses(tempExemptArray);
                setClepCourses(tempClepArray);
                setActflCourses(tempActflArray);
                setCapeCourses(tempCapeArray);
                setActiveCostSavings(aCostSavingsArray);
                if(aCostSavingsArray.length-1>0){
                    setExemptTab(parseInt(aCostSavingsArray.length - 1));
                }
                
                setFloridaRes(currentUser.profile.floridaResidency);
                setForeignLangExempt(currentUser.profile.foreignLangExempt);
                setAdditionalCost(currentUser.profile.addCost);
                setAdditionalSavings(currentUser.profile.addSave);
                setRemovedCourses(currentUser.profile.removedCourses);
                
                setCourses(nmdcCourses);
                setFiuCourses(nfiuCourses);
                setDualEnrollCourses(ndeCourses);
                //updateUserPathwayWCur(nmdcCourses, nfiuCourses, userSemesters);
                
            })
    } else {

        var newUserPathways = [];

        const newPathwayId = generateNewId();

        //console.log(userPathways);


        userPathways.map((upath) => {

            //console.log(upath.name);

            const getPathways = async () => {
                const pathways = await axios({
                    method: 'get',
                    withCredentials: true,
                    url: `/getpathways/${upath.basePathway}`
                });
                const nfiuCourses = pathways.data.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER')
                let nmdcCourses = pathways.data.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');
                if(testExempt){
                    nmdcCourses = pathways.data.filter(c => c.SheetName === 'ACSG');
                }

                upath = {...upath, mdcCourses: nmdcCourses, fiuCourses: nfiuCourses }
                
                newUserPathways.push(upath);
    
            } 

            getPathways();

        })

        //setUserPathways(newUserPathways);

        const getPathways = async () => {
            const pathways = await axios({
                method: 'get',
                withCredentials: true,
                url: `/getpathways/${fiupathway}`
            });

            const nfiuCourses = pathways.data.filter(c => c.SheetName === 'BCSG' || c.SheetName === 'TRANSFER')
            let nmdcCourses = pathways.data.filter(c => c.SheetName === 'ACSG' || c.SheetName === 'DEV');

            if(testExempt){
                nmdcCourses = pathways.data.filter(c => c.SheetName === 'ACSG');
            }
            //console.log(pathways.data);
            
            setCourses(nmdcCourses);
            setFiuCourses(nfiuCourses);

        } 
        getPathways();  

    } 
    setLoading(false);
    setAllowWarn(true); 
            
    }, []);

    const handleInitDialog = (props) => {
        setStartDialog(false);
        checkUser();
    }

    const handleTabChange = (event, newValue) => {
        const showUserPathway = userPathways.find(up => up.index === newValue);

        setMDCGrad(showUserPathway.mdcGrad);
        setCurrentPathway(showUserPathway.id);
        setPathwayName(showUserPathway.name);
        setBasePathway(showUserPathway.basePathway);

        setSemesters(showUserPathway.semesters);
        setCourses(showUserPathway.mdcCourses);
        setFiuCourses(showUserPathway.fiuCourses);

        setValue(newValue);
    }

    const handleExemptTabChange = (event, newValue) => {
        setExemptTab(newValue);
    }

    const handleAddProgram = async (info) => {

        try {
            const newUserPathwayIndex = Math.max.apply(Math, userPathways.map(o => {return o.index})) + 1;
            if(newUserPathwayIndex < 5){
                const newPathwayId = generateNewId();
                const newUserPathwayBasePathway = info.partpath;
                const newUserPathwayName = info.label;
                const newUserFullPathway = await axios({
                    method: 'GET',
                    withCredentials: true,
                    url: `/getbasepathway/${info.partpath}`
                });

                var allCurrentCourses = [...courses];
                var addPartCourses = [...fiucourses];
                var newFullCourses = newUserFullPathway.data;
                var newSemesters = [...semesters];


                var newFlMdcCourses = newFullCourses.filter(nfc => nfc.SheetName === 'ACSG');
                if(!testExempt){
                    newFlMdcCourses = newFullCourses.filter(nfc => nfc.SheetName === 'ACSG' || nfc.SheetName === 'DEV');
                }

                if(dualenroll){
                    newFlMdcCourses = newFlMdcCourses.filter(nc => nc.CourseNumber !== '1106' && nc.CoursePrefix !== 'SLS')
                }

                var newTestFiuCourses = newFullCourses.filter(nfc => nfc.SheetName === 'BCSG' || nfc.SheetName === 'TRANSFER');

                //Check Foreign Language Criteria
                var afterForeignLangMdc = await handleForeignLangExempt(newUserPathwayBasePathway, newFlMdcCourses);

                //Check Testing Criteria
                var revisedCourses = await handleTestEvalCourses(newUserPathwayBasePathway, afterForeignLangMdc, newTestFiuCourses);
                revisedCourses.mdc = revisedCourses.mdc.filter(m => {
                    let courseDE = dualEnrollCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseAICE = aiceCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseAP = apCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseCART = cartCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseCSP = cspCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseDLPT = dlptCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseDSST = dsstCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseIB = ibCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseUexcel = uexcelCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseCLEP = clepCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseExempt = exemptCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseACTFL = actflCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseCAPE = capeCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseRemoved = removedCourses.find(r => `${r.Title}` === `${m.Title}`);

                    if(!courseExempt && !courseRemoved && !courseDE && !courseAICE && !courseAP && !courseCART && !courseCSP && !courseDLPT && !courseDSST && !courseIB && !courseUexcel && !courseCLEP && !courseACTFL && !courseCAPE){
                        return m;
                    }
                });

                newFullCourses = [ ...revisedCourses.mdc ];
                var fiuPostTest = [ ...revisedCourses.fiu ];

                for (const c of removedCourses){
                    if(c.subCourse){
                        let subCoursePrefix = c.subCourse.CoursePrefix
                        let subCourseNumber = c.subCourse.CourseNumber
                        const getSubForPath = await axios({
                            method: 'POST',
                            data: {
                                CoursePrefix: subCoursePrefix,
                                CourseNumber: subCourseNumber,
                                BasePathway: newUserPathwayBasePathway
                            },
                            withCredentials: true,
                            url: '/coursesub'
                        })

                        let newSub = getSubForPath.data;
                        newSub = {...newSub, TERM: c.TERM, SheetName: c.SheetName }
                        newFullCourses.push(newSub);
                    }
                }

                Array.prototype.push.apply(newFullCourses, fiuPostTest);

                Array.prototype.push.apply(allCurrentCourses, addPartCourses);

                const originalArray = [ ...newFullCourses ];

                let nonCompSemStart = null;

                newSemesters.map((s) => {
                    if(s.completed){
                        var semCourses = allCurrentCourses.filter(c => parseInt(c.TERM) === parseInt(s.id.substr(1)));
                        var newSemCourses = newFullCourses.filter(c => parseInt(c.TERM) === parseInt(s.id.substr(1)));

                        newSemCourses.map((nSem) => {
                            nSem = { ...nSem, TERM: parseInt(s.id.substr(1)) + 1 }
                            newFullCourses = newFullCourses.filter(n => n.id !== nSem.id);
                            newFullCourses.push(nSem);
                        });

                        semCourses.map((cSem) => {
                            var eqNew;
                            if(cSem.Title.includes('Humanities') || cSem.Title.includes('Foreign') || cSem.Title.includes('Behavioral') || cSem.Title.includes('Oral ')){
                                //console.log(cSem.Title);
                                eqNew = newFullCourses.find(n => n.Title === cSem.Title);
                                if(eqNew){
                                    eqNew = { ...eqNew, TERM: parseInt(s.id.substr(1)), CoursePrefix: cSem.CoursePrefix, CourseNumber: cSem.CourseNumber }
                                    newFullCourses = newFullCourses.filter(n => n.id !== eqNew.id);
                                    newFullCourses.push(eqNew);
                                } else {
                                    newFullCourses.push(cSem);
                                }
                            } else {
                                eqNew = newFullCourses.find(n => `${n.CourseNumber}${n.CoursePrefix}` === `${cSem.CourseNumber}${cSem.CoursePrefix}`);
                                if(eqNew){
                                    eqNew = { ...eqNew, TERM: parseInt(s.id.substr(1)) }
                                    newFullCourses = newFullCourses.filter(n => n.id !== eqNew.id);
                                    newFullCourses.push(eqNew);
                                } else {
                                    newFullCourses.push(cSem);
                                }
                            }
                        })
                    } else {
                        if(!nonCompSemStart || nonCompSemStart>parseInt(s.id.substr(1))){
                            nonCompSemStart=parseInt(s.id.substr(1))
                        }
                    }
                });

                let postReqCoursesList = newFullCourses.filter(n => parseInt(n.TERM) === parseInt(nonCompSemStart));

                postReqCoursesList.forEach((c) => {
                    newFullCourses = postReqLoop(newFullCourses, c.id);
                });

                if(testExempt){
                    //newFullCourses = newFullCourses.filter(n => n.CoursePrefix !== 'SLS' && n.CourseNumber !== '1106')
                    newFullCourses = newFullCourses.filter(n => n.SheetName !== 'DEV');
                }

                let npMdcCourses = await newFullCourses.filter(nfc => {return nfc.SheetName === 'ACSG' || nfc.SheetName === 'DEV'});
                let npFiuCourses = await newFullCourses.filter(nfc => {return nfc.SheetName === 'BCSG' || nfc.SheetName === 'TRANSFER'});

                let mdcMaxTerm = Math.max.apply(Math, npMdcCourses.map(function(m){ return parseInt(m.TERM)}));
                let fiuMinTerm = Math.min.apply(Math, npFiuCourses.map(function(f){ return parseInt(f.TERM)}));

                npMdcCourses = npMdcCourses.filter(m => {
                    let courseExempt = dualEnrollCourses.find(d => `${d.CoursePrefix}${d.CourseNumber}` === `${m.CoursePrefix}${m.CourseNumber}`);
                    let courseRemoved = removedCourses.find(r => `${r.Title}` === `${m.Title}`);

                    if(!courseExempt && !courseRemoved){
                        return m;
                    }
                });

                if(mdcMaxTerm>=fiuMinTerm){
                    let diffAmount = mdcMaxTerm - fiuMinTerm;
                    let termAdjust = fiuMinTerm + diffAmount;
                    for(var i=fiuMinTerm; i <= termAdjust; i++){
                        for (var n in npFiuCourses){
                            if(parseInt(npFiuCourses[n].TERM) === i && npFiuCourses[n].SheetName === 'BCSG'){
                                npFiuCourses[n].TERM = i + 1
                                npFiuCourses = postReqLoop(npFiuCourses, npFiuCourses[n].id);
                            }
                        }
                    }
                }

                var newPathway = {
                    id: newPathwayId,
                    basePathway: info.partpath,
                    index: newUserPathwayIndex,
                    name: newUserPathwayName,
                    semesters: newSemesters,
                    mdcCourses: npMdcCourses,
                    fiuCourses: npFiuCourses,
                    mdcGrad: null
                }

                setUserPathways([ ...userPathways, newPathway ]);
                setCurrentPathway(newPathwayId);
                setPathwayName(newPathway.name);
                setBasePathway(newPathway.basePathway);
                setMDCGrad(newPathway.mdcGrad);
                //setFiuPathway(newPathway.basePathway);

                setOpenAddProgram(false);
                setSemesters(newPathway.semesters);
                setCourses(npMdcCourses);
                setFiuCourses(npFiuCourses);
                
                setValue(newPathway.index);


            } else {
                setOpenAddProgram(false);
                openMuiSnackbar({ message: 'Max Pathways Reached - Please remove one to add one!', variant: 'warning' });
            }   
        } catch (e) {
            console.log(e);
        }

        
    }

    const handleAddSavings = (savings) => {
        let nArray = activeCostSavings;

        nArray.push(savings);

        setActiveCostSavings(nArray);
        if(nArray.length-1 > 0){
            setExemptTab(parseInt(nArray.length - 1));
        }

        setOpenAddSavings(false);
        
    }

    const handleRemoveProgramClick = () => {
        setOpenRemoveProgram(true);
    }

    const handleRemoveSavingsClick = () => {
        let rmCS = activeCostSavings[exemptTab];

        let rmArray = 0;

        let newArr = [];

        switch(rmCS.value){
            case 'DE':
                rmArray = dualEnrollCourses.length;
                break;
            case 'AICE':
                rmArray = aiceCourses.length;
                break;
            case 'AP':
                rmArray = apCourses.length;
                break;
            case 'CART':
                rmArray = cartCourses.length;
                break;
            case 'CLEP':
                rmArray = clepCourses.length;
                break;
            case 'CSP':
                rmArray = cspCourses.length;
                break;
            case 'DLPT':
                rmArray = dlptCourses.length;
                break;
            case 'DSST':
                rmArray = dsstCourses.length;
                break;
            case 'Exempt':
                rmArray = exemptCourses.length;
                break;
            case 'IB':
                rmArray = ibCourses.length;
                break;
            case 'Uexcel':
                rmArray = uexcelCourses.length;
                break;
            case 'ACTFL':
                rmArray = actflCourses.length;
                break;
            case 'CAPE':
                rmArray = capeCourses.length;
                break;
        }


        if(rmArray > 0){
            openMuiSnackbar({ message: `All courses need to be removed from this Cost Savings before removing`, variant: 'warning'})
        } else {
            if(activeCostSavings.length > 1){
                setExemptTab(0);
                newArr = activeCostSavings.filter((a, i) => i !== exemptTab);
                setActiveCostSavings(newArr);
            } else {
                setActiveCostSavings(newArr);
            }
        }

    }

    const handleRemoveProgramClose = () => {
        refreshUserPathways();
        setOpenRemoveProgram(false);
    } 

    const PreReqCheck = (courseId, semester) => {
        var invalid = false;
        var nSem = semester;
        var nCid = courseId;
        var preReqChainCount = 0;

/*         if(debugApp && semester === 3){
            console.log('Debug Stop');
        } */

        //New Failed

        /* do{
            let thisCourse = courses.find(c => c.id === nCid);
            if(!thisCourse){
                thisCourse = fiucourses.find(f => f.id === nCid);
            }
            if(!thisCourse){
                thisCourse = dualEnrollCourses.find(e => e.id === nCid);
            }
            if(!thisCourse){
                nCid = null;
            }
            if(thisCourse.PREREQ){
                let allPreReqs = thisCourse.PREREQ.split(';');
                let activePreReqArr = []

                allPreReqs.forEach(p => {
                    let preReq = courses.find(c => `${c.CoursePrefix}${c.CourseNumber}` === p.trim());
                    if(preReq){
                        activePreReqArr.push(preReq);
                    }
                });

                let maxPreReq = Math.max.apply(Math, activePreReqArr.map(function(o) {return o.TERM }));
                if(maxPreReq >= semester){
                    let bumpSem = semester
                    activePreReqArr.sort((a, b) => (a.TERM > b.TERM) ? 1 : -1);
                    activePreReqArr.reverse();
                    activePreReqArr.forEach(a => {
                        let newTerm = bumpSem - 1
                        if(newTerm <= 0){
                            invalid = true;
                            nCid = null;
                        }
                        bumpSem--
                    })
                    nCid = null;
                } else {
                    nCid = null;
                }

            } else {
                nCid = null;
            }
        }
        while(nCid !== null); */

        //Revised Check

        let thisCourse = courses.find((c) => c.id === nCid);

        if(!thisCourse){
            thisCourse = fiucourses.find((f) => f.id === nCid);
        }
        if(!thisCourse){
            thisCourse = dualEnrollCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = aiceCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = apCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = dlptCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = dsstCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = cartCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = cspCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = ibCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = uexcelCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = clepCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = exemptCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = actflCourses.find((d) => d.id === nCid);
        }

        if(!thisCourse){
            thisCourse = capeCourses.find((d) => d.id === nCid);
        }

        let preReqValid = preReqCheckLoop(thisCourse, nSem);

        invalid = preReqValid;

/* 
        do {
            var thisCourse = courses.find((c) => c.id === nCid);
            let dualEnrolled = false;
            if(!thisCourse){
                thisCourse = dualEnrollCourses.find(e => e.id === nCid);
                dualEnrolled = true;
            }
            if(!thisCourse){
                thisCourse = fiucourses.find(f => f.id === nCid);
                dualEnrolled = false;
            }
            if(thisCourse.PREREQ){
                var mainPreReqs = thisCourse.PREREQ.split(';');


                mainPreReqs.forEach(function(pr, i){
                    let activePreReq = courses.find(c => `${c.CoursePrefix}${c.CourseNumber}` === pr);
                    if(!activePreReq){
                        activePreReq = fiucourses.find(f => `${f.CoursePrefix}${f.CourseNumber}` === pr);
                    }
                    if(activePreReq){
                        if(checkCourseCompleted(parseInt(activePreReq.TERM)-1)){
                            return;
                        }
                        if(nSem <= activePreReq.TERM){

                        }
                    }
                });
                for(var n=0; n<mainPreReqs.length; n++){
                    if(mainPreReqs[n].includes(' OR ')){
                        var preReqs = mainPreReqs[n].split(' OR ');
                        for(var i = 0; i<preReqs.length; i++){
                            var preReq = preReqLookup(courses, preReqs[i].trim());
                            if(preReq){
                                if(checkCourseCompleted(parseInt(preReq.TERM)-1)){
                                    nSem = 0;
                                    nCid = null;
                                    break;
                                }
                                if(preReq.PREREQ){
                                    nSem--;
                                    nCid = preReq.id;
                                    break;
                                } else {
                                    nCid = preReq.id;
                                }
                            } else {
                                if(preReqs.length === 1){
                                    nCid = null;
                                }
                                if(preReqs.length - 1 === i){
                                    nCid = null;
                                }
                            }
                        }
                    } else {
                        //var preReqs = mainPreReqs[n].split(';');
                        //for(var i=0; i<preReqs.length; i++){
                            var preReq = preReqLookup(courses, mainPreReqs[n].trim());
                            if(preReq){
                                if(checkCourseCompleted(parseInt(preReq.TERM)-1)){
                                    nSem = 0;
                                    nCid = null;
                                    break;
                                }
                                if(preReq.PREREQ){
                                    nSem--;
                                    nCid = preReq.id;
                                    break;
                                } else {
                                    nCid = preReq.id;
                                }
                            }else{
                                if(mainPreReqs.length === 1 && n === mainPreReqs.length - 1){
                                    nCid = null;
                                }
                            }
                        //}
                    }
                }                
                preReqChainCount++;
            } else {
                nCid = null;
            }
            if(nSem <= 0){
                invalid = true;
            }

        }
        while(nCid !== null); */



        //Old Check


        /* do {
            var thisCourse = courses.find((c) => c.id === nCid);
            let dualEnrolled = false;
            if(!thisCourse){
                thisCourse = dualEnrollCourses.find(e => e.id === nCid);
                dualEnrolled = true;
            }
            if(!thisCourse){
                thisCourse = fiucourses.find(f => f.id === nCid);
                dualEnrolled = false;
            }
            if(thisCourse.PREREQ){
                var mainPreReqs = thisCourse.PREREQ.split(';');
                for(var n=0; n<mainPreReqs.length; n++){
                    if(mainPreReqs[n].includes(' OR ')){
                        var preReqs = mainPreReqs[n].split(' OR ');
                        for(var i = 0; i<preReqs.length; i++){
                            var preReq = preReqLookup(courses, preReqs[i].trim());
                            if(preReq){
                                if(checkCourseCompleted(parseInt(preReq.TERM)-1)){
                                    nSem = 0;
                                    nCid = null;
                                    break;
                                }
                                if(preReq.PREREQ){
                                    nSem--;
                                    nCid = preReq.id;
                                    break;
                                } else {
                                    nCid = preReq.id;
                                }
                            } else {
                                if(preReqs.length === 1){
                                    nCid = null;
                                }
                                if(preReqs.length - 1 === i){
                                    nCid = null;
                                }
                            }
                        }
                    } else {
                        //var preReqs = mainPreReqs[n].split(';');
                        //for(var i=0; i<preReqs.length; i++){
                            var preReq = preReqLookup(courses, mainPreReqs[n].trim());
                            if(preReq){
                                if(checkCourseCompleted(parseInt(preReq.TERM)-1)){
                                    nSem = 0;
                                    nCid = null;
                                    break;
                                }
                                if(preReq.PREREQ){
                                    nSem--;
                                    nCid = preReq.id;
                                    break;
                                } else {
                                    nCid = preReq.id;
                                }
                            }else{
                                if(mainPreReqs.length === 1 && n === mainPreReqs.length - 1){
                                    nCid = null;
                                }
                            }
                        //}
                    }
                }                
                preReqChainCount++;
            } else {
                nCid = null;
            }
            if(nSem <= 0){
                invalid = true;
            }

        }
        while(nCid !== null); */

        /* if(preReqChainCount > semester){
            invalid = true;
        } */

        return invalid;
    }

    const preReqCheckLoop = (course, semester) => {
        let invalid = false;
        if(debugApp && semester == '2'){
            //console.log('Deubg App')
        }
        if(course.PREREQ){
            let coursePreReqs = course.PREREQ.split(';');
            let nSem = semester;
            for(var x=0; x<coursePreReqs.length; x++){
                let preReq = preReqLookup(courses, coursePreReqs[x].trim());
                if(!preReq){
                    preReq = preReqLookup(fiucourses, coursePreReqs[x].trim());
                }
                if(!preReq){
                    continue;
                }
                if(nSem <= parseInt(preReq.TERM)){
                    let nAvailSem = getPrevTerm(parseInt(preReq.TERM)-1);
                    let nSemComp = semesters.find(s => parseInt(s.id.substr(1)) === parseInt(preReq.TERM)-1);
                    if(parseInt(preReq.TERM)-1 <= 0 || (nSem === 1 && parseInt(preReq.TERM)-1 === nSem) || nSemComp.completed ){
                        invalid = true;
                        break;
                    } else {
                        if(preReq.PREREQ){
                            invalid = preReqCheckLoop(preReq, parseInt(preReq.TERM)-1);
                        }
                    }
                }

            }
        }
        return invalid;
    }

    const PostReqCheck = (postreqs, semester) => {
        var invalid = false;
        /* postreqs.map((pr) => {
            if(courses[courses.findIndex(obj => `${obj.CoursePrefix}${obj.CourseNumber}`===pr)].TERM >= semester){
                invalid = true;
            }
        }); */
    
        return invalid;
    }

    const PreReqPush = () => {
        courses.map((c) => {
            if(c.PREREQ){
                var prereqs = c.PREREQ.replace('\\s+', '');
                prereqs = prereqs.split(';');
                prereqs.map((pr) => {
                    var prereq = courses.find(c=> `${c.CoursePrefix}${c.CourseNumber}` === pr);
                    if(prereq){
                        if(prereq.TERM >= c.TERM){
                            //var narray = 
                            changeCourses(prereq.id, `s${prereq.TERM + 1}`);
                        }
                    }
                })

            }            
        })
        //let postreqs = courses.find(c => c.PREREQ)
    }

    const checkMDCMaxSemester = () => {
        return Math.max.apply(Math, courses.map((o) => !isNaN(o.TERM) && o.TERM));
    }

    const checkPathwayMaxTerm = (pathwayCourses) => {
        return Math.max.apply(Math, pathwayCourses.map((o) => !isNaN(o.TERM) && o.TERM));
    }

    const handleCoReq = (coreqs, semester) => {

    }

    const refreshUserPathways = () => {

        if(currentUser !== null && currentUser !== ''){  
            
            setStartDialog(false);
            var userPath;
            var userPaths;
            var userSemesters = [];
            var pathway;

            const requests = async () => {

                const pathwayObj = await axios({
                    method: 'get',
                    withCredentials: true,
                    url: '/getuserpathways'
                });

                //console.log(pathwayObj.data);

                pathwayObj.data[0].semesters.map((s) => {
                    userSemesters.push({ ...s, termdate: new Date(s.termdate)});
                });

                userPaths = pathwayObj.data;
            };

            requests().then(() => {

                setUserPathways(userPaths);
                //console.log(userPaths);

                const nfiuCourses = userPaths[0].fiuCourses;
                const nmdcCourses = userPaths[0].mdcCourses;
                const ncsCourses = userPaths[0].csCourses;
                const ndeCourses = userPaths[0].deCourses;

                const nBasePathway = userPaths[0].basePathway;
                const nPathwayName = userPaths[0].name;

                
                setCurrentPathway(userPaths[0].id);
                setValue(userPaths[0].index);
                setBasePathway(nBasePathway);
                setPathwayName(nPathwayName);

                setSemesters(userSemesters);
                setDualEnrollCourses(ndeCourses);
                setCourses(nmdcCourses);
                setFiuCourses(nfiuCourses);

                let tempDEArray = [];
                let tempAiceArray = [];
                let tempApArray = [];
                let tempCartArray = [];
                let tempCspArray = [];
                let tempDlptArray = [];
                let tempDsstArray = [];
                let tempIbArray = [];
                let tempUexcelArray = [];
                let tempExemptArray = [];
                let tempClepArray = [];
                let tempActflArray = [];
                let tempCapeArray = [];

                ncsCourses.map((cs) => {
                    switch(cs.csArray){
                        case 'DE':
                            delete cs.csArray;
                            tempDEArray.push(cs);
                            break;
                        case 'AICE':
                            delete cs.csArray;
                            tempAiceArray.push(cs);
                            break;
                        case 'AP':
                            delete cs.csArray;
                            tempApArray.push(cs);
                            break;
                        case 'CART':
                            delete cs.csArray;
                            tempCartArray.push(cs);
                            break;
                        case 'CSP':
                            delete cs.csArray;
                            tempCspArray.push(cs);
                            break;
                        case 'DLPT':
                            delete cs.csArray;
                            tempDlptArray.push(cs);
                            break;
                        case 'DSST':
                            delete cs.csArray;
                            tempDsstArray.push(cs);
                            break;
                        case 'IB':
                            delete cs.csArray;
                            tempIbArray.push(cs);
                            break;
                        case 'UEXCEL':
                            delete cs.csArray;
                            tempUexcelArray.push(cs);
                            break;
                        case 'Exempt':
                            delete cs.csArray;
                            tempExemptArray.push(cs);
                            break;
                        case 'CLEP':
                            delete cs.csArray;
                            tempClepArray.push(cs);
                            break;
                        case 'ACTFL':
                            delete cs.csArray;
                            tempActflArray.push(cs);
                            break;
                        case 'CAPE':
                            delete cs.csArray;
                            tempCapeArray.push(cs);
                            break;
                    }
                });

                let aCostSavingsArray = [];

                if(tempDEArray.length>0){
                    aCostSavingsArray.push({id: 0, label: 'Dual Enrollment (DE)', value: 'DE' })
                }
                if(tempAiceArray.length>0){
                    aCostSavingsArray.push({
                        id: 1,
                        value: 'AICE',
                        label: 'Association of International Credential Evaluators (AICE)'
                    })
                }
                if(tempApArray.length>0){
                    aCostSavingsArray.push({
                        id: 2,
                        value: 'AP',
                        label: 'Advanced Placement (AP)'
                    })   
                }
                if(tempCartArray.length>0){
                    aCostSavingsArray.push({
                        id: 3,
                        value: 'CART',
                        label: 'Center for Advanced Research and Technology (CART)'
                    })
                }
                if(tempCspArray.length>0){
                    aCostSavingsArray.push({
                        id: 5,
                        value: 'CSP',
                        label: 'Computer Skills Placement (CSP)'
                    })
                }
                if(tempDlptArray.length>0){
                    aCostSavingsArray.push({
                        id: 6,
                        value: 'DLPT',
                        label: 'Defense Language Proficiency Test (DLPT)'
                    })
                }
                if(tempDsstArray.length>0){
                    aCostSavingsArray.push({
                        id: 7,
                        value: 'DSST',
                        label: 'DSST (DANTES)'
                    })
                }
                if(tempIbArray.length>0){
                    aCostSavingsArray.push({
                        id: 8,
                        value: 'IB',
                        label: 'International Baccalaureate (IB)'
                    })
                }
                if(tempUexcelArray.length>0){
                    aCostSavingsArray.push({
                        id: 9,
                        value: 'Uexcel',
                        label: 'UExcel Examinations'
                    })
                }
                if(tempExemptArray.length>0){
                    aCostSavingsArray.push({
                        id: 10,
                        value: 'Exempt',
                        label: 'Exemption (Exempt)'
                    })
                }
                if(tempClepArray.length>0){
                    aCostSavingsArray.push({
                        id: 4,
                        value: 'CLEP',
                        label: 'College-Level Examination Program (CLEP)'
                    })
                }
                if(tempActflArray.length>0){
                    aCostSavingsArray.push({
                        id: 11,
                        value: 'ACTFL',
                        label: 'American Council on the Teaching of Foreign Languages (ACTFL)'
                    })
                }
                if(tempCapeArray.length>0){
                    aCostSavingsArray.push({
                        id: 12,
                        value: 'CAPE',
                        label: 'Caribbean Advanced Proficiency Examination Program (CAPE)'
                    })
                }




                setDualEnrollCourses(tempDEArray);
                setAiceCourses(tempAiceArray);
                setApCourses(tempApArray);
                setCartCourses(tempCartArray);
                setCspCourses(tempCspArray);
                setDlptCourses(tempDlptArray);
                setDsstCourses(tempDsstArray);
                setIbCourses(tempIbArray);
                setUexcelCourses(tempUexcelArray);
                setExemptCourses(tempExemptArray);
                setClepCourses(tempClepArray);
                setActflCourses(tempActflArray);
                setCapeCourses(tempCapeArray);
                setActiveCostSavings(aCostSavingsArray);
                if(aCostSavingsArray.length-1>0){
                    setExemptTab(parseInt(aCostSavingsArray.length - 1));
                }

                setMDCGrad(userPaths[0].mdcGrad);
                setTestArray(currentUser.profile.testsTaken);
                //setTest(currentUser.profile.testTaken);
                //setWritingScore(currentUser.profile.writingScore);
                //setMathScore(currentUser.profile.mathScore);
                //setReadingScore(currentUser.profile.readingScore);
                //setAafScore(currentUser.profile.aafScore);
                //setDualEnroll(currentUser.profile.dualEnrollment);
                //setDualTerms(currentUser.profile.dualTerms);
                setFloridaRes(currentUser.profile.floridaResidency);
                setForeignLangExempt(currentUser.profile.foreignLangExempt);
                setAdditionalCost(currentUser.profile.addCost);
                setAdditionalSavings(currentUser.profile.addSave);
                setRemovedCourses(currentUser.profile.removedCourses);

                /* setSemesters(userSemesters);
                setCourses(nmdcCourses);
                setFiuCourses(nfiuCourses); */

                //updateUserPathwayWCur(nmdcCourses, nfiuCourses, userSemesters);

                //console.log(userPathways);
                
            })
        }
    }

    const handlePathwaySave = () => {

        if(currentUser !== null && currentUser !== ''){
            const handleSave = async () => {
                const pathway = userPathways.find(u => u.id === currentPathway);
                
                let csCourses = [];

                if(dualEnrollCourses.length > 0){
                    dualEnrollCourses.map((de) => {
                        csCourses.push({ ...de, csArray: 'DE' });
                    });
                }

                if(aiceCourses.length > 0){
                    aiceCourses.map((ai) => {
                        csCourses.push({ ...ai, csArray: 'AICE' });
                    });
                }

                if(apCourses.length > 0){
                    apCourses.map((ap) => {
                        csCourses.push({ ...ap, csArray: 'AP' });
                    });
                }

                if(cartCourses.length > 0){
                    cartCourses.map((cart) => {
                        csCourses.push({ ...cart, csArray: 'CART' });
                    });
                }

                if(cspCourses.length > 0){
                    cspCourses.map((csp) => {
                        csCourses.push({ ...csp, csArray: 'CSP' });
                    });
                }

                if(dlptCourses.length > 0){
                    dlptCourses.map((dlpt) => {
                        csCourses.push({ ...dlpt, csArray: 'DLPT' });
                    });
                }

                if(dsstCourses.length > 0){
                    dsstCourses.map((dsst) => {
                        csCourses.push({ ...dsst, csArray: 'DSST' });
                    });
                }

                if(ibCourses.length > 0){
                    ibCourses.map((ib) => {
                        csCourses.push({ ...ib, csArray: 'IB' });
                    });
                }

                if(uexcelCourses.length > 0){
                    uexcelCourses.map((ux) => {
                        csCourses.push({ ...ux, csArray: 'UEXCEL' });
                    })
                }

                if(exemptCourses.length > 0){
                    exemptCourses.map((exempt) => {
                        csCourses.push({ ...exempt, csArray: 'Exempt' });
                    });
                }

                if(clepCourses.length > 0){
                    clepCourses.map((clep) => {
                        csCourses.push({ ...clep, csArray: 'CLEP' });
                    });
                }

                if(actflCourses.length > 0){
                    actflCourses.map((actfl) => {
                        csCourses.push({ ...actfl, csArray: 'ACTFL' });
                    });
                }

                if(capeCourses.length > 0){
                    capeCourses.map((cape) => {
                        csCourses.push({ ...cape, csArray: 'CAPE' });
                    });
                }

                const serverRes = await axios({
                    method: 'post',
                    data: {
                        currentPathway: currentPathway,
                        mdcCourseList: courses,
                        semesterList: semesters,
                        fiuCourseList: fiucourses,
                        deCourseList: dualEnrollCourses,
                        csCourseList: csCourses,
                        userPathway: pathway,
                        mdcGrad: mdcGrad,
                        removedCourseList: removedCourses
                    },
                    withCredentials: true,
                    url: '/savepathway'
                });

                if(serverRes.data === 'Received Data'){
                    const pathwaysRes = await axios({
                        method: 'get',
                        withCredentials: true,
                        url: '/getuserpathways'
                    });
                    openMuiSnackbar({ message: 'Saved Successfully!', variant: 'success' });
                } else {
                    openMuiSnackbar({ message: 'Save Not Completed Correctly', variant: 'warning' });
                }
            }

            handleSave();
        } else {
            openMuiSnackbar({ message: 'Not Signed In', variant: 'warning' });
            setLoginOpen(true);
        }

    }

    const semesterGradCheck = () => {
        var grad = false;
        semesters.map((s) => {
            if(s.grad){
                grad = true;
            }
        });
        return grad;
    }

    const handleMdcGradClose = () => {
        setOpenMdcGrad(false);
    }

    const handleMdcGradOpen = () => {
        setOpenMdcGrad(true);
    }

    const handleFiuAddElective = async () => {
        var fiuUnitTotal = 0;
        var gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;
        fiucourses.map((f) => {
            if(f.SheetName !== 'TRANSFER'){
                fiuUnitTotal = fiuUnitTotal + f.Units;
            }
            if(f.SheetName === 'TRANSFER' && gradLine < f.TERM){
                fiuUnitTotal = fiuUnitTotal + f.Units;
            }
        });

        if(fiuUnitTotal < 60 && fiucourses.length > 0){
            const newid = getMaxId()+1;
            const fiuElectiveQuery = await axios({
                method: 'get',
                withCredentials: true,
                url: '/fiuelective'
            });
            const fiuElective = fiuElectiveQuery.data;
            const newclass = { 
                id: newid,
                CoursePrefix: fiuElective.CoursePrefix,
                CourseNumber: fiuElective.CourseNumber,
                PathwayName: pathwayName,
                SheetName: 'BCSG',
                PERT: null,
                ACCP: null,
                ACT: null,
                SAT: null,
                CostSaving: null,
                CourseSub: null,
                ABEHAV: null,
                BBEHAV: null,
                REQGRAD: fiuElective.REQGRAD,
                EXEMPT: false,
                PREREQ: null,
                COREQ: null,
                POSTREQ: null,
                RESCOST: fiuElective.RESCOST,
                NONRESCOST: fiuElective.RESCOST,
                Component: fiuElective.Component,
                TERM: getSemMax(), 
                Transfer: false,
                Color: fiuElective.Color,
                Title: fiuElective.Title,
                CourseID: fiuElective.CourseID,
                Description: fiuElective.Description,
                Units: parseInt(fiuElective.Units),
                Prerequisite: null,
                Corequisite: null,
                URL: null,
                Exemption: null,
                logo: true,
                videoUrl: null,
                BasePathway: basePathway
             }
             setFiuCourses([ ...fiucourses, newclass ]);            
        }
    }

    const handleFiuRemoveElective = () => {
        var fiuUnitTotal = 0;
        var gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;
        var elecFlag = false;
        fiucourses.map((f) => {
            if(f.SheetName !== 'TRANSFER'){
                fiuUnitTotal = fiuUnitTotal + f.Units;
            }
            if(f.SheetName === 'TRANSFER' && gradLine < f.TERM){
                fiuUnitTotal = fiuUnitTotal + f.Units;
            }
            if(f.CoursePrefix && f.CoursePrefix.trim() === 'ELEC'){
                elecFlag = true;
            }
        });
        
        if(fiuUnitTotal > 62 && elecFlag){
            var fiuElecCourses = fiucourses.filter((f) => f.CoursePrefix && f.CoursePrefix.trim() === 'ELEC')
            var nFiuElecCourses = fiucourses.filter((f) => f.CoursePrefix && f.CoursePrefix.trim() !== 'ELEC')
            var maxElecId = Math.max.apply(Math, fiuElecCourses.map((e) => e.id));
            fiuElecCourses.map((elective) => {
                if(elective.id !== maxElecId){
                    nFiuElecCourses.push(elective);
                }
            });
            setFiuCourses(nFiuElecCourses);
        }
    }

    const handleMdcAddElective = async () => {
        var mdcUnitTotal = 0;
        var gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;

        //Count Units
        fiucourses.map((f) => {
            if(f.SheetName === 'TRANSFER' && gradLine >= f.TERM){
                mdcUnitTotal = mdcUnitTotal + f.Units;
            }
        });

        courses.map((m) => {
            mdcUnitTotal = mdcUnitTotal + m.Units;
        });

        dualEnrollCourses.map((d) => {
            mdcUnitTotal = mdcUnitTotal + d.Units;
        })

        if(mdcUnitTotal < 60 && courses.length > 10){
            const newid = getMaxId()+1;
            const mdcElectiveQuery = await axios({
                method: 'get',
                withCredentials: true,
                url: '/mdcelective'
            });
            const mdcElective = mdcElectiveQuery.data;
            const newclass = { 
                id: newid,
                CoursePrefix: mdcElective.CoursePrefix,
                CourseNumber: mdcElective.CourseNumber,
                PathwayName: pathwayName,
                SheetName: 'ACSG',
                PERT: null,
                ACCP: null,
                ACT: null,
                SAT: null,
                CostSaving: null,
                CourseSub: null,
                ABEHAV: null,
                BBEHAV: null,
                REQGRAD: mdcElective.REQGRAD,
                EXEMPT: false,
                PREREQ: null,
                COREQ: null,
                POSTREQ: null,
                RESCOST: mdcElective.RESCOST,
                NONRESCOST: mdcElective.RESCOST,
                Component: mdcElective.Component,
                TERM: gradLine, 
                Transfer: false,
                Color: mdcElective.Color,
                Title: mdcElective.Title,
                CourseID: mdcElective.CourseID,
                Description: mdcElective.Description,
                Units: parseInt(mdcElective.Units),
                Prerequisite: null,
                Corequisite: null,
                URL: null,
                Exemption: null,
                logo: true,
                videoUrl: null,
                BasePathway: basePathway
             }
             setCourses([ ...courses, newclass ]);            
        }
    }

    const handleMdcRemoveElective = () => {
        var mdcUnitTotal = 0;
        var gradLine = mdcGrad ? mdcGrad : mdcMaxSemester;
        var elecFlag = false;
        fiucourses.map((f) => {
            if(f.SheetName === 'TRANSFER' && gradLine >= f.TERM){
                mdcUnitTotal = mdcUnitTotal + f.Units;
            }
        });

        courses.map((m) => {
            mdcUnitTotal = mdcUnitTotal + m.Units;
            if(m.CoursePrefix && m.CoursePrefix.trim() === 'ELEC'){
                elecFlag = true;
            }
        });

        dualEnrollCourses.map((d) => {
            mdcUnitTotal = mdcUnitTotal + d.Units;
        })
        
        if(mdcUnitTotal > 62 && elecFlag){
            var mdcElecCourses = courses.filter((m) => m.CoursePrefix && m.CoursePrefix.trim() === 'ELEC')
            var nMdcElecCourses = courses.filter((m) => m.Title.trim() !== 'MDC Elective')
            var maxElecId = Math.max.apply(Math, mdcElecCourses.map((e) => e.id));
            mdcElecCourses.map((elective) => {
                if(elective.id !== maxElecId){
                    nMdcElecCourses.push(elective);
                }
            });
            setCourses(nMdcElecCourses);
        }
    }

    const exemptTabButtonClass = makeStyles({
       scrollButtons: {
           fontSize: '2.0rem !important',
           fontWeight: 'bold !important'
       }
    });

    const exemptClasses = exemptTabButtonClass();

    if(loading){
        return <div />
    }
    
    
    return(
        <Grid container justify="center" alignItems="center">
            {(currentUser !== null && currentUser !== '') && 
            <Fab size='medium' tabIndex={0} onClick={handleShowReset} variant="extended" style={{ margin: 0, top: 'auto', right: 20, bottom: 335, left: 'auto', position: 'fixed', textTransform: 'capitalize' }}>
                <ResetIcon />
                Reset
            </Fab>}
            <Fab size='medium' tabIndex={0} onClick={handleShowLegend} variant="extended" style={{ margin: 0, top: 'auto', right: 20, bottom: 290, left: 'auto', position: 'fixed', textTransform: 'capitalize' }}>
                <ListIcon />
                Legend
            </Fab>
            <Fab size='medium' tabIndex={0} onClick={handleManOpen} variant="extended" style={{ margin: 0, top: 'auto', right: 20, bottom: 245, left: 'auto', position: 'fixed', textTransform: 'capitalize' }}>
                <AddIcon />
                Course
            </Fab>
            <Fab size='medium' tabIndex={0} onClick={handleManTermAdd} variant="extended" style={{ width: '85px', margin: 0, top: 'auto', right: 20, bottom: 200, left: 'auto', position: 'fixed', textTransform: 'capitalize' }} aria-label='new term'>
                <AddIcon />
                Term
            </Fab>
            {/* <Link target='_blank' onClick={() => localStorage.setItem("pdfRef", JSON.stringify({ fiucourses: fiucourses, courses: courses, completed: completed, startdate: startdate, enddate: enddate, semesters: semesters, dualenroll: dualenroll, dualterms: dualterms, mdcMaxSemester: mdcMaxSemester, mdcGrad: mdcGrad, floridares: floridares, accumcost: accumCost, accumhours: accumHours }))} to={{ pathname: '/pdf'}}> */}
                <Fab size='medium' component={Link} target='_blank' onClick={() => localStorage.setItem("pdfRef", JSON.stringify({ fiucourses: fiucourses, courses: courses, completed: completed, startdate: startdate, enddate: enddate, semesters: semesters, dualenroll: dualenroll, dualterms: dualterms, mdcMaxSemester: mdcMaxSemester, mdcGrad: mdcGrad, floridares: floridares, accumcost: accumCost, accumhours: accumHours, dualEnrollCourses: dualEnrollCourses, mdcGradDate: mdcGradDate, compDate: compDate, additionalCost: additionalCost, additionalSavings: additionalSavings }))} to={{ pathname: '/pdf'}} tabIndex={0} variant="extended" style={{ width: '85px', margin: 0, top: 'auto', right: 20, bottom: 155, left: 'auto', position: 'fixed', textTransform: 'capitalize' }}>
                    <PrintIcon />
                    Print
                </Fab>
            {/* </Link> */}
            <Fab size='small' tabIndex={0} onClick={handlePathwaySave} variant="extended" style={{ width: '85px', margin: 0, top: 'auto', right: 20, bottom: 110, left: 'auto', position: 'fixed', textTransform: 'capitalize' }}>
                <SaveIcon />
                Save
            </Fab>
            <Fab size='small' variant='extended' tabIndex={0} target="_blank" href='https://www.mdc.edu/registration/' style={{ margin: 0, top: 'auto', right: 20, bottom: 65, left: 'auto', position: 'fixed', textTransform: 'capitalize' }}>
                <RegisterIcon />
                Register
            </Fab>
            <Fab size='medium' tabIndex={0} onClick={handleDashToggle} variant="extended" style={{ margin: 0, top: 'auto', right: 20, bottom: 20, left: 'auto', position: 'fixed', textTransform: 'capitalize' }}>
                {showdash ? <span><HideIcon />Hide Dash</span> : <span><VisibleIcon />Show Dash</span>}
            </Fab>
            {/* <Fab tabIndex={0} onClick={handleMdcGradOpen} variant="extended" style={{ margin: 0, top: 'auto', right: 20, bottom: 20, left: 'auto', position: 'fixed' }}>
                <GradIcon />
                Graduation
            </Fab> */}
            <Grid item xs={1} />
                <Grid item xs={10}>
                    <Collapse in={showdash}>
                        <CostSpeedo dualenroll={dualenroll} dualEnrollCourses={dualEnrollCourses} dualterms={dualterms} floridares={floridares} fiucourses={fiucourses} courses={courses} progress={completed} startdate={startdate} enddate={enddate} accumcost={accumCost} accumhours={accumHours} mdcGrad={mdcGrad} mdcMaxSemester={mdcMaxSemester} mdcGradDate={mdcGradDate} compDate={compDate} additionalCosts={additionalCost} additionalSavings={additionalSavings} aiceCourses={aiceCourses} apCourses={apCourses} cartCourses={cartCourses} clepCourses={clepCourses} cspCourses={cspCourses} dlptCourses={dlptCourses} dsstCourses={dsstCourses} exemptCourses={exemptCourses} ibCourses={ibCourses} uexcelCourses={uexcelCourses} actflCourses={actflCourses} capeCourses={capeCourses} />
                    </Collapse>
                </Grid>
                <Grid item xs={1} />
            <Grid item xs={1} />
            <Grid item xs={10}>
            <DndProvider backend={MultiBackend} options={HTML5toTouch} >
                <Grid container style={{ marginBottom: '15px' }}>
                <Grid item xs={12}>
                        <AppBar position='static' style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', backgroundColor: 'rgb(220, 220, 220)', color: '#383838', flexDirection: 'row' }}>
                            <Tabs classes={{ scrollButtons: exemptClasses.scrollButtons }} style={{ flexGrow: 1 }} indicatorColor='primary' value={exemptTab} onChange={handleExemptTabChange} variant='scrollable' scrollButtons='auto'>
                                {activeCostSavings.length > 0 && activeCostSavings.map((cs, i) => (
                                    <Tab style={{ textTransform: 'none', fontWeight: 'bold' }} label={cs.label} {...a11yProps(i)} />
                                ))}
                                {/* <Tab style={{ textTransform: 'none', fontWeight: 'bold' }} label='Dual Enrollment' {...a11yProps(0)} /> */}
                            </Tabs>
                            {activeCostSavings.length > 0 &&
                            <Fab style={{ textTransform: 'capitalize' }} color='secondary' variant='extended' onClick={handleRemoveSavingsClick}>
                                Remove Savings
                            </Fab>}
                            <Fab style={{ textTransform: 'capitalize' }} onClick={handleAddSavingsOpen} variant='extended' >
                                <AddIcon /> Add Savings
                            </Fab>
                        </AppBar>
                        {activeCostSavings.length > 0 && 
                            <React.Fragment>
                                {activeCostSavings.map((cs, i) => {


                                    let activeArray = [];

                                    switch(cs.value){
                                        case 'DE':
                                            activeArray = dualEnrollCourses;
                                            break;
                                        case 'AICE':
                                            activeArray = aiceCourses;
                                            break;
                                        case 'AP':
                                            activeArray = apCourses;
                                            break;
                                        case 'CART':
                                            activeArray = cartCourses;
                                            break;
                                        case 'CSP':
                                            activeArray = cspCourses;
                                            break;
                                        case 'DLPT':
                                            activeArray = dlptCourses;
                                            break;
                                        case 'DSST':
                                            activeArray = dsstCourses;
                                            break;
                                        case 'IB':
                                            activeArray = ibCourses;
                                            break;
                                        case 'Uexcel':
                                            activeArray = uexcelCourses;
                                            break;
                                        case 'CLEP':
                                            activeArray = clepCourses;
                                            break;
                                        case 'Exempt':
                                            activeArray = exemptCourses;
                                            break;
                                        case 'ACTFL':
                                            activeArray = actflCourses;
                                            break;
                                        case 'CAPE':
                                            activeArray = capeCourses;
                                            break;
                                    }

                                    
                                    return(
                                        <TabPanel value={exemptTab} index={i}>
                                            <Paper variant='outlined' style={{ display: 'flex', minHeight: '150px', overflowX: 'scroll', backgroundImage: `url(${mdcDecoBlue})` }}>
                                                {semesters.sort((a, b) => a.index-b.index).map(s => {

                                                        const curSem = s.index + 1;

                                                        var colMdcTerm = classes.exemptColumn;

                                                        var stotal = 0

                                                        return(
                                                            <ExemptColumn
                                                                key={s.id}
                                                                semester={s.id}
                                                                completed={s.completed}
                                                                changeExemptCourses={changeExemptCourses}
                                                                PreReqCheck={PreReqCheck}
                                                                PostReqCheck={PostReqCheck}
                                                                mdcGrad={mdcGrad}
                                                                mdcMaxSemester={mdcMaxSemester}
                                                                exemptType={cs.value}
                                                                setExemptType={setExemptType}
                                                            >
                                                                <div style={colMdcTerm}>
                                                                {
                                                                    activeArray.filter(item => `s${item.TERM}`&& `s${item.TERM}` === s.id).map(item => {
                                                                        //stotal = stotal + item.hours
                                                                        stotal = stotal + parseInt(item.Units);
                                                                        return(
                                                                        <SemesterItem key={item.id} id={item.id} semcomp={s.completed} citem={item} videoClick={()=>videoClick(item)} courseClick={() => courseClick(item, s.completed, true)} delClick={handleManRemove} exemptType={cs.value} />
                                                                    )})
                                                                    }
                                                                </div>
                                                            </ExemptColumn>
                                                        )})}
                                            </Paper>
                                        </TabPanel>

                                    )
                                })}
                            
                        </React.Fragment>}
                    </Grid>
                </Grid>
                <AppBar position="static" style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', backgroundColor: 'rgb(220, 220, 220)', color: '#383838', flexDirection: 'row' }}>
                {/* <AppBar position="static" style={{ backgroundColor: 'rgb(34, 34, 34)', color: 'white', flexDirection: 'row' }}> */}
                    <Tabs style={{ flexGrow: 1 }} indicatorColor="primary" value={value} onChange={handleTabChange} aria-label="Programs">
                        {
                            userPathways.sort((a, b) => a.index-b.index).map((pathway) => {
                                return(
                                    <Tab key={pathway.index} style={{ textTransform: 'none', fontWeight: 'bold' }} label={pathway.name} {...a11yProps(pathway.index)} />
                                )
                            })
                        }
                    </Tabs>
                    {activeProgramCount() > 1 &&
                    <Fab style={{ textTransform: 'capitalize' }} color='secondary' variant='extended' onClick={handleRemoveProgramClick}>
                        Remove Program
                    </Fab>}
                    <Fab style={{ textTransform: 'capitalize' }} onClick={handleAddProgramOpen} variant='extended' >
                       <AddIcon /> Add Program
                    </Fab>
                </AppBar>
                {
                    userPathways.map((pathway) => {
                        return (
                            <TabPanel value={value} index={pathway.index} key={pathway.index}>
                                <Paper variant="outlined" style={{ display: 'flex', overflowX: 'scroll', backgroundImage: `url(${mdcDecoBlue})` }}>
                                    {semesters.sort((a, b) => a.index-b.index).map(s => {

                                        //console.log(s);
                                        var colClass = classes.columnHead;

                                        const curSem = s.index + 1;

                                        var colMdcTerm = classes.column;

                                        //var dualEnrollSem = dualenroll && dualterms >= s.index + 1 ? true : false;

                                        //if(dualEnrollSem){
                                        //    colClass = classes.columnHeadDualE;
                                        //}

                                        if(!mdcGrad && curSem === mdcMaxSemester){
                                            colMdcTerm = classes.columnGrad;
                                        }

                                        if(mdcGrad && mdcGrad === curSem){  
                                            colMdcTerm = classes.columnGrad;
                                        }

                                        const inGroup = semWarn.find(m => m === s.id);

                                        if(parseInt(s.hours)>=18 && !inGroup && !startDialog && !loginOpen && allowWarn){
                                            var tSem = semWarn;
                                            tSem.push(s.id);
                                            setSemWarn(semWarn);
                                            openMaxCreditAlertDialog({ title: 'Maximum Credits per Term Warning', message: <span>You'll need permission in order to take more then 18 credit hours for <b>{handleTermName(s.termdate)}</b>.</span> });
                                        }

                                        var stotal = 0

                                        return(
                                            <SemesterColumn
                                                key={s.id}
                                                semester={s.id}
                                                completed={s.completed}
                                                changeCourses={changeCourses}
                                                changeFiuCourses={changeFiuCourses}
                                                PreReqCheck={PreReqCheck}
                                                PostReqCheck={PostReqCheck}
                                                mdcGrad={mdcGrad}
                                                mdcMaxSemester={mdcMaxSemester}
                                                setExemptType={setExemptType}
                                            >
                                                <div style={colMdcTerm}>
                                                <div style={colClass} ><Typography style={{ textTransform: 'uppercase' }} variant="h5">{handleTermName(s.termdate)} {s.removeable && <IconButton style={{ color: 'red' }} size="small" onClick={() => handleManTermRemove(s)}aria-label='remove term'><DeleteIcon /></IconButton>}</Typography></div>
                                                <div style={colClass}><FormControlLabel control={<Checkbox style={{ color: '#f73378' }} checked={s.completed} onChange={handleSemesterComplete(s)} />} label="Completed" /></div>
                                                <div style={colClass}><Typography variant='h6'>Total: {s.hours} credits</Typography></div>
                                                    {
                                                    courses.filter(item => `s${item.TERM}`&& `s${item.TERM}` === s.id).reverse().map(item => {
                                                        var inDualEnrollment = dualEnrollCourses.find(d => d.id === item.id);
                                                        let inAICE = aiceCourses.find(d => d.id === item.id);
                                                        let inAP = apCourses.find(d => d.id === item.id);
                                                        let inCART = cartCourses.find(d => d.id === item.id);
                                                        let inCSP = cspCourses.find(d => d.id === item.id);
                                                        let inDLPT = dlptCourses.find(d => d.id === item.id);
                                                        let inDSST = dsstCourses.find(d => d.id === item.id);
                                                        let inIB = ibCourses.find(d => d.id === item.id);
                                                        let inUexcel = uexcelCourses.find(d => d.id === item.id);
                                                        let inClep = clepCourses.find(d => d.id === item.id);
                                                        let inExempt = exemptCourses.find(d => d.id === item.id);
                                                        let inActfl = actflCourses.find(d => d.id === item.id);
                                                        let inCape = capeCourses.find(d => d.id === item.id);
                                                        //stotal = stotal + item.hours
                                                        stotal = stotal + parseInt(item.Units);

                                                        if(inDualEnrollment || inAICE || inAP || inCART || inCSP || inDLPT || inDSST || inIB || inUexcel || inExempt || inClep || inActfl || inCape){
                                                            return;
                                                        }
                                                        return(
                                                        //<SemesterItem dualEnroll={dualEnrollSem}  key={item.id} id={item.id} semcomp={s.completed} citem={item} videoClick={()=>videoClick(item)} courseClick={() => courseClick(item, s.completed)} delClick={handleManRemove} />
                                                        <SemesterItem key={item.id} id={item.id} semcomp={s.completed} citem={item} videoClick={()=>videoClick(item)} courseClick={() => courseClick(item, s.completed, false)} delClick={handleManRemove} exemptType={exemptType} exempted={false} />
                                                    )})
                                                    }
                                                    {
                                                        fiucourses.filter(item => `s${item.TERM}`&& `s${item.TERM}` === s.id).reverse().map(item => {
                                                            return (
                                                                <FiuSemesterItem key={item.id} semcomp={s.completed} id={item.id} citem={item} videoClick={()=>videoFiuClick(item)} courseClick={() => courseFiuClick(item, s.completed, false)} delClick={handleManRemove} exemptType={exemptType} exempted={false} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </SemesterColumn>
                                        )})}
                                </Paper>
                            </TabPanel>
                        )
                    })
                }
            </DndProvider>
            </Grid>
            <Grid item xs={1} />
            <VideoDialog open={openVideo} course={selectedCourse} close={handleClose} />
            <ClassDialog open={openCourse} course={selectedCourse} close={handleClose} courses={courses} setCourses={setCourses} basePathway={basePathway} postReqLoop={postReqLoop} completed={selectedCourseCompleted} exempted={selectedCourseExempt} semesters={semesters} removedCourses={removedCourses} setRemovedCourses={setRemovedCourses} startDate={startdate} />
            <FiuVideoDialog open={openFiuVideo} course={selectedCourse} close={handleFiuClose} />
            <FiuClassDialog open={openFiuCourse} course={selectedCourse} close={handleFiuClose} courses={fiucourses} setCourses={setFiuCourses} basePathway={basePathway} completed={selectedCourseCompleted} />
            <AddManualClass handleTermName={handleTermName} open={openManualAdd} close={handleManClose} addManualCourse={handleManAdd} semesters={semesters} mdcGrad={mdcGrad} mdcMaxSemester={mdcMaxSemester} />
            <LoginDialog open={startDialog} 
                handleDone={handleInitDialog} 
                handleProgram={handleProgram} 
                handleOpenLogin={handleMuiLoginOpen} 
                changeDualEnroll={changeDualEnroll} 
                changeDualTerms={changeDualTerms} 
                dualenroll={dualenroll} 
                dualterms={dualterms} 
                floridares={floridares} 
                changeFloridaRes={changeFloridaRes} 
                foreignlangexempt={foreignlangexempt} 
                changeForeignLangExempt={changeForeignLangExempt} 
                fiupathway={fiupathway} 
                setFiuPathway={setFiuPathway} 
                test={test} 
                setTest={setTest} 
                writingScore={writingScore} 
                setWritingScore={setWritingScore} 
                mathScore={mathScore} 
                setMathScore={setMathScore} 
                testExempt={testExempt} 
                setTestExempt={setTestExempt}
                mdcCourses={courses}
                fiuCourses={fiucourses}
                semesters={semesters}
                userPathways={userPathways}
                readingScore={readingScore}
                setReadingScore={setReadingScore}
                aafScore={aafScore}
                setAafScore={setAafScore}
                testArray={testArray}
                setTestArray={setTestArray}
                fiupathways={partnerPathways}
                />
            <MuiLogin open={loginOpen} close={handleMuiLoginClose} />
            <RemoveProgramDialog openRemoveProgram={openRemoveProgram} userPathways={userPathways} closeRemoveProgram={handleRemoveProgramClose} currentUser={currentUser} setUserPathways={setUserPathways} currentPathway={currentPathway} setCurrentPathway={setCurrentPathway} setValue={setValue} />
            <LegendDialog open={showlegend} closeCallback={handleCloseLegend} />
            <GradDialog openMdcGrad={openMdcGrad} semesters={semesters} mdcMaxTerm={mdcMaxSemester} setMDCGrad={setMDCGrad} mdcGrad={mdcGrad} closeMdcGradCallback={handleMdcGradClose} />
            <ProgramDialog openAddProgram={openAddProgram} closeProgramCallback={handleAddProgramClose} addProgram={handleAddProgram} partnerPathways={partnerPathways} />
            <ExemptDialog openAddSavings={openAddSavings} closeSavingsCallback={handleAddSavingsClose} addSavings={handleAddSavings} activeCostSavings={activeCostSavings}  />
            <ResetDialog open={showReset} closeCallback={handleCloseReset} />
        </Grid>
    );
}


export default CourseMatrix;