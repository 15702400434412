import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import VideoIcon from '@material-ui/icons/PlayArrow';

const LegendDialog = (props) => {

    const { open, closeCallback } = props;

    const getColor = (ind) => {
        var colorhex = '#d3d3d3';

        switch (ind){
            case 1:
                colorhex = '#FC2C7E';
                break;
            case 2:
                colorhex = '#2CEAFC';
                break;
            case 3:
                colorhex = '#2CFC52';
                break;
            case 4: 
                colorhex = '#E732E7';
                break;
            case 5:
                colorhex = '#fff701';
                break;
            case 6:
                colorhex = '#970097';
                break;
            default:
                colorhex = '#d3d3d3';
        }
     
        return colorhex;
    }

    return(
        <Dialog open={open} onClose={closeCallback}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <Typography variant='h6' style={{ fontWeight: 'bold', textAlign: 'center' }}>Legend</Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <IconButton><VideoIcon /></IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Paper variant='outlined' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid container justify='center' alignItems='center'>
                        <Grid item xs={2} />
                        <Grid container item justify='center' xs={8}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography variant='h6'>
                                <Chip style={{ backgroundColor: getColor(1), color: 'lightgray', marginBottom: '10px', width: '100px', border: '2px solid darkslategrey' }} /> - Science
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography variant='h6'>
                                <Chip style={{ backgroundColor: getColor(2), color: 'lightgray', marginBottom: '10px', width: '100px', border: '2px solid darkslategrey' }} /> - Math
                            </Typography>
                            {/* <Chip label={`Required Math`} style={{ backgroundColor: getColor(2), color: 'darkgray', marginBottom: '10px' }} /> */}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography variant='h6'>
                                <Chip style={{ backgroundColor: getColor(3), color: 'lightgray', marginBottom: '10px', width: '100px', border: '2px solid darkslategrey' }} /> - English {/* Graduation */}
                            </Typography>
                            {/* <Chip label={`Required Graduation`} style={{ backgroundColor: getColor(3), color: 'darkgray', marginBottom: '10px' }} /> */}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography variant='h6'>
                                <Chip style={{ backgroundColor: getColor(4), color: 'lightgray', marginBottom: '10px', width: '100px', border: '2px solid darkslategrey' }} /> - Course Selection Available
                            </Typography>
                            {/* <Chip label={`Course Selection Available`} style={{ backgroundColor: getColor(4), color: 'lightgray', marginBottom: '10px' }} /> */}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography variant='h6'>
                                <Chip style={{ backgroundColor: getColor(5), color: 'lightgray', marginBottom: '10px', width: '100px', border: '2px solid darkslategrey' }} /> - Transferable Course
                            </Typography>
                            {/* <Chip label={`Transferable Course`} style={{ backgroundColor: getColor(5), color: 'darkgray', marginBottom: '10px' }} /> */}
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Typography variant='h6'>
                                <Chip style={{ backgroundColor: getColor(6), color: 'lightgray', marginBottom: '10px', width: '100px', border: '2px solid darkslategrey' }} /> - Transfer Institution
                            </Typography>
                            {/* <Chip label={`Transfer Institution`} style={{ backgroundColor: getColor(6), color: 'lightgray', marginBottom: '10px' }} /> */}
                        </Grid>
                        
                        </Grid>
                        <Grid item xs={2} />

                        {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Chip label={`Per term Math Recommended`} style={{ backgroundColor: '#2CEAFC', color: 'black', marginBottom: '10px' }} />        
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Chip label={`Per term Flexible`} style={{ backgroundColor: '#2CFC52', color: 'black', marginBottom: '10px' }} />        
                        </Grid> */}
                    </Grid>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <Button color='secondary' onClick={closeCallback} aria-label='close'>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )

}

export default LegendDialog;