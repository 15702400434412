import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
/* import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info'; */

/* const variantIcon = {
    success: SuccessIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}; */

let openMuiSnackbarFn;

const MuiSnackbar = (props) => {
    const [open, setOpen] = React.useState(false);
    const [variant, setVariant] = React.useState('info');
    const [message, setMessage] = React.useState('');

    React.useEffect(() => {
        openMuiSnackbarFn = openMuiSnackbar;
    }, []);

    const openMuiSnackbar = ({ message, variant }) => {
        setOpen(true);
        setMessage(message);
        setVariant(variant);
    }

    const handleClose = (event, reason) => {
        if(reason === 'clickaway'){
            return;
        }
        setOpen(false);
        setMessage('');
        setVariant('info');
    }

    return(
        <Snackbar 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} elevation={6} variant='filled' severity={variant}>{message}</Alert>
        </Snackbar>
    );
}

export function openMuiSnackbar({ message, variant }){
    openMuiSnackbarFn({ message, variant });
}

export default MuiSnackbar;