import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const MuiHeader = (props) => {

    const { openProfileFunc, currentUser, setLoginOpen } = props;

    const auth = currentUser !== null && currentUser !== '' ? true : false;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const history = useHistory();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleProfileClick = () => {
        openProfileFunc();
        setAnchorEl(null);
    }

    const handleLoginClick = () => {
        setLoginOpen(true);
    }

    const handleLogout = async () => {
        await axios({
            method: 'get',
            withCredentials: true,
            url: '/logout'
          }).then((res) => {
              //console.log(res);
          });
          history.go(0);
          handleClose();
    }


    return(
        <AppBar position="static" style={{ backgroundColor: '#222' }}>
            <Toolbar>
            <img src="https://www.mdc.edu/college_wide/template14/img/mdc-logo-inverse.png" alt="Miami Dade College" style={{ width: '165px', height: '45px', margin: '0px' }} />
            <Typography variant="h1" style={{marginLeft: '20px', flexGrow: '1', textAlign: 'left', fontSize: '1.5em' }}>
                Interactive My Academic Planner
            </Typography>
            {auth && (
                <div>
                    <IconButton
                        aria-label='account of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        color='inherit'
                        onClick={handleMenu}
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                      id='menu-appbar'
                      anchorEl={anchorEl}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                        <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            )}
            {!auth && (
                <Button color='inherit' onClick={handleLoginClick} >Login</Button>
            )}            
            </Toolbar>
        </AppBar>
    )

}

export default MuiHeader;