import React from 'react';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slider from '@material-ui/core/Slider';
import VideoPlayerDialog from './VideoPlayerDialog';
import { openMuiSnackbar } from '../components/MuiSnackbar';

import InfoIcon from '@material-ui/icons/Info';
import VideoIcon from '@material-ui/icons/PlayCircleFilled';

import MuiQuestions from './mui-questions';
import CodeDialog from './CodeDialog';


const registerUser = async (newUser, setOpen2fa) => {

    var mdcArray = newUser.mdcCourseList;
    var fiuArray = newUser.fiuCourseList;

    Array.prototype.push.apply(mdcArray, fiuArray);

    const regUserRes = await axios({
        method: 'post',
        data: {
            fullName: newUser.fullName,
            email: newUser.email,
            password: newUser.password,
            userProfile: newUser.profile,
            courseList: mdcArray,
            semesters: newUser.semesters
        },
        withCredentials: true,
        url: '/register'
    });

    if(regUserRes.data === 'User Created'){
        const loginUser = await axios({
            method: 'post',
            data: {
                username: newUser.email,
                password: newUser.password
            },
            withCredentials: true,
            url: '/userlogin'
        });

        setOpen2fa(true);

        //openMuiSnackbar({ message: 'User Created', variant: 'success' });
        //return true;
    }
    if(regUserRes.data === 'User Already Exists'){
        openMuiSnackbar({ message: 'Email already exist in system. Please pick another', variant: 'error' });
        return false;
    }
}


const getSteps = () => {
    return ['Welcome','Pathway', 'Testing', 'Additional Information', 'Setup Account']
}

const getInfos = () => {
    return ['', 'Information on Pathway', 'Testing Information', 'Dual Enrollment Info', 'Info on setting up an account']
}

const getInfoLinks = () => {
    return ['', 'https://www.mdc.edu/academics/programs/associate.aspx', '','', ''];
}

const WelcomeStep = (handleNext, handleLoginClick) => {

    return (
        <Grid container alignItems="center" justify="center">
            <Typography style={{ display: 'none' }} variant='h4'>Welcome</Typography>
            <Grid item xs={2} />
            <Grid item xs={8} style={{ textAlign: 'center' }}>
                <Typography variant="body1">
                    Welcome to the Miami Dade College Interactive My Academic Planner (iMAP), a seamless transfer tool at Miami Dade College! We're excited to help you with your program planning process.  Select a button below to get started!
                </Typography>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={4} />
            <Grid item xs={4} style={{ textAlign: 'center', marginTop: '15px' }}>
                <Button variant="contained" color="primary" onClick={handleNext} aria-label='guest'>
                    Guest
                </Button>
                {/* <TextField label='Username' /> */}
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} />
            <Grid style={{ textAlign: 'center', marginTop: '15px' }} item xs={4}>
                {/* <TextField label='Password' type='password' /> */}
                <Button variant="contained" color="primary" onClick={handleNext} aria-label='first time user'>
                    First Time User
                </Button>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} />
            <Grid item xs={4} style={{ textAlign: 'center', marginTop: '15px', marginBottom: '15px' }}>
                <Button onClick={handleLoginClick} variant="contained" color="primary" aria-label='returning user'>
                    Returning User
                </Button>
            </Grid>
            <Grid item xs={4} />
        </Grid>
    );
}

const PathwayStep = (pathway, setPathway, fiupathway, setFiuPathway, partner, setPartner, initterm, setInitTerm, inityear, setInitYear, fiupathways) => {

    
    const pathways = [
        {
            value: 'aa',
            label: 'Associate of Arts'
        },
    ];

    /* const fiupathways = [
        {
            value: 'BIO_BA_DATA',
            label: 'Bachelor of Arts Biology'
        },
        {
            value: 'BIO_BS_DATA',
            label: 'Bachelor of Science Biology'
        },
        {
            value: 'FIU_BS_BIOMEDICAL-ENGINEERING',
            label: 'Bachelor of Science Biomedical Engineering'
        },
        {
            value: 'FIU_BA_CHEMISTRY',
            label: 'Bachelor of Arts Chemistry'
        },
        {
            value: 'FIU_BS_CHEMISTRY',
            label: 'Bachelor of Science Chemistry'
        }
    ] */

    /* const fiupathways = getPathways(); */

    const partnerinst = [
        {
            value: 'fiu',
            label: 'Florida International University'
        }
    ]

    const termlist = [
        {
            value: 8,
            label: 'Fall',
        },
        {
            value: 0,
            label: 'Spring',
        },
        {
            value: 4,
            label: 'Summer',
        }
    ]

    const yearlist = () => {

        var d = new Date();
        var year = d.getFullYear() - 4;
        var narray = [];

        for(var i=0; i < 9; i++){
            narray.push(year + i);
        }

        return narray; 
    }


    
    

const handlePathwayChange = (event) => {
    setPathway(event.target.value);
} 

const handlePartnerChange = (event) => {
    setPartner(event.target.value);
}

const handleFiuPathwayChange = (event) => {
    setFiuPathway(event.target.value);
}

const handleTermChange = (event) => {
    setInitTerm(event.target.value);
}

const handleYearChange = (event) => {
    setInitYear(event.target.value);
}


    return (
        <Grid container alignItems="center" justify="center" style={{ marginBottom: '15px' }}>
            <Grid item xs={12}>
                <Typography style={{ display: 'none' }} variant='h4'>Pathway</Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                <Typography variant='body1'>Miami Dade College Pathways:</Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                <Typography variant='body1'>Partner Institution:</Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                <Typography variant='body1'>Partner Degree Program:</Typography>
            </Grid>
            <Grid item xs={4} style={{textAlign: 'center' }}>
                <TextField 
                    select
                    /* label="Pathway" */
                    value={pathway}
                    onChange={handlePathwayChange}
                    style={{ width: '200px', textAlign: 'left' }}
                    id='Select Pathway'
                >
                    {pathways.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                <TextField 
                        select
                        /* label="Partner" */
                        value={partner}
                        onChange={handlePartnerChange}
                    >
                        {partnerinst.map((opt) => (
                            <MenuItem key={opt.value} value={opt.value}>
                                {opt.label}
                            </MenuItem>
                        ))}
                    </TextField>                
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                <TextField 
                        select
                        /* label="Partner Pathway" */
                        value={fiupathway}
                        onChange={handleFiuPathwayChange}
                    >
                        {fiupathways.map((opt) => (
                            <MenuItem key={opt.value} value={opt.value}>
                                {opt.label}
                            </MenuItem>
                        ))}
                    </TextField>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Tooltip title='Miami Dade College Pathways Information'>
                    <IconButton target='_blank' href='https://www.mdc.edu/academics/programs/associate.aspx' aria-label='miami dade college pathways information'><InfoIcon /></IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center', marginTop: '15px' }}>
                <Typography variant='body1'>
                    Start Term
                </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center', marginTop: '15px' }}>
                <Typography variant='body1'>
                    Start Year
                </Typography>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Tooltip title='Partner Degree and Program Information'>
                    <IconButton target='_blank' href='https://www.fiu.edu/academics/degrees-and-programs/index.html' aria-label='partner degree and program information'><InfoIcon /></IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                {/* <IconButton target='_blank' href='https://www.mdc.edu/academics/programs/associate.aspx'><InfoIcon /></IconButton> */}
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                <TextField 
                    select
                    value={initterm}
                    onChange={handleTermChange}
                >
                    {termlist.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </TextField>                
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                <TextField 
                    select
                    value={inityear}
                    onChange={handleYearChange}
                >
                    {yearlist().map((opt) => (
                        <MenuItem key={opt} value={opt}>
                            {opt}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'center' }}>
                {/* <IconButton target='_blank' href='https://www.fiu.edu/academics/degrees-and-programs/index.html'><InfoIcon /></IconButton> */}
            </Grid>
        </Grid>
    );
}

const TestingStep = (test, handleTestChange, writingScore, handleWScoreChange, mathScore, handleMScoreChange, testExempt, handleTestExempt, readingScore, handleRScoreChange, testArray, setTestArray, aafScore, handleAafScoreChange, handleModTest) => {

    const handleAddToTestArray = (testToAdd) => {
        let newTestArray = [];
        testArray.map((a) => newTestArray.push(a));
        newTestArray.push(testToAdd);
        setTestArray(newTestArray);
        handleModTest('None');
    }

    const handleRemoveFromTestArray = (testToRemove) => {
        let newArr = testArray.filter((t) => t.Test !== testToRemove.Test);
        setTestArray(newArr);
    }

    return (<React.Fragment>
                <Typography variant='h3' style={{ display: 'none' }}>Testing</Typography>
                <MuiQuestions 
                    open={true} 
                    test={test} 
                    handleTestChange={handleTestChange} 
                    writingScore={writingScore} 
                    handleWScoreChange={handleWScoreChange} 
                    handleMScoreChange={handleMScoreChange} 
                    mathScore={mathScore} exempt={testExempt} 
                    handleExempt={handleTestExempt} 
                    readingScore={readingScore} 
                    handleRScoreChange={handleRScoreChange} 
                    testArray={testArray} 
                    aafScore={aafScore} 
                    handleAafScoreChange={handleAafScoreChange}
                    handleAddToTestArray={handleAddToTestArray}
                    handleRemoveFromTestArray={handleRemoveFromTestArray}
                     />
            </React.Fragment>)
}

const DualEnrollment = (dualenroll, changeDualEnroll, dualterms, changeDualTerms, floridares, changeFloridaRes, foreignlangexempt, changeForeignLangExempt) => {

    const handleSwitchChange = (event) => {
        changeDualEnroll(event.target.checked);
    }

    const handleDualTerms = (event, newValue) => {
        changeDualTerms(newValue);
    }

    const handleSwitchResidency = (event) => {
        changeFloridaRes(event.target.checked);
    }

    const handleForeignLang = (event) => {
        changeForeignLangExempt(event.target.checked);
    }


    return (
        <Grid container alignItems="center" justify='center' spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h3' style={{ display: 'none' }}>Additional Information</Typography>
            </Grid>
            <Grid item container xs={4} style={{ marginBottom: '10px' }}>
                <Paper style={{ padding: '20px', width: '100%' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                            Florida Residency
                        </Typography>
                        <FormControlLabel 
                            control={<Switch color='primary' checked={floridares} onChange={handleSwitchResidency} name='Florida Resident' />}
                            label={floridares ? 'Florida Resident' : 'Non-Florida Resident'}
                        />
                        <Button color='primary' target='_blank' href='https://www.mdc.edu/admissions/tuition/florida-residency.aspx' aria-label='more info'>
                            More Info
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item container xs={4}>
                <Paper style={{ padding: '20px', width: '100%' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                            Dual Enrollment
                        </Typography>
                        <FormControlLabel 
                            control={<Switch color='primary' checked={dualenroll} onChange={handleSwitchChange} name='Dual Enrollment' />}
                            label={dualenroll ? 'Yes' : 'No'}
                        /><br />
                        <Button color='primary' target='_blank' href='https://www.mdc.edu/future-students/high-school/dual-enrollment.aspx' aria-label='more info'>
                            More Info
                        </Button>
                    </Grid>
                    {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ marginBottom: '40px' }}>
                            How many terms?
                        </Typography>
                        <Slider 
                            defaultValue={1}
                            step={1}
                            valueLabelDisplay='on'
                            marks
                            min={1}
                            max={4}
                            disabled={!dualenroll}
                            value={dualterms}
                            onChange={handleDualTerms}
                        />
                        <Button color='primary' target='_blank' href='https://www.mdc.edu/future-students/high-school/dual-enrollment.aspx' aria-label='dual enrollment more information'>
                            More Info
                        </Button>
                    </Grid> */}
                </Paper>
            </Grid>
            <Grid item container xs={4}>
                <Paper style={{ padding: '20px', width: '100%' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                            Foreign Language Competence and Equivalence
                        </Typography>
                        <FormControlLabel 
                            control={<Switch color='primary' checked={foreignlangexempt} onChange={handleForeignLang} />}
                            label={foreignlangexempt ? 'Yes' : 'No' }
                        />
                        <Button color='primary' target='_blank' href='https://www.mdc.edu/main/testing/criteria/foreign_language_competence.aspx#:~:text=Foreign%20Language%20Equivalence,the%20Foreign%20Language%20Competence%20requirement' aria-label='foreign language exemption more information'>
                            More Info
                        </Button>
                    </Grid>
                </Paper>
            </Grid>            
        </Grid>
    );
}

const AccountStep = (user, setUser) => {

    const changeUser = (event, name) => {
        setUser({ ...user, [name]: event.target.value });
    }


    return (
        <Grid container alignItems="center" justify="center">
            <Grid item xs={12}>
                <Typography variant='h3' style={{ display: 'none' }}>Setup Account</Typography>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={8} style={{ textAlign: 'center' }}>
                <Typography variant="body1">
                   Setup an Account for use later!
                </Typography>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={4} />
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                <TextField value={user.fullName} label='Name' onChange={(e) => changeUser(e, 'fullName')} id='name' />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} />
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                <TextField value={user.email} label='Email' onChange={(e) => changeUser(e, 'email')} id='email' />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4} />
            <Grid style={{ textAlign: 'center' }} item xs={4}>
                <TextField value={user.password} label='Password' type='password' onChange={(e) => changeUser(e, 'password')} id='password' />
            </Grid>
            <Grid item xs={4} />
        </Grid>
    );
}

const getStepContent = (step, pathway, setPathway, handleNext, fiupathway, setFiuPathway, partner, setPartner, initterm, setInitTerm, inityear, setInitYear, handleLoginClick, dualenroll, changeDualEnroll,  dualterms, changeDualTerms, floridares, changeFloridaRes, foreignlangexempt, changeForeignLangExempt, user, setUser, test, handleTestChange, writingScore, handleWScoreChange, mathScore, handleMScoreChange, testExempt, handleTestExempt, readingScore, handleRScoreChange, testArray, setTestArray, aafScore, handleAafScoreChange, handleModTest, fiupathways) => {
    switch(step){
        case 0:
            return WelcomeStep(handleNext, handleLoginClick);
        case 1:
            return PathwayStep(pathway, setPathway, fiupathway, setFiuPathway, partner, setPartner, initterm, setInitTerm, inityear, setInitYear, fiupathways);
        case 2: 
            return TestingStep(test, handleTestChange, writingScore, handleWScoreChange, mathScore, handleMScoreChange, testExempt, handleTestExempt, readingScore, handleRScoreChange, testArray, setTestArray, aafScore, handleAafScoreChange, handleModTest);
        case 3:
            return DualEnrollment(dualenroll, changeDualEnroll, dualterms, changeDualTerms, floridares, changeFloridaRes, foreignlangexempt, changeForeignLangExempt);
        case 4:
            return AccountStep(user, setUser);
        default:
            return 'Unknown Step';
    }
};
 
const LoginDialog = (props) => {

    const d = new Date();

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [pathway, setPathway] = React.useState('aa');
    
    const [partner, setPartner] = React.useState('fiu');
    /* const [test, setTest] = React.useState('ACT');
    const [writingScore, setWritingScore] = React.useState(0);
    const [mathScore, setMathScore] = React.useState(0);
    const [testExempt, setTestExempt] = React.useState(false); */
    const [initterm, setInitTerm] = React.useState(8);
    const [inityear, setInitYear] = React.useState(d.getFullYear());
    const [user, setUser] = React.useState({
        fullName: '',
        email: '',
        password: ''
    });
    const [userSkip, setUserSkip] = React.useState(false);
    const [videoOpen, setVideoOpen] = React.useState(false);
    const [videoTitle, setVideoTitle] = React.useState('');
    const [videoUrl, setVideoUrl] = React.useState('');
    const [open2fa, setOpen2fa] = React.useState(false);

    const steps = getSteps();
    const infos = getInfos();
    const infoLinks = getInfoLinks();


    const { open, fiupathway, setFiuPathway, handleDone, handleProgram, handleOpenLogin, changeDualEnroll, changeDualTerms, dualenroll, dualterms, floridares, changeFloridaRes, foreignlangexempt, changeForeignLangExempt, test, setTest, writingScore, setWritingScore, mathScore, setMathScore, testExempt, setTestExempt, mdcCourses, fiuCourses, semesters, userPathways, readingScore, setReadingScore, testArray, setTestArray, aafScore, setAafScore, fiupathways } = props;

    const handleVideoOpen = (title, videoUrl) => {
        setVideoTitle(title);
        setVideoUrl(videoUrl);
        setVideoOpen(true);
    }

    const handleVideoClose = () => {
        setVideoOpen(false);
        setVideoTitle('');
        setVideoUrl('');
    }

    const handleTestExempt = (event) => {
        setTestExempt(event.target.checked);
        if(event.target.checked){
            setTest('None');
            setWritingScore(0);
            setMathScore(0);
            setReadingScore(0);
            setAafScore(0);
        } else {
            setTest('ACT');
            setWritingScore(0);
            setMathScore(0);
            setReadingScore(0);
            setAafScore(0);
        }
    }

    const handleTestChange = (event) => {
        if(!testExempt){
            setTest(event.target.value);
            setWritingScore(0);
            setMathScore(0);
            setReadingScore(0);
            setAafScore(0);
        };
    }

    const handleModTest = (test) => {
        if(!testExempt){
            setTest(test);
            setWritingScore(0);
            setMathScore(0);
            setReadingScore(0);
            setAafScore(0);
        };
    }

    const handleWScoreChange = (event) => {
        setWritingScore(event.target.value);
    }

    const handleMScoreChange = (event) => {        
        setMathScore(event.target.value);
    }

    const handleRScoreChange = (event) => {
        setReadingScore(event.target.value);
    }

    const handleAafScoreChange = (event) => {
        setAafScore(event.target.value);
    }

    const isStepOptional = (step) => {
        return step === 4;
    }

    const isStepSkipped = (step) => {
        return skipped.has(step);
    }

    const handleLoginClick = () => {
        handleOpenLogin();
    }

    const checkEmail = (email) => {
        //eslint-disable-next-line
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            return true;
        } else {
            return false;
        }
    }

    const handleNext = async () => {
        if(activeStep === 2){
            if((test !== 'None') && (parseInt(writingScore) < 1 || parseInt(mathScore) < 1)){
                openMuiSnackbar({ message: 'Invalid Test Score', variant: 'error' });
                return;
            }
        }
        if(activeStep === 4){
            var emailValid = checkEmail(user.email);
            const newUser = {
                fullName: user.fullName,
                email: user.email,
                password: user.password,
                profile: {
                    testTaken: test,
                    testsTaken: testArray,
                    writingScore: writingScore,
                    mathScore: mathScore,
                    readingScore: readingScore,
                    aafScore: aafScore,
                    testExempt: testExempt,
                    foreignLangExempt: foreignlangexempt,
                    floridaResidency: floridares,
                    dualEnrollment: dualenroll,
                    dualTerms: dualterms,
                    startTerm: initterm,
                    startYear: inityear
                },
                mdcCourseList: mdcCourses,
                fiuCourseList: fiuCourses,
                semesters: semesters,
                userPathways: userPathways
            }
            if(!emailValid){
                openMuiSnackbar({ message: 'Not a valid email address', variant: 'error' });
                return;
            }
            if(user.fullName.trim().length < 1){
                openMuiSnackbar({ message: 'Name is required', variant: 'error' });
                return;
            }
            if(user.password.trim().length<6){
                openMuiSnackbar({ message: 'Password needs to be at least 6 characters', variant: 'error' });
                return;
            }

            var regUser = await registerUser(newUser, setOpen2fa);
            //console.log(regUser);
            if(!regUser){
                return;
            }
        }

        let newSkipped = skipped;
        if (isStepSkipped(activeStep)){
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if(activeStep === 1){
            handleProgram(initterm, inityear);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error('You can\'t skip a step that isn\'t optional');
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
        setUserSkip(true);
    }

/*     const handleReset = () => {
        setActiveStep(0);
    } */

    const handleClose = () => {
        handleDone();
    }

    React.useEffect(() => {
        handleProgram(initterm, inityear);
    }, [initterm, inityear])

    React.useEffect(() => {
        handleProgram(initterm, inityear);
    }, []);

    const handleDualFactorClose = () => {
        setOpen2fa(false);
        axios({
            method: 'get',
            withCredentials: true,
            url: '/getuser'
        }).then((res) => {
            //console.log(res.data);
        });
        
        openMuiSnackbar({ message: 'Welcome to Miami Dade College iMAP!', variant: 'success' });
        handleClose();
    }

    const handleVideoStep = () => {
        switch(activeStep){
            case 1:
                handleVideoOpen('iMAP Pathways', 'https://www.youtube.com/embed/EMkZwIKdpLc')
                break;
            case 2:
                handleVideoOpen('iMap Testing', 'https://www.youtube.com/embed/RL74qCmkUio')
                break;
            case 3:
                handleVideoOpen('iMap Additional Information', 'https://www.youtube.com/embed/sI537q6avn8')
                break;
            case 4:
                handleVideoOpen('iMAP Start Planning', 'https://www.youtube.com/embed/Zgqw1jeA9pE')
                break;
            case 5:
                handleVideoOpen('iMAP Start Planning', 'https://www.youtube.com/embed/Zgqw1jeA9pE')
                break;
            default:
                handleVideoOpen('Welcome', 'https://www.youtube.com/embed/uzEQ3MeNdzg')
        }
    }

    return(
        <React.Fragment>
        <Dialog open={open} maxWidth='md'>
            <DialogTitle disableTypography>
                <Grid container justify='space-between'>
                    <Grid item />
                    <Grid item>
                        <Typography variant='h3' style={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
                            Welcome to the Interactive My Academic Planner (iMAP)
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title='Informational Video' aria-label='informational video'>
                            <IconButton aria-label='informational video' onClick={() => handleVideoStep()}>
                                <VideoIcon />
                            </IconButton>
                        </Tooltip>     
                    </Grid>
                </Grid>                
            </DialogTitle>
            <DialogContent>
                {activeStep === 0 &&
                <Typography variant='body1'>
                    Let's get started with just a few steps!
                </Typography>}
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if(isStepOptional(index)){
                            labelProps.optional = <Typography variant="caption">Optional</Typography>
                        }
                        if(isStepSkipped(index)){
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel aria-label={`Step ${index + 1} ${label}`} {...labelProps}>{label}</StepLabel>
                            </Step>
                        )
                    })}                    
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <Grid container alignItems="center" style={{ textAlign: 'center' }}>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    All steps completed - you&apos;re finished
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={handleClose} aria-label='start planning'>
                                    Start Planning!
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <div>
                            {getStepContent(activeStep, pathway, setPathway, handleNext, fiupathway, setFiuPathway, partner, setPartner, initterm, setInitTerm, inityear, setInitYear, handleLoginClick, dualenroll, changeDualEnroll,  dualterms, changeDualTerms, floridares, changeFloridaRes, foreignlangexempt, changeForeignLangExempt, user, setUser, test, handleTestChange, writingScore, handleWScoreChange, mathScore, handleMScoreChange, testExempt, handleTestExempt, readingScore, handleRScoreChange, testArray, setTestArray, aafScore, handleAafScoreChange, handleModTest, fiupathways)}
                            {activeStep !== 0 ? (
                            <Grid container justify='space-between'>
                                <Grid item>
                            <div>
                                <Button disabled={activeStep === 0} onClick={handleBack} aria-label='back'>
                                    Back
                                </Button>
                                {isStepOptional(activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSkip}
                                        aria-label='skip'
                                    >
                                        Skip
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    aria-label={activeStep === steps.length - 1 ? 'finish' : 'next'}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                                </div></Grid>{/* <Grid item>{activeStep === 1 ? <Tooltip title={infos[activeStep]} aria-label={infos[activeStep]}><IconButton target='_blank' href={infoLinks[activeStep]}><InfoIcon /></IconButton></Tooltip> : ''}</Grid> */}</Grid>) : (<div />)}
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
        <VideoPlayerDialog open={videoOpen} close={handleVideoClose} videoUrl={videoUrl} width='1120' height='630' />
        <CodeDialog open={open2fa} username={user.email} password={user.password} handleClose={handleDualFactorClose} />
        </React.Fragment>
    )


}

export default LoginDialog;