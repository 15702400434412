import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { Redirect, useHistory } from 'react-router-dom';

const PasswordProtect = (props) => {

    const { handleAuthValid, handleAuthInvalid } = props;
    const [pass, setPass] = React.useState('');
    const [redirect, setRedirect] = React.useState(false);
    const history = useHistory();

    const changePass = (event) => {
        setPass(event.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/login', {pass: pass}).then(res => {
            if(res.status === 200){
                history.push('/');
            } else {
                const error = new Error(res.error);
                throw error;
            }
        }).catch(err => {
            console.log(err);
            alert('Error logging in please try again');
        });
    }
    
    return (
        <Grid container justify='center' alignContent='center'>
            {redirect ? <Redirect to='/' />: 
            <form onSubmit={handleSubmit}>
            <Grid item xs={12} style={{ marginTop: '200px' }}>
                <TextField 
                    name='password'
                    type='password'
                    label='Password'
                    value={pass}
                    onChange={changePass}
                />
            </Grid>
            <Grid item xs={12}>
                <Button type='submit' color='primary'>
                    Verify
                </Button>
            </Grid>
            </form>}
        </Grid>
    )
};

export default PasswordProtect;