import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const DEFAULT_COLOR = '#040404';

const useStyle = makeStyles({
    radialchart: {
        position: 'relative',
        display: 'inline-block',
        transition: 'all 0.3s ease-in'
    },
    radialnoprogress: {
        opacity: 0,
    },
    radialcharttotal: {
        opacity: 0.3,
        fill: 'none'
    },
    radialchartprogress: {
        transform: 'rotate(180deg)',
        transformOrigin: 'center',
        transition: 'all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14)',
        transitionDelay: '0.3s',
    },
    svgcircletext: {
        fontSize: '2rem',
        textAnchor: 'middle',
        marginLeft: '-5px',
        /* color: 'white' */
        fill: '#ccc'
    },
});

const MuiRadialChart = (props) => {
   const [strokeLength, setStrokeLength] = React.useState(0);
    const {
        radius,
        progress,
        strokeWidth,
        dimension,
        color
    } = props;

    const circleRadius = Math.min(radius, 85);
    const circumference = 2 * Math.PI * circleRadius;
    const classes = useStyle();
    const center = dimension/2;
    const circleRef = React.useRef(null);

    React.useEffect(() => {
        setStrokeLength(circumference / 100 * progress);
        /* circleRef.current.style = 'transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14)' */
    }, [progress, circumference]);

    return(
        <div className={classes.radialchart}>
            <svg width={dimension} height={dimension}>
                <circle 
                    className={strokeLength === 0 ? `${classes.radialcharttotal} ${classes.radialnoprogress}` : `${classes.radialcharttotal}`}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    fill="none"
                    cx="90"
                    cy="90"
                    r={circleRadius}
                />
                <circle 
                    className={classes.radialchartprogress}
                    ref={circleRef}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${strokeLength}, ${circumference}`}
                    strokeLinecap="round"
                    cx="90"
                    cy="90"
                    fill="none"
                    r={circleRadius}
                />
                <text style={{ fontSize: '2rem', textAnchor: 'middle', marginLeft: '-5px', fill: '#ccc' }} x={center} y={center}>
                    {progress}%
                </text>
            </svg>
        </div>
    )
   
}

MuiRadialChart.defaultProps = {
    radius: 60,
    progress: 0,
    strokeWidth: 10,
    dimension: 180,
    color: DEFAULT_COLOR
}

export default MuiRadialChart;