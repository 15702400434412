import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import muiSiteTheme from './theme/muiSiteTheme';
import Authorized from './components/Authorized';
import ReactGA from 'react-ga';

import Index from './pages/index';
import PasswordProtect from './components/password';
import PrivateRoute from './components/privateroute';
import PasswordReset from './pages/PasswordReset';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import GenerateTempPDF from './components/temppdf';

function App() {

  ReactGA.initialize('UA-114935899-6');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <MuiThemeProvider theme={muiSiteTheme}>
    <div className="App">
      <Router>
        <Switch>
          <Route path="/admin-login">
            <LoginPage />
          </Route>
          <Route path='/login'>
            <PasswordProtect />
          </Route>
          <Route exact path='/pdf'>
            <GenerateTempPDF />
          </Route>
          <Route exact path='/reset-password/:token'>
            <PasswordReset />
          </Route>
          <Authorized exact path='/admin' component={AdminPage} />
          <Route path='/'>
            {/* <Index /> */}
            <PrivateRoute component={<Index />} />
          </Route>          
        </Switch>
      </Router>
    </div>
    </MuiThemeProvider>
  );
}

export default App;
