import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const ResetDialog = (props) => {

    const { open, closeCallback } = props;

    const [fiuPathway, setFiuPathway] = React.useState({ value: 'BIO_BA_DATA', label: 'Bachelor of Arts Biology'});

    const fiupathways = [
        {
            value: 'BIO_BA_DATA',
            label: 'Bachelor of Arts Biology'
        },
        {
            value: 'BIO_BS_DATA',
            label: 'Bachelor of Science Biology'
        }
    ]

    const handleFiuPathwayChange = (event) => {
        let nObj = {}
        if(event.target.value === 'BIO_BA_DATA'){
            nObj={
                value: 'BIO_BA_DATA',
                label: 'Bachelor of Arts Biology'
            }
        } else {
            nObj={
                value: 'BIO_BS_DATA',
                label: 'Bachelor of Science Biology'
            }
        }
        setFiuPathway(nObj);
    }

    return (
     <Dialog open={open} onClose={() => closeCallback(false, fiuPathway)}>
         <DialogTitle disableTypography>
             <Grid container>
                 <Grid item xs={4} />
                 <Grid item xs={4}>
                    <Typography variant='h6' style={{ fontWeight: 'bold', textAlign: 'center' }}>Program Reset</Typography>
                 </Grid>
                 <Grid item xs={4} style={{ textAlign: 'right' }}>
                 </Grid>
             </Grid>
         </DialogTitle>
         <DialogContent>
             <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant='body1'>
                        Please select a program to reset to below.  This will delete all current pathway setup and exempted courses and reset you back to default course pathway.
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <TextField
                        select
                        value={fiuPathway.value}
                        onChange={handleFiuPathwayChange}
                    >
                        {fiupathways.map((opt) => (
                            <MenuItem key={opt.value} value={opt.value}>
                                {opt.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
             </Grid>
         </DialogContent>
         <DialogActions>
             <Button color='secondary' onClick={() => closeCallback(false, fiuPathway)}>Cancel</Button>
             <Button color='primary' onClick={() => closeCallback(true, fiuPathway)}>Reset</Button>
         </DialogActions>
     </Dialog>   
    )

}

export default ResetDialog;