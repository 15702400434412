import React from 'react'

const Video = ({ videoSrcUrl, videoTitle, width, height, ...props }) => (
    <div>
        <iframe 
            src={videoSrcUrl}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            width={width || "560"}
            height={height || "315"}
        />
    </div>
);

export default Video;