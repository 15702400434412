import React from 'react';
import { loadCSS } from 'fg-loadcss';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > .fas': {
            margin: theme.spacing(1),
        },
    },
}));

const GradIcon = (props) => {

    const classes = useStyles();

    React.useEffect(() => {
        const node = loadCSS(
            'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );

        return () => {
            node.parentNode.removeChild(node);
        }
    }, []);

    return(
        <Icon style={{ paddingRight: '5px' }} className="fas fa-graduation-cap" />
    )

}

export default GradIcon;