import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import UploadDialog from '../UploadDialog';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useAnalyticsApi, useAuthorize, useDataChart } from 'react-use-analytics-api';
import { AnalyticsDashboard } from 'react-analytics-charts';
import { SessionsByDateChart, SessionsGeoChart } from 'react-analytics-charts';

import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import InstitutionDialog from '../InstitutionDialog';

function TabPanel(props){
    const { children, value, index, ...other } = props;

    return(
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`admin-tabpanel-${index}`}
            aria-labelledby={`admin-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index){
    return {
        id: `admin-tab-${index}`,
        'aria-controls': `admin-tabpanel-${index}`
    }
}

const AdminContent = (props) => {

    const [openUpload, setOpenUpload] = React.useState(false);
    const [thisPathway, setThisPathway] = React.useState(null);
    const [value, setValue] = React.useState(0);
    const [partnerPathways, setPartnerPathways] = React.useState([]);
    const [partners, setPartners] = React.useState([]);
    const [selectPartner, setSelectPartner] = React.useState({});
    const [openPartner, setOpenPartner] = React.useState(false);
    const [newPathway, setNewPathway] = React.useState(false);
    const [pathwayPartner, setPathwayPartner] = React.useState({});

    const { ready, gapi, authorized, error } = useAnalyticsApi();
    const authorize = useAuthorize(gapi, { clientId: "520939721976-1rcad1dnqi42o22pceul52007e2s3kdu.apps.googleusercontent.com" })
    const query = {
        ids: 'ga:215882054',
        "start-date": "28daysAgo",
        "end-date": "today",
        metrics: "ga:sessions",
        dimensions: "ga:date",
    }

    const chart = {
        container: "line-chart",
        type: 'LINE',
        options: {
            title: 'Sessions (28 Days)'
        }
    }
    useDataChart(gapi, query, chart);

    React.useEffect(()=>{

        getPartners().then((p) => {
            setPartners(p);
        });

        getPathways().then((p) => {
            //console.log(p);
            setPartnerPathways(p);
        })

    }, []);

    const getPartners = async () => {
        const partnerQuery = await axios({
            method: 'post',
            data: {},
            withCredentials: true,
            url: '/getpartners'
        });

        let recPartners = partnerQuery.data;

        return recPartners;
    }

    const getPathways = async () => {
        const partnerPathQuery = await axios({
            method: 'post',
            data: {},
            withCredentials: true,
            url: 'getpartnerpathways'
        });

        let recPartnerPaths = partnerPathQuery.data;

        return recPartnerPaths;

    }
    

    const pathwayList = [
        {
            id: 1,
            fileName: 'BIO_BA_DATA',
            pathwayName: 'Bachelor of Arts Biology'
        },
        {
            id: 2,
            fileName: 'BIO_BS_DATA',
            pathwayName: 'Bachelor of Science Biology'
        }
    ]

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleClick = (pathway, partner) => {
        setPathwayPartner(partner);
        setThisPathway(pathway);
        setOpenUpload(true);
    }

    const handleUploadClose = () => {
        getPathways().then((p) =>{
            setPartnerPathways(p);
        })
        setPathwayPartner({});
        setThisPathway(null);
        setNewPathway(false);
        setOpenUpload(false);
    }

    const handleAddPartner = () => {

    }

    const handleOpenPartner = (sPartner) => {
        setSelectPartner(sPartner);
        setOpenPartner(true)
    }

    const handlePartnerClose = () => {
        setOpenPartner(false);
    }

    const handleAddPathway = (partner) => {
        setNewPathway(true);
        setPathwayPartner(partner);
        setOpenUpload(true);
    }


    return(
        <Paper>
            <AppBar position='static' style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', backgroundColor: 'rgb(220, 220, 220)', color: '#383838', flexDirection: 'row' }}>
                <Tabs value={value} onChange={handleTabChange} aria-label='admin tabs' indicatorColor='primary'>
                    <Tab label='Dashboard' {...a11yProps(0)} />
                    <Tab label='Institutions' {...a11yProps(1)} />
                    <Tab label='Pathways' {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid container justify='center'>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='h5'>
                            Dashboard
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AnalyticsDashboard 
                            authOptions={{ clientId: "520939721976-1rcad1dnqi42o22pceul52007e2s3kdu.apps.googleusercontent.com" }}
                            hideViewSelector={true}
                            viewId={'ga:215882054'}
                            renderCharts={(gapi, viewId) => {
                                return(
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <SessionsByDateChart 
                                                gapi={gapi}
                                                viewId={'ga:215882054'}
                                                showPageViews
                                                showUsers
                                            />
                                        </Grid>
                                        <Grid item xs={2} />
                                        <Grid item xs={4}>
                                            <SessionsGeoChart gapi={gapi} viewId={'ga:215882054'} options={{ region: 'US', resolution: 'provinces' }} query={{ dimensions: 'ga:region' }} />
                                        </Grid>
                                    </Grid>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container justify='center'>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='h5'>
                            Institutions
                        </Typography>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <List component='nav' aria-label=''>
                            {partners.map((partner) => {
                                
                                return(
                                    <ListItem button key={partner.id} onClick={() => handleOpenPartner(partner)}>
                                        <ListItemText primary={partner.instname} />
                                    </ListItem>
                                )
                            })}
                            <ListItem button onClick={() => handleAddPartner()}>
                                Add New Institution
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container justify='center'>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='h5'>
                            Pathways
                        </Typography>
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <List>
                            {partners.map((partner) => {
                                return(<Paper>
                                    <Grid container justify='center'>
                                        <Grid item={12}>
                                            <Typography variant='h6'>{partner.instname}</Typography>
                                        </Grid>
                                        <Grid item={12}>
                                            <List component='nav' aria-label=''>
                                                {partnerPathways.map((pathway) => {
                                                    if(pathway.instituteId === partner.id){
                                                        return(
                                                            <ListItem button key={pathway.id} onClick={() => handleClick(pathway, partner)}>
                                                                <ListItemText primary={pathway.instLabel} />
                                                            </ListItem>
                                                        )
                                                    }                                                    
                                                })}
                                                <ListItem button onClick={() => handleAddPathway(partner)}>
                                                    Add New Pathway
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Paper>)
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
            </TabPanel>
            <InstitutionDialog  open={openPartner} close={handlePartnerClose} partner={selectPartner} />
            <UploadDialog open={openUpload} pathway={thisPathway} close={handleUploadClose} add={newPathway} partner={pathwayPartner} />
        </Paper>
    )

}

export default AdminContent;