import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

const Transition = React.forwardRef(function Transition(props, ref){
    return <Slide direction='up' ref={ref} {...props} />
});

let openMaxCreditAlertDialogFn;

const MaxCreditAlertDialog = (props) => {

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [title, setTitle] = React.useState('');

    React.useEffect(() => {
        openMaxCreditAlertDialogFn = openMaxCreditAlertDialog;
    }, []);

    const openMaxCreditAlertDialog = ({ title, message }) => {
        setOpen(true);
        setMessage(message);
        setTitle(title);
    }

    const handleClose = (event, reason) => {
        if(reason === 'clickaway'){
            return;
        }

        setOpen(false);
        setMessage('');
        setTitle('');
    }

    return(
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby='max-credit-alert-dialog-title'
            aria-describedby='max-credit-alert-dialog-description'
        >
            <DialogTitle id='max-credit-alert-dialog-title'>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='max-credit-alert-dialog-description'>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ textTransform: 'captialize' }} onClick={handleClose} color='primary'>
                    I Understand
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export function openMaxCreditAlertDialog({ title, message }){
    openMaxCreditAlertDialogFn({ title, message });
}

export default MaxCreditAlertDialog;