import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'capitalize'
            }
        }
    }
});

export default theme;