import React from 'react';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import Paper from '@material-ui/core/Paper';

const MDCDropzone = (props) => {


    const { fileReturn } = props;


    const onDrop = React.useCallback(acceptedFiles => {
        fileReturn(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDrop, maxFiles: 1, accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' });
    const { ref, ...rootProps} = getRootProps();

    return(
        <RootRef rootRef={ref}>
            <Paper {...rootProps} style={{ height: '200px', width: '100%', textAlign: 'center' }}>
            <input {...getInputProps()} name='pathwayFile' />
            {
                !isDragActive && <p>Drag n' Drop some XLSX files here, or click to select files</p>                
            }
            {
                isDragActive && !isDragReject && <p>Drop the file here</p>
            }
            {
                isDragReject && <p>File type not accepted, sorry!</p>
            }
            <p>(1 file max)</p>
            </Paper>
        </RootRef>
    )

}

export default MDCDropzone;