import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const DEFAULT_COLOR = '#3c71d0';

const useStyle = makeStyles({
    radialchart: {
        position: 'relative',
        display: 'inline-block',
        transition: 'all 0.3s ease-in'
    },
    radialnoprogress: {
        opacity: 0,
    },
    radialcharttotal: {
        opacity: 0.3,
        fill: 'none',
        transform: 'rotate(180deg)',
        transformOrigin: 'center'
    },
    radialchartprogress: {
        transform: 'rotate(180deg)',
        transformOrigin: 'center',
        transition: 'all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14)',
        transitionDelay: '0.3s',
    },
    svgcircletext: {
        fontSize: '1.25rem',
        textAnchor: 'middle',
        marginLeft: '-5px',
        marginBottom: '5px',
        /* color: 'white' */
        fill: '#ccc'
    },
});

const MuiRadialGauge = (props) => {
   const [strokeLength, setStrokeLength] = React.useState(0);
    const {
        radius,
        value,
        strokeWidth,
        dimension,
        color,
        prefix,
        suffix,
        minValue,
        maxValue
    } = props;

    const circleRadius = Math.min(radius, 130);
    const circumference = 2 * Math.PI * circleRadius;
    const classes = useStyle();
    const center = dimension/2;
    const circleRef = React.useRef(null);

    React.useEffect(() => {
        setStrokeLength( (circumference / maxValue * (value >= maxValue ? maxValue : value)/2));
    }, [value, circumference]);

    return(
        <div className={classes.radialchart}>
            <svg width={dimension} height={(dimension/2)+3}>
                {
                    strokeLength === 0 ? (
                        <circle 
                            style={{
                                opacity: 0.3,
                                fill: 'none',
                                transform: 'rotate(180deg)',
                                transformOrigin: 'center'
                            }}
                            stroke={DEFAULT_COLOR}
                            strokeWidth={strokeWidth}
                            strokeDasharray={circumference/2}
                            strokeLinecap="round"
                            fill="none"
                            cx={dimension/2}
                            cy={dimension/20}
                            r={circleRadius}
                        />

                    ) : (
                        <circle 
                            style={{
                                opacity: 0.3,
                                fill: 'none',
                                transform: 'rotate(180deg)',
                                transformOrigin: 'center'
                            }}
                            stroke={DEFAULT_COLOR}
                            strokeWidth={strokeWidth}
                            strokeDasharray={circumference/2}
                            strokeLinecap="round"
                            fill="none"
                            cx={dimension/2}
                            cy={dimension/20}
                            r={circleRadius}
                        />
                    )
                }
                {/* <circle 
                    className={strokeLength === 0 ? `${classes.radialcharttotal} ${classes.radialnoprogress}` : `${classes.radialcharttotal}`}
                    stroke={DEFAULT_COLOR}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference/2}
                    strokeLinecap="round"
                    fill="none"
                    cx={dimension/2}
                    cy={dimension/20}
                    r={circleRadius}
                /> */}
                <circle 
                    style={{ 
                        transform: 'rotate(180deg)',
                        transformOrigin: 'center',
                        transition: 'all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14)',
                        transitionDelay: '0.3s'
                    }}
                    ref={circleRef}
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${strokeLength}, ${circumference}`}
                    strokeLinecap="round"
                    cx={dimension/2}
                    cy={dimension/20}
                    fill="none"
                    r={circleRadius}
                />
                <text style={{ fontSize: '1.25rem', textAnchor: 'middle', marginLeft: '-5px', marginBottom: '5px', fill: '#ccc' }} x={center} y={center}>
                    {prefix === '$' ? `${prefix}${value.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')}${suffix}` : `${prefix}${value}${suffix}`}
                </text>
            </svg>
        </div>
    )
   
}

MuiRadialGauge.defaultProps = {
    radius: 130,
    value: 0,
    strokeWidth: 10,
    dimension: 300,
    color: DEFAULT_COLOR,
    suffix: '%',
    prefix: '',
    minValue: 0,
    maxValue: 100
}

export default MuiRadialGauge;