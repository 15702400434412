import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      {'Copyright © '}
      <Link color="inherit" to="https://mdc.edu">
        Miami Dade College
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}
