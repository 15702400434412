import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Fab from '@material-ui/core/Fab';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';
import { ListSubheader } from '@material-ui/core';

const TestScoreFormat = (props) => {
    const { inputRef, onChange, testMax, ...other } = props;

    return(
        <NumberFormat 
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
            isNumericString
            allowNegative={false}
            isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return formattedValue === "" || floatValue <= testMax;
            }}
        />
    )
}

const testDropdownList = [
    {
        value: 'None',
        label: 'None'
    },
    {
        value: 'ACT',
        label: 'ACT'
    },
    {
        value: 'SAT',
        label: 'SAT'
    },
    {
        value: 'PERT',
        label: 'PERT'
    },
    {
        value: 'ACCUPLACER',
        label: 'ACCUPLACER NG'
    }
]


const MuiQuestions = (props) => {

    const { test, handleTestChange, writingScore, handleWScoreChange, mathScore, handleMScoreChange, exempt, handleExempt, readingScore, handleRScoreChange, testArray, aafScore, handleAafScoreChange, handleAddToTestArray, handleRemoveFromTestArray } = props;


    const [expand, setExpand] = React.useState(false);

    React.useEffect(() => {
        if(props.open){
            setExpand(true);
        }
    }, []);

    const handleExpand = () => {
        setExpand(!expand);
    }

    const buildTestToAdd = () => {
        let testObj={
            Test: test,
            writingScore: writingScore,
            readingScore: readingScore,
            mathScore: mathScore,
            aafScore: aafScore
        }

        handleAddToTestArray(testObj);
    }

    return (
            <Grid container /* justify="center" alignItems="center" */ >
                {/* <Grid item xs={3} /> */}
                <Grid item xs={6}>
                    <List
                        subheader={
                            <ListSubheader component='div'>
                                Tests Taken:
                            </ListSubheader>
                        }
                        >                        
                        {testArray && testArray.map((t) => (
                            <ListItem>
                                <ListItemText primary={t.Test} secondary={t.aafScore ? `Writing: ${t.writingScore} | Reading: ${t.readingScore} | Math: ${t.mathScore} | AAF: ${t.aafScore}` : `Writing: ${t.writingScore} | Reading: ${t.readingScore} | Math: ${t.mathScore}`}  />
                                <ListItemSecondaryAction>
                                    <IconButton edge='end' aria-label='remove test' color='secondary' onClick={()=>handleRemoveFromTestArray(t)}>
                                        <RemoveIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                        {testArray.length === 0 && (
                            <ListItem>
                                <ListItemText primary='No Test Added - Test must be added' />
                            </ListItem>
                        )}
                    </List>
                </Grid>
               {/*  <Grid item xs={3} /> */}
                {/* <Grid item xs={3} /> */}
                <Grid item xs={6}>
                    <Accordion expanded={expand} onChange={handleExpand}>
                        <AccordionSummary 
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography variant="body1">Test Score Input</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container justify="space-evenly">
                                <Grid item>
                                    I've taken the: <br /> <TextField select style={{ fontSize: '.75em' }} value={test} onChange={handleTestChange} >
                                        {testDropdownList.map((d, i) => {

                                            let testAlready = testArray.find(t => t.Test === d.value);
                                            if(!testAlready){
                                                return (
                                                    <MenuItem key={i} value={d.value}>{d.label}</MenuItem>
                                                )
                                            }
                                        })}
                                </TextField>
                                </Grid>
                                {test === 'None' &&
                                    ''
                                }
                                {test === 'ACT' &&
                                <Grid item>
                                    My scores: <br />
                                <TextField 
                                    label="Reading" 
                                    value={readingScore} 
                                    onChange={handleRScoreChange} 
                                    aria-label='Reading Scores' 
                                    inputProps={{ 'aira-label': 'reading score', 'testMax': 37 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat,
                                    }} /><br />
                                <TextField 
                                    label="English" 
                                    value={writingScore} 
                                    onChange={handleWScoreChange} 
                                    aria-label='English Scores' 
                                    inputProps={{ 'aira-label': 'writing score', 'testMax': 37 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat,
                                    }} /><br />
                                {/* {writingScore !== 0 && <WritingComponent score={writingScore} test='ACT' />} */}
                                <TextField 
                                    label="Math" 
                                    value={mathScore} 
                                    onChange={handleMScoreChange} 
                                    aria-label='Math Scores' 
                                    inputProps={{ 'aira-label': 'math score', 'testMax': 37 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat,
                                    }} /><br />
                                {/* {mathScore !== 0 && false && <MathComponent score={mathScore} test='ACT' />} */}
                                </Grid>}
                                {test === 'SAT' &&
                                <Grid item>
                                    My scores: <br />
                                <TextField 
                                    label="Reading"
                                    value={readingScore} 
                                    onChange={handleRScoreChange} 
                                    aria-label='Reading Scores' 
                                    inputProps={{ 'aira-label': 'reading score', 'testMax': 41 }} 
                                    InputProps={{
                                        inputComponent: TestScoreFormat,
                                    }} /><br />
                                <TextField 
                                    label="Writing &amp; Language"
                                    value={writingScore} 
                                    onChange={handleWScoreChange} 
                                    aria-label='Writing Scores' 
                                    inputProps={{ 'aira-label': 'writing score', 'testMax': 41 }} 
                                    InputProps={{
                                        inputComponent: TestScoreFormat,
                                    }} /><br />
                                {/* {writingScore !== 0 && false && <WritingComponent score={writingScore} test='SAT' />} */}
                                <TextField 
                                    label="Math" 
                                    value={mathScore} 
                                    onChange={handleMScoreChange} 
                                    aria-label='Math Scores' 
                                    inputProps={{ 'aira-label': 'math score', 'testMax': 41 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat
                                    }} /><br />
                                {/* {mathScore !== 0 && false && <MathComponent score={mathScore} test='SAT' />} */}
                                </Grid>}
                                {test === 'PERT' &&
                                <Grid item>
                                    My scores: <br />
                                <TextField 
                                    label="Reading" 
                                    value={readingScore} 
                                    onChange={handleRScoreChange} 
                                    aria-label='Reading Scores' 
                                    inputProps={{ 'aira-label': 'reading score', 'testMax': 151 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat
                                    }} /><br />
                                <TextField 
                                    label="Writing" 
                                    value={writingScore} 
                                    onChange={handleWScoreChange} 
                                    aria-label='Writing Scores' 
                                    inputProps={{ 'aira-label': 'writing score', 'testMax': 151 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat
                                    }} /><br />
                                {/* {writingScore !== 0 && false && <WritingComponent score={writingScore} test='PERT' />} */}
                                <TextField 
                                    label="Math" 
                                    value={mathScore}
                                    onChange={handleMScoreChange} 
                                    aria-label='Math Scores' 
                                    inputProps={{ 'aira-label': 'math score', 'testMax': 151 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat
                                    }} /><br />
                                {/* {mathScore !== 0 && false && <MathComponent score={mathScore} test='PERT' />} */}
                                </Grid>}
                                {test === 'ACCUPLACER' &&
                                <Grid item>
                                    My scores: <br />
                                <TextField 
                                    label="Reading" 
                                    value={readingScore} 
                                    onChange={handleRScoreChange} 
                                    aria-label='Reading Scores' 
                                    inputProps={{ 'aira-label': 'reading score', 'testMax': 301 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat
                                    }} /><br />
                                <TextField 
                                    label="Writing" 
                                    value={writingScore} 
                                    onChange={handleWScoreChange} 
                                    aria-label='Writing Scores' 
                                    inputProps={{ 'aira-label': 'writing score', 'testMax': 301 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat
                                    }} /><br />
                                {/* {writingScore !== 0 && false && <WritingComponent score={writingScore} test='ACCUPLACER' />} */}
                                <TextField 
                                    label="Math (QAS)" 
                                    value={mathScore} 
                                    onChange={handleMScoreChange} 
                                    aria-label='Math Scores' 
                                    inputProps={{ 'aira-label': 'qas score', 'testMax': 301 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat
                                    }} /><br />
                                {/* {mathScore !== 0 && false && <MathComponent score={mathScore} test='ACCUPLACER' />} */}
                                {mathScore > 257 && <TextField 
                                    label="Math (AAF)" 
                                    value={aafScore} 
                                    onChange={handleAafScoreChange} 
                                    aria-label='Math Scores' 
                                    inputProps={{ 'aira-label': 'aaf score', 'testMax': 301 }}
                                    InputProps={{
                                        inputComponent: TestScoreFormat
                                    }} />}<br />
                                </Grid>}
                                <Grid item xs={12} style={{ textAlign: 'right', marginTop: '10px' }}>
                                    {!exempt && test !== 'None' && <Button variant='contained' color='primary' onClick={()=>buildTestToAdd()}>Add Test</Button>}
                                </Grid>                            
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* <Grid item xs={3} /> */}
                <Grid item xs={3} />
                <Grid item xs={4}>
                    <FormControlLabel 
                        control={<Checkbox checked={exempt} onChange={handleExempt} />}
                        label={<Typography>I am exempt from testing <Link href='https://www.mdc.edu/main/testing/criteria/dev_ed.aspx' target='_blank' aria-label='testing exemption more information'>More Info</Link></Typography>}
                    />
                </Grid>
                <Grid item xs={3} />
            </Grid>
    )
    
}

export default MuiQuestions;