import React from 'react';
import axios from 'axios';

import Container from '@material-ui/core/Container';
import MuiHeader from '../../components/mui-header';
import MuiSnackbar from '../../components/MuiSnackbar';
import MuiProfile from '../../components/mui-profile';
import AdminContent from '../../components/AdminContent';
import Copyright from '../../components/Copyright';
import LoadingBackdrop from '../../components/LoadingBackdrop';


const AdminPage = (props) => {

    const [openProfile, setOpenProfile] = React.useState(false);
    const [validUser, setValidUser] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(null);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
  
  
    const handleOpenProfile = () => {
      setOpenProfile(true);
    }
  
    const handleCloseProfile = () => {
      setOpenProfile(false);
    }
  
    const checkUser = async () => {
      const checkCurrentUser = await axios({
        method: 'get',
        withCredentials: true,
        url: '/getuser'
      });
      setCurrentUser(checkCurrentUser.data);
    }
  
    React.useEffect(() => {
      checkUser();
    }, []);
  
    React.useEffect(() => {
      //console.log(currentUser);
    }, [currentUser]);

    return (
        <React.Fragment>
            <MuiHeader openProfileFunc={handleOpenProfile} currentUser={currentUser} setLoginOpen={setLoginOpen} />
            <Container maxWidth="xl" style={{ marginTop: '20px' }}>
            <MuiProfile open={openProfile} close={handleCloseProfile} currentUser={currentUser} checkUser={checkUser} />
            <AdminContent />
            </Container>
            <Copyright />
            <MuiSnackbar />
            {/* <LoadingBackdrop openLoading={loading} /> */}
      </React.Fragment>
    );
}

export default AdminPage;