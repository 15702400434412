import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { openMuiSnackbar } from './MuiSnackbar';

import CodeDialog from '../components/CodeDialog';


const MuiLogin = (props) => {

    const { open, close } = props;

    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const [open2fa, setOpen2fa] = React.useState(false);

    const checkEmail = (email) => {
        // eslint-disable-next-line
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
            return true;
        } else {
            return false;
        }
    }

    const handlePasswordReset = async () => {
        var isValid = checkEmail(username);
        if(!isValid){
            openMuiSnackbar({ message: 'Invalid Email!', variant: 'warning' });
            return;
        }
        const sendPasswordReset = await axios({
            method: 'post',
            data: {
                email: username,
            },
            withCredentials: true,
            url: '/password-reset'
        });
        if(sendPasswordReset.data === 'User Found'){
            openMuiSnackbar({ message: 'Reset Email Sent', variant: 'success' });
        }
        if(sendPasswordReset.status === '404'){
            openMuiSnackbar({ message: 'User Not Found', variant: 'error' })
        }
    } 


    const handleLogin = async () => {
        var isValid = checkEmail(username);
        if(!isValid){
            openMuiSnackbar({ message: 'Invalid Email', variant: 'error' });
            return;
        }
        if(password<6){
            openMuiSnackbar({ message: 'Check Password', variant: 'error' });
            return;
        }
        const userLoggedIn = await axios({
            method: 'post',
            data: {
                username: username,
                password: password
            },
            withCredentials: true,
            url: '/userlogin'
        });
        if(userLoggedIn.data === 'No User Exists'){
            openMuiSnackbar({ message: 'Invalid Credentials', variant: 'error' });
            return;
        }
        setOpen2fa(true);
        /* axios({
            method: 'get',
            withCredentials: true,
            url: '/getuser'
        }).then((res) => {
            console.log(res.data);
        });
        openMuiSnackbar({ message: 'Welcome Back!', variant: 'success' });
        handleClose(); */
    }

    const handleDualFactorClose = () => {
        setOpen2fa(false);
        axios({
            method: 'get',
            withCredentials: true,
            url: '/getuser'
        }).then((res) => {
            //console.log(res.data);
        });
        openMuiSnackbar({ message: 'Welcome Back!', variant: 'success' });
        handleClose();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin();
    }

    const handleClose = () => {
        setUsername('');
        setPassword('');
        close();
    }

    return(
        <React.Fragment>
        <Dialog open={open} fullWidth maxWidth='sm'>
            <form onSubmit={handleSubmit}>
            <DialogTitle>
                Login
            </DialogTitle>
            <DialogContent>
                <Grid container justify='center' alignContent='center'>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <TextField label='Email' value={username} onChange={(e) => setUsername(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <TextField label='Password' value={password} onChange={(e) => setPassword(e.target.value)} type='password' />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={3}>
                        <Button onClick={handlePasswordReset}>
                            Password Reset
                        </Button>
                    </Grid>
                    <Grid item xs={5} />
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Button type='submit' color='primary' aria-label='login'>
                            Login
                        </Button>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                        <Button color='secondary' onClick={handleClose} aria-label='cancel'>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            </form>
        </Dialog>
        <CodeDialog open={open2fa} username={username} password={password} handleClose={handleDualFactorClose} />
        </React.Fragment>
    );


}

export default MuiLogin;