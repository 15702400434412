import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ImageUploading from 'react-images-uploading';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Remove';



const InstitutionDialog = (props) => {

    const { open, close, partner } = props;

    const [editPartner, setEditPartner] = React.useState({});
    const [image, setImage] = React.useState([]);
    const maxNumber = 1;

    React.useEffect(() => {

        setEditPartner(partner);

        let partnerImage = [
            {
                data_url: partner.logopath,
                file: File
            }
        ]

        setImage(partnerImage);

    }, [partner]);

    const handleClose = () => {
        close();
    }


    const onImageChange = (imageList, addUpdateIndex) => {
        console.log(imageList);
        setImage(imageList);
    }

    if(!open){
        return(
            <div />
        )
    }

    return(
        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
            <DialogTitle>
                Edit Institution
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField value={editPartner.instname} label='Institution Name' fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField value={editPartner.instvalue} label='Institution Value' />
                    </Grid>
                    <Grid item xs={9} />
                    <Grid item xs={12}>
                        <ImageUploading 
                            //value={editPartner.logopath}
                            value={image}
                            onChange={onImageChange}
                            maxNumber={maxNumber}
                            dataURLKey='data_url'
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps
                            }) => (
                                <div className="upload__image-wrapper">
                                    <Button 
                                        color={isDragging ? 'secondary' : undefined}
                                        variant='contained'
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        Click or Drop here
                                    </Button>
                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item" style={{ padding: '10px' }}>
                                            <img src={image['data_url']} alt="" width="100" />
                                            <IconButton onClick={() => onImageRemove(index)} color='secondary' component="span">
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>
                            )}

                        </ImageUploading>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary'>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default InstitutionDialog;