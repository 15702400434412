import React from 'react';
import Grid from '@material-ui/core/Grid';
import MuiRadialChart from './mui-radialchart';
import MuiRadialGauge from './mui-radialgauge';
import Typography from '@material-ui/core/Typography';
import MuiGradCount from './mui-gradcount';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

/* import mdcDeco from '../images/deco-stripes.png';
import mdcMainLogo from '../images/MDCLogo-Blue.png'; */
import fiuMainLogo from '../images/fiu-main-logo.png';
import mdcLogoBlack from '../images/MDC_Vert_Black.png';
import mdcLogoBlue from '../images/MDCLogo-Blue.png';
import mdcLogoBlue150 from '../images/MDC-150-Blue.png';
import mdcBlue100 from '../images/mdcblue100.png';
import mdcBlue150 from '../images/mdcblue150.png';
import mdcLogoWhite from '../images/mdcwhite150.png';
import dashBackground from '../images/dashback.png';
import mdcLBlack from '../images/mdcblack150.png';

/* import calendarframe from '../images/calendarframe-180.png';
 */
const CostSpeedo = (props) => {

    const { fiucourses, courses, dualEnrollCourses, progress, startdate, enddate, print, accumcost, accumhours, floridares, dualenroll, dualterms, mdcGrad, mdcMaxSemester, mdcGradDate, compDate, additionalCosts, additionalSavings, aiceCourses, apCourses, cartCourses, clepCourses, cspCourses, dlptCourses, dsstCourses, exemptCourses, ibCourses, uexcelCourses } = props;

    const [mdchours, setMDCHours] = React.useState(0);
    const [fiuhours, setFIUHours] = React.useState(0);
    const [cost, setCost] = React.useState(0);
    const [mdccost, setMdcCost] = React.useState(0);
    const [fiucost, setFiuCost] = React.useState(0);
    const [savings, setSavings] = React.useState(0);
    var d = new Date(compDate);
    d.setMonth(d.getMonth() + 4);

    const handleColor = (s, val) => {
        if(s === 'mdc'){
            /* if(val <= 66){
                return '#3cff33';
            } */
            if(val <= 66){
                return '#3CFF33';
            }
            if(val <= 76){
                return '#FFFF33';
            }
            if(val > 76){
                return '#FF3333';
            }
        }
        if(s === 'fiu'){
            if(val <= 66){
                return '#3CFF33';
            }
            if(val <= 76){
                return '#FFFF33';
            }
            if(val > 76){
                return '#FF3333';
            }
        }
        if(s === 'both'){
            if(val <= 126){
                return '#3CFF33';
            }
            /* if(val <= 120){
                return '#3CFF33';
            } */
            if(val <= 144){
                return '#FFFF33';
            }
            if(val > 144){
                return '#FF3333';
            }
        }
        if(s === 'savings'){
            return '#FFBE33';
        }
    }

    const handleCostColor = (s, val) => {
        if(s === 'mdc'){
            /* if(val <= 5000.00){
                return '#FFFF33';
            } */
            if(val <= 7803.00){
                return '#3CFF33';
            }
            if(val <= 8985.00){
                return '#FFFF33';
            }
            if(val > 8985.00){
                return '#FF3333';
            }
        }
        if(s === 'fiu'){
            /* if(val <= 7093.20){
                return '#FFFF33';
            } */
            if(val <= 13568.00){
                return '#3CFF33';
            }
            if(val <= 29602.00){
                return '#FFFF33';
            }
            if(val > 29602.00){
                return '#FF3333';
            }
        }
        if(s === 'total'){
            /* if(val <= 7093.20){
                return '#FFFF33';
            } */
            if(val <= 21371.00){
                return '#3CFF33';
            }
            if(val <= 30000.00){
                return '#FFFF33';
            }
            if(val > 30000.00){
                return '#FF3333';
            }
        }
        if(s === 'savings'){
            return '#3CFF33';
        }    
    }

    React.useEffect(() => {
        var fnhours = 0;
        var chours = 0;
        var ccost = 0;
        var mcost = 0;
        var fcost = 0;
        var tcost = 0;
        var thours = 0;
        var dhours = 0;
        var dcost = 0;

        var semLine = mdcMaxSemester;
        if(mdcGrad){
            semLine = mdcGrad;
        }

        dualEnrollCourses && dualEnrollCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        aiceCourses && aiceCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        apCourses && apCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        dlptCourses && dlptCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        dsstCourses && dsstCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        cartCourses && cartCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        cspCourses && cspCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        ibCourses && ibCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        uexcelCourses && uexcelCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        clepCourses && clepCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST) - 89 - 39;
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST) - 89 - 39;
            }
        });

        exemptCourses && exemptCourses.map((ai) => {
            dhours = dhours + ai.Units;
            if(floridares){
                dcost = dcost + parseFloat(ai.RESCOST);
            } else {
                dcost = dcost + parseFloat(ai.NONRESCOST);
            }
        });

        fiucourses && fiucourses.map((fc) => {
            if(fc.SheetName==='TRANSFER' && semLine>=fc.TERM){
                thours = thours + fc.Units;
            } else {
                fnhours = fnhours + fc.Units;
            }           
           if(dualenroll){
                if(fc.TERM > dualterms){
                    if(floridares){
                        if(fc.SheetName==='TRANSFER' && semLine>=fc.TERM){
                            tcost = tcost + parseFloat(fc.RESCOST)
                        } else {
                            fcost = fcost + parseFloat(fc.RESCOST)
                        }                        
                    } else {
                        if(fc.SheetName==='TRANSFER' && semLine>= fc.TERM){
                            tcost = tcost + parseFloat(fc.NONRESCOST)
                        } else {
                            fcost = fcost + parseFloat(fc.NONRESCOST)
                        }
                        
                    }    
                }
           } else {
                if(floridares){
                    if(fc.SheetName==='TRANSFER' && semLine>=fc.TERM){
                        tcost = tcost + parseFloat(fc.RESCOST)
                    } else {
                        fcost = fcost + parseFloat(fc.RESCOST)
                    }                    
                } else {
                    if(fc.SheetName==='TRANSFER' && semLine >= fc.TERM){
                        tcost = tcost + parseFloat(fc.NONRESCOST)
                    } else {
                        fcost = fcost + parseFloat(fc.NONRESCOST)
                    }                    
                }
           }
           
        });
        courses && courses.map((c) => {
           /*  chours = chours + c.Units;
            if(dualenroll){
                if(c.TERM > dualterms){
                    if(floridares){
                        mcost = mcost + parseFloat(c.RESCOST);
                    } else {
                        mcost = mcost + parseFloat(c.NONRESCOST);
                    }
                }
            } else {
                if(floridares){
                    mcost = mcost + parseFloat(c.RESCOST);
                } else {
                    mcost = mcost + parseFloat(c.NONRESCOST);
                }
            }  */
            if(!c.cartActive){
                chours = chours + c.Units;
            }            
            if(dualenroll){
                if(floridares){
                    if(c.cartActive){
                        dcost = dcost + parseFloat(c.RESCOST);
                    } else {
                        if(c.clepActive){
                            dcost = dcost + parseFloat(c.RESCOST) - 89 - 39;
                        } else {
                            mcost = mcost + parseFloat(c.RESCOST);
                        }
                    }                    
                } else {
                    if(c.cartActive){
                        dcost = dcost + parseFloat(c.NONRESCOST);
                    } else {
                        if(c.clepActive){
                            dcost = dcost + parseFloat(c.NONRESCOST) - 89 - 39;
                        } else {
                            mcost = mcost + parseFloat(c.NONRESCOST);
                        }
                    }                    
                }
            } else {
                if(floridares){
                    if(c.cartActive){
                        dcost = dcost + parseFloat(c.RESCOST);
                    } else {
                        if(c.clepActive){
                            dcost = dcost + parseFloat(c.RESCOST) - 89 - 39;
                        } else {
                            mcost = mcost + parseFloat(c.RESCOST);
                        }
                    }                    
                } else {
                    if(c.cartActive){
                        dcost = dcost + parseFloat(c.NONRESCOST);
                    } else {
                        if(c.clepActive){
                            dcost = dcost + parseFloat(c.NONRESCOST) - 89 - 39;
                        } else {
                            mcost = mcost + parseFloat(c.NONRESCOST);
                        }
                    }                    
                }
            }              
            
        });

        var transCost = thours*118.22;

        setMDCHours(chours+thours+dhours);
        setFIUHours(fnhours);
        ccost = parseFloat(fcost) + parseFloat(mcost) + parseFloat(transCost);
        /* ccost = (chours*118.22)+(fnhours*205.57);
        mcost = (chours*118.22).toFixed(2);
        fcost = (fnhours*205.57).toFixed(2); */
        setCost(ccost.toFixed(2));
        setMdcCost(mcost.toFixed(2) + transCost.toFixed(2));
        setFiuCost(fcost.toFixed(2));
        let dsavings = parseFloat(dcost) + parseFloat(additionalSavings);
        setSavings(dsavings.toFixed(2));
    }, []);

    React.useEffect(() => {
        var fnhours = 0;
        var chours = 0;
        var ccost = 0;
        var mcost = 0;
        var fcost = 0;
        var tcost = 0;
        var thours = 0;
        var dcost = 0;
        var dhours = 0;

        var semLine = mdcMaxSemester;
        if(mdcGrad){
            semLine = mdcGrad;
        }

        dualEnrollCourses && dualEnrollCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        aiceCourses && aiceCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        apCourses && apCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        dlptCourses && dlptCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        dsstCourses && dsstCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        cartCourses && cartCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        cspCourses && cspCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        ibCourses && ibCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        uexcelCourses && uexcelCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });

        clepCourses && clepCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST) - 89 - 39;
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST) - 89 - 39;
            }
        });

        exemptCourses && exemptCourses.map((de) => {
            dhours = dhours + de.Units;
            if(floridares){
                dcost = dcost + parseFloat(de.RESCOST);
            } else {
                dcost = dcost + parseFloat(de.NONRESCOST);
            }
        });


        fiucourses && fiucourses.map((fc) => {
            if(fc.SheetName==='TRANSFER' && semLine>=fc.TERM){
                thours = thours + fc.Units;
            } else {
                fnhours = fnhours + fc.Units;
            }           
           if(dualenroll){
                if(fc.TERM > dualterms){
                    if(floridares){
                        if(fc.SheetName==='TRANSFER' && semLine>=fc.TERM){
                            tcost = tcost + parseFloat(fc.RESCOST)
                        } else {
                            fcost = fcost + parseFloat(fc.RESCOST)
                        }                        
                    } else {
                        if(fc.SheetName==='TRANSFER' && semLine>= fc.TERM){
                            tcost = tcost + parseFloat(fc.NONRESCOST)
                        } else {
                            fcost = fcost + parseFloat(fc.NONRESCOST)
                        }
                        
                    }    
                }
           } else {
                if(floridares){
                    if(fc.SheetName==='TRANSFER' && semLine>=fc.TERM){
                        tcost = tcost + parseFloat(fc.RESCOST)
                    } else {
                        fcost = fcost + parseFloat(fc.RESCOST)
                    }                    
                } else {
                    if(fc.SheetName==='TRANSFER' && semLine >= fc.TERM){
                        tcost = tcost + parseFloat(fc.NONRESCOST)
                    } else {
                        fcost = fcost + parseFloat(fc.NONRESCOST)
                    }                    
                }
           }
           
        });
        courses && courses.map((c) => {
            if(!c.cartActive){
                chours = chours + c.Units;
            }            
            if(dualenroll){
                if(floridares){
                    if(c.cartActive){
                        dcost = dcost + parseFloat(c.RESCOST);
                    } else {
                        if(c.clepActive){
                            dcost = dcost + parseFloat(c.RESCOST) - 89 - 39;
                        } else {
                            mcost = mcost + parseFloat(c.RESCOST);
                        }
                    }                    
                } else {
                    if(c.cartActive){
                        dcost = dcost + parseFloat(c.NONRESCOST);
                    } else {
                        if(c.clepActive){
                            dcost = dcost + parseFloat(c.NONRESCOST) - 89 - 39;
                        } else {
                            mcost = mcost + parseFloat(c.NONRESCOST);
                        }
                    }                    
                }
            } else {
                if(floridares){
                    if(c.cartActive){
                        dcost = dcost + parseFloat(c.RESCOST);
                    } else {
                        if(c.clepActive){
                            dcost = dcost + parseFloat(c.RESCOST) - 89 - 39;
                        } else {
                            mcost = mcost + parseFloat(c.RESCOST);
                        }
                    }                    
                } else {
                    if(c.cartActive){
                        dcost = dcost + parseFloat(c.NONRESCOST);
                    } else {
                        if(c.clepActive){
                            dcost = dcost + parseFloat(c.NONRESCOST) - 89 - 39;
                        } else {
                            mcost = mcost + parseFloat(c.NONRESCOST);
                        }
                    }                    
                }
            }            
        });

        var transCost = thours*118.22;

        setMDCHours(chours+thours+dhours);
        setFIUHours(fnhours);
        ccost = parseFloat(fcost) + parseFloat(mcost) + parseFloat(transCost);
        /* ccost = (chours*118.22)+(fnhours*205.57);
        mcost = (chours*118.22).toFixed(2);
        fcost = (fnhours*205.57).toFixed(2); */
        setCost(ccost.toFixed(2));
        setMdcCost((mcost + transCost).toFixed(2));
        setFiuCost(fcost.toFixed(2));
        let dsavings = parseFloat(dcost) + parseFloat(additionalSavings);
        setSavings(dsavings.toFixed(2));
    }, [fiucourses, courses, floridares, dualenroll, dualterms, mdcMaxSemester, mdcGrad, dualEnrollCourses, aiceCourses, apCourses, dlptCourses, dsstCourses, cartCourses, cspCourses, ibCourses, uexcelCourses, clepCourses, exemptCourses]);

    return (
        <Paper style={{ padding: '10px', marginBottom: '15px', backgroundImage: `url(${dashBackground})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        {/* <Paper style={{ padding: '10px', marginBottom: '15px', background: 'radial-gradient(circle, rgba(36, 123, 192, 1) 82%, rgba(0,0,0,1) 100%' }}> */}
        {/* <Paper style={{ padding: '10px', marginBottom: '15px', background: 'linear-gradient(to right bottom, #005dab, #fff)' }}> */}
            
            <Grid container justify="space-evenly" alignItems="center">
                <Grid item xs={12} style={{ color: '#ccc' }}>
                    <Typography variant='h2'>
                        Dashboard
                    </Typography>
                </Grid>
                <Tooltip title='This calculates the total time in your associate degree.'>
                    <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                        {/* <img src={mdcLogoBlue150} alt="MDC" /> */}
                        <img src={mdcBlue150} style={{ width: '130px', marginBottom: '10px' }} alt="MDC" />
                        <MuiGradCount name={`Time in Associate's Degree`} date={mdcGradDate instanceof Date ? mdcGradDate : new Date(mdcGradDate)} start={startdate} />
                    </Grid>
                </Tooltip>
                <Tooltip title='This calculates the percentage completed based on the checked completed terms.'>
                    <Grid item style={{ textAlign: 'center' }} xs={12} md={2}>
                        {/* <MuiRadialChart 
                            progress={progress}
                            color="#3c71d0"
                        /><br /> */}
                        <MuiRadialChart 
                            progress={progress}
                            color="#ccc"
                        /><br />
                        <Typography variant="h5" style={{ display: 'none' }}>{progress}</Typography>
                        <Typography variant="h6" style={{ fontWeight: 'bold', color: '#ccc', marginTop: '-20px' }} >Program Progress</Typography>
                    </Grid>
                </Tooltip>
                <Tooltip title={`This calculates the time remaining until your bachelor's degree.`}>
                    <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                        <img style={{ width: '150px', marginBottom: '10px', marginTop: '10px' }} src={fiuMainLogo} alt="FIU" />
                        <MuiGradCount name={`Time until Bachelor's Degree`} date={enddate} start={d} />
                    </Grid>
                </Tooltip>
                <Grid container item md={5} xs={12}>
                    <Tooltip title='Miami Dade College Credits'>
                        <Grid item style={{ textAlign: 'center', color: '#ccc' }} xs={6} md={6} >
                            <MuiRadialGauge
                                value={mdchours}
                                minValue={0}
                                maxValue={120}
                                suffix=" Credits"
                                color={handleColor('mdc', mdchours)}
                                radius={70}
                                dimension={200}
                            />
                            <br />
                            <Typography variant="h5" style={{ display: 'none' }}>{mdchours}</Typography>
                            <Typography variant="h6">MDC Credits</Typography>
                        </Grid>
                    </Tooltip>
                    <Tooltip title='Florida International University Credits'>
                        <Grid item style={{ textAlign: 'center', color: '#ccc' }} xs={6} md={6}>
                            <MuiRadialGauge
                                value={fiuhours}
                                minValue={60}
                                maxValue={180}
                                suffix=" Credits"
                                color={handleColor('fiu', fiuhours)}
                                radius={70}
                                dimension={200}
                            />    
                            <br />
                            <Typography variant="h5" style={{ display: 'none' }}>{fiuhours}</Typography>
                            <Typography variant="h6">FIU Credits</Typography>
                        </Grid>
                    </Tooltip>
                    <Tooltip title='Total Credits'>
                        <Grid item style={{ textAlign: 'center', color: '#ccc' }} xs={6} md={6}>
                        <MuiRadialGauge
                            value={fiuhours+mdchours}
                            minValue={60}
                            maxValue={180}
                            suffix=" Credits"
                            color={handleColor('both', fiuhours+mdchours)}
                            radius={70}
                            dimension={200}
                        />
                        <br />
                        <Typography variant="h5" style={{ display: 'none' }}>{fiuhours+mdchours}</Typography>
                        <Typography variant="h6">Total Credits</Typography>
                        </Grid>
                    </Tooltip>
                    <Tooltip title='Accumulative Credits'>
                        <Grid item style={{ textAlign: 'center', color: '#ccc' }} xs={6} md={6}>
                        <MuiRadialGauge
                            value={accumhours}
                            minValue={60}
                            maxValue={180}
                            suffix=" Credits"
                            color={handleColor('fiu', accumcost)}
                            radius={70}
                            dimension={200}
                        />
                        <br />
                        <Typography variant="h5" style={{ display: 'none' }}>{accumhours}</Typography>
                        <Typography variant="h6">Credits Completed</Typography>
                        </Grid>
                    </Tooltip>
                </Grid>
                <Grid container item md={2} style={{ marginTop: '20px' }}>
                    <Tooltip title='Total Savings (Estimated by Credit Hour minus Relevant Fees)'>
                        <Grid item xs={12} style={{ textAlign: 'center', color: '#ccc' }}>        
                            <MuiRadialGauge
                                value={savings}
                                minValue={0}
                                maxValue={20000}
                                suffix=""
                                prefix="$"
                                color={handleCostColor('savings', savings)}
                                radius={70}
                                dimension={200}
                            />
                            <br />
                            <Typography variant="h5" style={{ display: 'none' }}>{savings}</Typography>
                            <Typography variant="h6">Total Savings</Typography>
                        </Grid>
                    </Tooltip>
                    {/* <Grid item xs={12} style={{ textAlign: 'center', color: '#ccc' }}>
                        <Typography variant='h6'>Miami Dade College</Typography>
                        <img style={{ width: '150px' }} src={mdcLogoBlack} alt="MDC" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '20px', color: '#ccc' }}>
                        <Typography variant='h6'>Transfer Institution</Typography>
                        <img style={{ width: '150px' }} src={fiuMainLogo} alt="FIU" />
                    </Grid> */}
                </Grid>
                <Grid container item md={5} xs={12}>
                    <Tooltip title='Miami Dade College Cost (Estimated by Credit Hours)'>
                        <Grid item xs={6} style={{ textAlign: 'center', color: '#ccc' }}>        
                            <MuiRadialGauge
                                value={mdccost}
                                minValue={0}
                                maxValue={20000}
                                suffix=""
                                prefix="$"
                                color={handleCostColor('mdc', mdccost)}
                                radius={70}
                                dimension={200}
                            />
                            <br />
                            <Typography variant="h5" style={{ display: 'none' }}>{mdccost}</Typography>
                            <Typography variant="h6">MDC Cost</Typography>
                        </Grid>
                    </Tooltip>
                    <Tooltip title='Florida International University Cost (Estimated by Credit Hours)'>
                        <Grid item xs={6} style={{ textAlign: 'center', color: '#ccc' }}>
                            <MuiRadialGauge
                                value={fiucost}
                                minValue={0}
                                maxValue={20000}
                                suffix=""
                                prefix="$"
                                color={handleCostColor('fiu', fiucost)}
                                radius={70}
                                dimension={200}
                            />                        
                            <br />
                            <Typography variant="h5" style={{ display: 'none' }}>{fiucost}</Typography>
                            <Typography variant="h6">FIU Cost</Typography>
                        </Grid>
                    </Tooltip>
                    <Tooltip title='Total Cost (Estimated by Credit Hours)'>
                        <Grid item xs={6} md={6} style={{ textAlign: 'center', color: '#ccc' }}>
                            <MuiRadialGauge
                                value={cost}
                                minValue={0}
                                maxValue={20000}
                                suffix=""
                                prefix="$"
                                color={handleCostColor('total', cost)}
                                radius={70}
                                dimension={200}
                            />
                            <br />
                            <Typography variant="h5" style={{ display: 'none' }}>{cost}</Typography>
                            <Typography variant="h6">Total Cost</Typography>
                        </Grid>
                    </Tooltip>
                    <Tooltip title='Accumulative Cost (Estimated by Credit Hours)'>
                        <Grid item xs={6} md={6} style={{ textAlign: 'center', color: '#ccc' }}>
                            <MuiRadialGauge
                                value={parseFloat(accumcost).toFixed(2)}
                                minValue={0}
                                maxValue={20000}
                                suffix=""
                                prefix="$"
                                color={handleCostColor('total', parseFloat(accumcost))}
                                radius={70}
                                dimension={200}
                            />
                            <br />
                            <Typography variant="h5" style={{ display: 'none' }}>{`${parseFloat(accumcost).toFixed(2)}`}</Typography>
                            <Typography variant="h6">Accumulative Cost</Typography>
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid> 
            </Paper>
    );
}

export default CostSpeedo;