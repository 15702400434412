import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { openMuiSnackbar } from '../MuiSnackbar';

import NumberFormat from 'react-number-format';


const DigitFormat = (props) => {
    const { inputRef, onChange, ...other } = props;

    return(
        <NumberFormat 
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
            allowNegative={false}

        />
    )
}

const CodeDialog = (props) => {

    const { open, username, password, handleClose } = props;

    const [factorDigits, setFactorDigits] = React.useState({
        fd1: '',
        fd2: '',
        fd3: '',
        fd4: '',
        fd5: '',
        fd6: ''
    })


    const handle2fa = async (e) => {
        e.preventDefault();
        
        if(factorDigits.fd1 === '' || factorDigits.fd2 === '' || factorDigits.fd3 === '' || factorDigits.fd4 === '' || factorDigits.fd5 === '' || factorDigits.fd6 === ''){
            openMuiSnackbar({ message: 'Invalid Code', variant: 'error' });
            return;
        }

        const fdCode = `${factorDigits.fd1}${factorDigits.fd2}${factorDigits.fd3}${factorDigits.fd4}${factorDigits.fd5}${factorDigits.fd6}`;

        const factorAuth = await axios({
            method: 'post',
            data: {
                authToken: fdCode,
                username: username,
                password: password
            },
            withCredentials: true,
            url: '/2fa-code'
        });

        //console.log(factorAuth);

        if(factorAuth.data !== 'Authenticated Successful'){
            openMuiSnackbar({ message: 'Invalid or Expired Token', variant: 'error' });
            return;
        } 

        handleClose();

    }

    const handleChange = (event) => {
        setFactorDigits({ ...factorDigits, [event.target.name]: event.target.value });
        const tName = event.target.name
        const fdIndex = tName.slice(-1)
        const nextSib = document.querySelector(`input[name=fd${parseInt(fdIndex)+1}]`);
        if(nextSib !== null){
            setTimeout(() => {
                nextSib.focus();
            }, 0);            
        }
    }

    return(
        <Dialog open={open} fullWidth maxWidth='sm'>
            <form onSubmit={handle2fa}>
                <DialogTitle>
                    Two-Factor Authentication
                </DialogTitle>
                <DialogContent style={{ overflowY: 'hidden' }}>
                    <Grid container justify='center' alignContent='center' spacing={3}>
                        <Grid item xs={3} />
                        <Grid item xs={1}>
                            <TextField 
                                InputProps={{
                                    inputComponent: DigitFormat,
                                }}
                                value={factorDigits.fd1}
                                onChange={handleChange}
                                name='fd1'
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                                }}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField 
                                InputProps={{
                                    inputComponent: DigitFormat,
                                }}
                                value={factorDigits.fd2}
                                onChange={handleChange}
                                name='fd2'
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField 
                                InputProps={{
                                    inputComponent: DigitFormat,
                                }}
                                value={factorDigits.fd3}
                                onChange={handleChange}
                                name='fd3'
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField 
                                InputProps={{
                                    inputComponent: DigitFormat,
                                }}
                                value={factorDigits.fd4}
                                onChange={handleChange}
                                name='fd4'
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField 
                                InputProps={{
                                    inputComponent: DigitFormat,
                                }}
                                value={factorDigits.fd5}
                                onChange={handleChange}
                                name='fd5'
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField 
                                InputProps={{
                                    inputComponent: DigitFormat,
                                }}
                                value={factorDigits.fd6}
                                onChange={handleChange}
                                name='fd6'
                                onInput = {(e) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,1)
                                }}
                            />
                        </Grid>
                        <Grid item xs={3} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type='submit' color='primary' aria-label='submit'>
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default CodeDialog;