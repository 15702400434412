import React, { useRef } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//import fiuwhitelogo from '../images/fiu-white-logo.png';
//import mdclogo from '../images/mdc-logo.png';
import mdcDecoBlue from '../images/deco-stripes-blue.png';
import mdclogoblue from '../images/MDCLogo-Blue.png';
import fiuBlueLogo from '../images/fiu-main-logo.png';


const classes = {
    board: {
        display: 'flex',
        margin: '0 auto',
        fontFamily: 'Arial, "Helvetica Neue", sans-serif',
        width: '100%'
    },
    column: {
        minWidth: 210,
        minHeight: 1000,
        width: '12vw',
        height: '80vh',
        margin: '0 auto',
        backgroundColor: '#005dab',
        backgroundImage: `url(${mdcDecoBlue})`
    },
    columnGrad: {
        minWidth: 210,
        minHeight: 1000,
        width: '12vw',
        height: '80vh',
        margin: '0 auto',
        backgroundColor: '#005dab',
        backgroundImage: `url(${mdcDecoBlue})`,
        borderRight: '5px solid #f44336'
    },
    columnHead: {
        textAlign: 'center',
        padding: 0,
        paddingTop: 5,
        paddingBottom: 0,
        fontSize: '1.2rem',
        backgroundColor: '#222',
        color: 'white',
    },
    columnHeadDualE: {
        textAlign: 'center',
        padding: 10,
        paddingTop: 5,
        paddingBottom: 0,
        fontSize: '1.2rem',
        backgroundColor: '#A9A9A9',
        color: 'white',
    },
    columnFoot: {
        textAlign: 'center',
        padding: 10,
        fontSize: '1.2rem',
        backgroundColor: '#222',
        width: '10vw',
        position: 'absolute',
        bottom: 35,
        color: 'white'
    },
    item: {
        padding: 10,
        margin: 10,
        fontSize: '0.8em',
        cursor: 'pointer',
        backgroundColor: 'white',
        textAlign: 'center'
    },
    pagebreak: {
        pageBreakBefore: 'always',
        width: '100%',
    }
}

const getCourseColor = (ind) => {
    var colorhex = '#d3d3d3';

    switch (ind){
        case 1:
            colorhex = '#FC2C7E';
            break;
        case 2:
            colorhex = '#2CEAFC';
            break;
        case 3:
            colorhex = '#2CFC52';
            break;
        case 4: 
            colorhex = '#E732E7';
            break;
        case 5:
            colorhex = '#fff701';
            break;
        case 6:
            colorhex = '#970097';
            break;
        default:
            colorhex = '#d3d3d3';
    }
 
    return colorhex;
}

const handleTermName = (termdate) => {

    var termname = '';

    termdate = new Date(termdate);

    switch(termdate.getMonth()){
        case 0:
            termname = 'Spring';
            break;
        case 4:
            termname = 'Summer';
            break;
        case 8:
            termname = 'Fall'
            break;
        default: 
            termname = 'Spring';
    }

    return `${termname} ${termdate.getFullYear()}`;

}



const SemesterColumn = ({ children }) => {
    const ref = useRef(null);
    return <div ref={ref}> {children}</div>
}

/* const oldSemesterItem = ({ citem }) => {
    const ref = useRef(null);
    
    return(
        <Card ref={ref} style={{ margin: '10px' }}>
            <CardHeader style={{ paddingBottom: '0px'}} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.id}</Typography>} subheader={<Typography variant="caption">{citem.hours} hours</Typography>} />
            <CardActions style={{ paddingTop: '0px' }}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Grid item>
                        <Button disabled={!citem.description} style={{ textTransform: 'capitalize', fontSize: '0.75em'}} color="primary"></Button>
                    </Grid>
                    <Grid item>
                        {citem.logo && <img src={citem.logo} style={{ width: '28px', height: '15px' }} alt="MDC" />}
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
} */

const SemesterItem = ({ citem }) => {
    const ref = useRef(null);

    return(
        <Card ref={ref} style={{ margin: '10px' }}>
            <CardHeader style={{ paddingBottom: '0px', borderTop: `8px solid ${getCourseColor(citem.Color)}`, textAlign: 'left' }} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.CoursePrefix !== null ? `${citem.CoursePrefix} ${citem.CourseNumber}` : `${citem.Title}`}</Typography>} subheader={<Typography variant="caption">{citem.Units} credits</Typography>} />
            <CardActions style={{ paddingTop: '0px' }}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Grid item>
                        {/* <Button disabled={!citem.Description} style={{ textTransform: 'capitalize', fontSize: '0.75em'}} color="primary" onClick={courseClick}>Learn More</Button> */}
                    </Grid>
                    <Grid item>
                        {citem.logo && citem.SheetName === 'TRANSFER' ? (<React.Fragment><img src={mdclogoblue} style={{ width: '28px', height: '15px' }} alt="MDC" /> <img src={fiuBlueLogo} style={{ width: '24px', height: '13px' }} alt="FIU" /></React.Fragment>) : citem.logo ? <img src={mdclogoblue} style={{ width: '28px', height: '15px' }} alt="MDC" /> : '' }
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    )
}

const FiuSemesterItem = ({ citem }) => {
    const ref = useRef(null);

    return(
        <Card ref={ref} style={{ margin: '10px' }}>
            <CardHeader style={{ paddingBottom: '0px', borderTop: `8px solid ${getCourseColor(citem.Color)}`, textAlign: 'left' }} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.CoursePrefix !== null ? `${citem.CoursePrefix} ${citem.CourseNumber}` : `${citem.Title}`}</Typography>} subheader={<Typography variant="caption">{citem.Units} credits</Typography>} />
            <CardActions style={{ paddingTop: '0px' }}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Grid item>
                        {/* <Button style={{ textTransform: 'capitalize', fontSize: '0.75em'}} color="primary" onClick={courseClick}>Learn More</Button> */}
                    </Grid>
                    <Grid item>
                        {citem.logo && citem.SheetName === 'TRANSFER' ? (<React.Fragment><img src={mdclogoblue} style={{ width: '28px', height: '15px' }} alt="MDC" /> <img src={fiuBlueLogo} style={{ width: '24px', height: '13px' }} alt="FIU" /></React.Fragment>) : citem.logo ? <img src={fiuBlueLogo} style={{ width: '28px', height: '15px' }} alt="MDC" /> : '' }
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
}

/* const oldFiuSemesterItem = ({ citem }) => {
    const ref = useRef(null);
    
    return(
        <Card ref={ref} style={{ margin: '10px' }}>
            <CardHeader style={{ paddingBottom: '0px'}} title={<Typography variant="h6" style={{fontSize: '.9em'}}>{citem.FIUcourse}</Typography>} subheader={<Typography variant="caption">{citem.FIUhours} hours</Typography>} />
            <CardActions style={{ paddingTop: '0px' }}>
                <Grid container justify="space-between" alignItems="baseline">
                    <Grid item>
                        <Button style={{ textTransform: 'capitalize', fontSize: '0.75em'}} color="primary"></Button>
                    </Grid>
                    <Grid item>
                        {citem.FIUcourse === 'MAC1147' && <span><img src={mdclogoblue} style={{ width: '28px', height: '15px' }} alt="MDC" />  </span>}
                        {fiuwhitelogo && <img src={fiuwhitelogo} style={{ width: '28px', height: '15px' }} alt="FIU" />}
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
} */


const MuiPrintSems = (props) => {

    const { semesters, courses, fiucourses, dualenroll, dualterms, mdcMaxSemester, mdcGrad } = props;

    var counter = 0;

    return(
        <div style={{ display: 'flex', flexFlow: 'wrap' }}>
            {semesters && semesters.sort((a, b) => a.index-b.index).map(s => {

                var colClass = classes.columnHead;

                const curSem = s.index + 1;

                var colMdcTerm = classes.column;

                //var dualEnrollSem = dualenroll && dualterms >= s.index + 1 ? true : false;

                /* if(dualEnrollSem){
                    colClass = classes.columnHeadDualE;
                } */

                if(!mdcGrad && curSem === mdcMaxSemester){
                    colMdcTerm = classes.columnGrad;
                }

                if(mdcGrad && mdcGrad === curSem){
                    colMdcTerm = classes.columnGrad;
                }

                var stotal = 0

                counter++;
               
                return(
                <React.Fragment>
                <SemesterColumn
                key={s.id}
                semester={s.id}
                completed={s.completed}
                >
                    <div style={colMdcTerm}>
                    <div style={colClass} ><Typography style={{ textTransform: 'uppercase' }} variant="h5">{handleTermName(s.termdate)} </Typography></div>
                    <div style={colClass}><FormControlLabel control={<Checkbox style={{ color: '#f73378' }} checked={s.completed} />} label="Completed" /></div>
                    <div style={colClass}><Typography variant='h6'>Total: {s.hours} credits</Typography></div>
                        {
                        courses.filter(item => `s${item.TERM}`&& `s${item.TERM}` === s.id).map(item => {
                            //stotal = stotal + item.hours
                            stotal = stotal + parseInt(item.Units);
                            return(
                            <SemesterItem key={item.id} id={item.id} semcomp={s.completed} citem={item} />
                        )})
                        }
                        {
                            fiucourses.filter(item => `s${item.TERM}`&& `s${item.TERM}` === s.id).map(item => {
                                return (
                                    <FiuSemesterItem key={item.id} semcomp={s.completed} id={item.id} citem={item} />
                                )
                            })
                        }
                    </div>
                </SemesterColumn>
                {counter === 4 || counter === 8 || counter === 12 || counter === 16 || counter === 20 ? <div style={classes.pagebreak} /> : ''}
                </React.Fragment>
            );
            })}
        </div>
    );
}

export default MuiPrintSems;