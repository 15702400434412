import React from 'react';
import axios from 'axios';
import { Route, useHistory, Redirect } from 'react-router-dom';
import LoadingBackdrop from '../../components/LoadingBackdrop'

const Authorized = (props) => {

    const { pathAfterFailure, component, path, exact, ...rest } = props;
    const history = useHistory();

    const [authorized, setAuthorized] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const checkIfAuthorized = async () => {
        var auth = false;
        const authUser = await axios({
                method: 'get',
                url: '/checkrole'
            });
        if(authUser.data){
            console.log(true);
            auth=true;
        }
        return auth;
    }

    React.useEffect(() => {
        checkIfAuthorized().then((res) => {
            setAuthorized(res);
            if(!res){
                history.push('/admin-login')
            }
        })
    }, []);

    React.useEffect(() => {
        setLoading(false);
    }, [authorized])
/* 
    if(loading){
        return (
            <LoadingBackdrop openLoading={loading} />
        )
    }
    console.log(authorized); */

    //console.log(authorized);

    return authorized ? (
        <Route path={path} exact={exact} render={(props) => React.createElement(component, { ...rest, ...props })} />
    ) : (
        <div />
    );

}

export default Authorized