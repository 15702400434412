import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import axios from 'axios';
import { openMuiSnackbar } from '../MuiSnackbar';


const RemoveProgramDialog = (props) => {

    const { openRemoveProgram, userPathways, closeRemoveProgram, currentUser, setUserPathways, setValue, setCurrentPathway, currentPathway } = props;

    const handleClose = () => {
        closeRemoveProgram();
    }

    const handleRemovePTab = async (pathwayId) => {
        //console.log(userPathways);

        const newUserPathways = userPathways.filter(u => u.id !== pathwayId);

        if(currentUser !== null && currentUser !== ''){
            const serverResp = await axios({
                method: 'POST',
                data: {
                    pathwayId: pathwayId
                },
                withCredentials: true,
                url: '/removepathway'
            });
            if(serverResp.data === 'Pathway Removed'){
                openMuiSnackbar({ message: 'Pathway Removed', variant: 'success' });
            } else {
                openMuiSnackbar({ message: 'Error Removing Pathway', variant: 'warning' });
            }
        } else {
            if(currentPathway === pathwayId){
                setCurrentPathway(userPathways[0].id);
                setValue(0);
            }
            setUserPathways(newUserPathways);
            openMuiSnackbar({ message: 'Pathway Removed', variant: 'success' });
        }

        closeRemoveProgram();
    }


    return(
        <Dialog open={openRemoveProgram} onClose={handleClose}>
            <DialogTitle>
                Remove Program Path
            </DialogTitle>
            <List>
                {userPathways.map((pathway) => {
                        return(
                            <ListItem button key={pathway.index} disabled={pathway.index === 0} onClick={() => handleRemovePTab(pathway.id)}>
                                <ListItemText primary={pathway.name} />
                            </ListItem>
                        );
                })}
            </List>
        </Dialog>
    )
}

export default RemoveProgramDialog;