import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import CloseIcon from '@material-ui/icons/Close';

const pathways = [
    {
        value: 'aa',
        label: 'Associate of Arts'
    },
];

const fiupathways = [
    {
        value: 'BIO_BA_DATA',
        label: 'Bachelor of Arts Biology'
    },
    {
        value: 'BIO_BS_DATA',
        label: 'Bachelor of Science Biology'
    },
    {
        value: 'FIU_BS_BIOMEDICAL-ENGINEERING',
        label: 'Bachelor of Science Biomedical Engineering'
    },
    {
        value: 'FIU_BA_CHEMISTRY',
        label: 'Bachelor of Arts Chemistry'
    },
    {
        value: 'FIU_BS_CHEMISTRY',
        label: 'Bachelor of Science Chemistry'
    }
]

const partnerinst = [
    {
        value: 'fiu',
        label: 'Florida International University'
    }
]

const ProgramDialog = (props) => {

    const { openAddProgram, closeProgramCallback, addProgram, partnerPathways } = props;

    const [pathway, setPathway] = React.useState('aa');
    const [fiupathway, setFiuPathway] = React.useState('BIO_BA_DATA');
    const [partner, setPartner] = React.useState('fiu');
    const [shortname, setShortname] = React.useState('');

    const handlePathwayChange = (event) => {
        setPathway(event.target.value);
    } 
    
    const handlePartnerChange = (event) => {
        setPartner(event.target.value);
    }
    
    const handleFiuPathwayChange = (event) => {
        setFiuPathway(event.target.value);
    }

    const handleShortNameChange = (event) => {
        setShortname(event.target.value);
    }

    

    const handleAddClick = () => {
        var defLabel = 'Bachelor of Arts Biology';

        let pickedPath = partnerPathways.find((p) => p.value === fiupathway);

        if(!pickedPath){
            defLabel = 'Bachelor of Arts Biology'
         } else {
             defLabel = pickedPath.label
         }

        /* switch(fiupathway){
            case 'BIO_BA_DATA':
                defLabel = 'Bachelor of Arts Biology';
                break;
            case 'BIO_BS_DATA':
                defLabel = 'Bachelor of Science Biology';
                break;
            case 'FIU_BS_BIOMEDICAL-ENGINEERING':
                defLabel='Bachelor of Science Biomedical Engineering';
                break;
            case 'FIU_BA_CHEMISTRY':
                defLabel = 'Bachelor of Arts Chemistry';
                break;
            case 'FIU_BS_CHEMISTRY':
                defLabel = 'Bachelor of Science Chemistry';
                break;
            default: 
                defLabel = 'Bachelor of Arts Biology';
        } */
        var pack = {
            pathway: pathway,
            partpath: fiupathway,
            partner: partner,
            label: defLabel
        }
        if(shortname !== ''){
           pack = {
               pathway: pathway,
               partpath: fiupathway,
               partner: partner,
               label: shortname
           }
        }
        addProgram(pack);

    }

    return(
        <Dialog open={openAddProgram} onClose={closeProgramCallback}>
            <DialogTitle disableTypography>
                <Grid container justify='space-between'>
                    <Grid item>
                        <Typography variant='h4'>
                            Add Degree Path
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={closeProgramCallback} aria-label='close'>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems="center" justify="center" style={{ marginBottom: '15px' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>Miami Dade College Pathways:</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <TextField 
                            select
                            /* label="Pathway" */
                            value={pathway}
                            onChange={handlePathwayChange}
                        >
                            {pathways.map((opt) => (
                                <MenuItem key={opt.value} value={opt.value}>
                                    {opt.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>Partner Institution:</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <TextField 
                                select
                                /* label="Partner" */
                                value={partner}
                                onChange={handlePartnerChange}
                            >
                                {partnerinst.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </TextField>                
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>Partner Degree Program:</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <TextField 
                                select
                                /* label="Partner Pathway" */
                                value={fiupathway}
                                onChange={handleFiuPathwayChange}
                            >
                                {partnerPathways.map((opt) => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <TextField 
                            label='Custom Name'
                            value={shortname}
                            onChange={handleShortNameChange}
                            style={{ width: '200px' }}
                            id='custom name'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddClick} color='primary' aria-label='add program'>
                    Add
                </Button>
                <Button onClick={closeProgramCallback} color='secondary' aria-label='cancel'>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default ProgramDialog;