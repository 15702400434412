import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import MuiHeader from '../../components/mui-header';
import MuiSnackbar from '../../components/MuiSnackbar';
import MuiProfile from '../../components/mui-profile';

import Copyright from '../../components/Copyright';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import MuiLogin from '../../components/mui-login';

const LoginPage = (props) => {

  const history = useHistory();

  const [openProfile, setOpenProfile] = React.useState(false);
  //const [validUser, setValidUser] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);


  const handleOpenProfile = () => {
    setOpenProfile(true);
  }

  const handleCloseProfile = () => {
    setOpenProfile(false);
  }

  const handleMuiLoginClose = async () => {
    const checkRole = axios({
      method: 'get',
      withCredentials: true,
      url: '/checkrole'
    });

    if(checkRole.data){
      history.push('/admin');
    } else {
      history.push('/');
    }

  }

  const checkUser = async () => {
    const checkCurrentUser = await axios({
      method: 'get',
      withCredentials: true,
      url: '/getuser'
    });
    setCurrentUser(checkCurrentUser.data);
  }

  React.useEffect(() => {
    checkUser();
  }, []);

  React.useEffect(() => {
    //console.log(currentUser);
  }, [currentUser]);
    


    return(
        <React.Fragment>
        <MuiHeader openProfileFunc={handleOpenProfile} currentUser={currentUser} setLoginOpen={setLoginOpen} />
        <Container maxWidth="xl" style={{ marginTop: '20px' }}>
          <MuiProfile open={openProfile} close={handleCloseProfile} currentUser={currentUser} checkUser={checkUser} />
          <MuiLogin open={true} close={handleMuiLoginClose} />
        </Container>
        <Copyright />
        <MuiSnackbar />
      </React.Fragment>
    )

}

export default LoginPage;