import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import CostSpeedo from '../components/cost-speedo';
import MuiPrintSems from '../components/mui-print-sems';
import htmlToImage from 'html-to-image';
import pdfConverter from 'jspdf';

//import { useLocation, useHistory } from 'react-router-dom';
//import { SportsRugbySharp } from '@material-ui/icons';

let defaultDoc

if (typeof window !== `undefined`) {
    defaultDoc = window.document;
} 

const setSemesterChunk = (sems) => {
    var R = [];
    for(var i = 0; i < sems.length; i += 4){
        R.push(sems.slice(i, i + 4));
    }
    return R;
}

const GenerateTempPDF = (props) => {

 /*    const location = useLocation();
    const history = useHistory(); */
    const itemProps = JSON.parse(localStorage.getItem('pdfRef'));
    const { fiucourses, courses, completed, startdate, enddate, semesters, dualenroll, dualterms, floridares, accumcost, accumhours, dualEnrollCourses, mdcGrad, mdcMaxSemester, mdcGradDate, compDate, additionalCost, additionalSavings } = itemProps;

    React.useEffect(() => {
        //setWindowHeight(window.innerHeight);
            genPDF();         
    }, []);

    const genPDF = () => {
        let input = defaultDoc.getElementsByClassName('dash')[0];
        let sems = defaultDoc.getElementsByClassName('semcol');

        const pdf = new pdfConverter({
            orientation: 'landscape',
            unit: 'pt',
            format: 'a4'
        });

        htmlToImage.toPng(input, ).then(async dataUrl => {
            const img = new Image();
            img.src = dataUrl;
            pdf.addImage(dataUrl, 'png', 20, 15, 792, 430);

            var i;

            for(i = 0; i < sems.length; i++){
                await htmlToImage.toPng(sems[i]).then(c => {
                    pdf.addPage('a4', 'l');
                    const simg = new Image();
                    img.src = c;
                    pdf.addImage(c, 'png', 50, 10, 792, 573);
                    if(i === sems.length - 1){
                        pdf.save('Test.pdf');
                        setTimeout(() => {
                            localStorage.removeItem('pdfRef');
                            window.close();
                        }, 100);                        
                    }
                });
            }
        });
    }

    if(!localStorage.getItem("pdfRef")){
        return(<div />)
    }

    

   var nsems = setSemesterChunk(semesters);

    return(
        <div>
            <div style={{ width: '100%', height: '100%', padding: 0, margin: 0, position: 'fixed', zIndex: 1000, background: 'rgba(232,232,232,1)' }}>
                <Grid container justify='center'>
                    <Grid item xs={12} style={{ textAlign: 'center', paddingTop: '250px' }}>
                        <CircularProgress size={200} />
                        <Typography variant='h4'>Generating a PDF...this tab will close when finished</Typography>
                    </Grid>
                </Grid>
            </div>
            <div className='dash' style={{ width: '1056px', height: '580px' }}>
                <CostSpeedo dualEnrollCourses={dualEnrollCourses} fiucourses={fiucourses} courses={courses} progress={completed} startdate={new Date(startdate)} enddate={new Date(enddate)} print={true} dualenroll={dualenroll} dualterms={dualterms} floridares={floridares} accumhours={accumhours} accumcost={accumcost} mdcGrad={mdcGrad} mdcMaxSemester={mdcMaxSemester} mdcGradDate={new Date(mdcGradDate)} compDate={compDate} additionalCost={additionalCost} additionalSavings={additionalSavings} />
            </div>
            {
                    nsems.map((s) => {
                        return(
                            <div className='semcol' style={{ width: '1056px', height: '770px' }}>
                                <MuiPrintSems key={s.id} fiucourses={fiucourses} courses={courses} semesters={s} dualenroll={dualenroll} dualterms={dualterms} mdcMaxSemester={mdcMaxSemester} mdcGrad={mdcGrad} />
                            </div>
                        );
                    })
                }
            <Button onClick={() => genPDF()}>Generate PDF</Button>
        </div>
    )




}

export default GenerateTempPDF;