import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Video from '../video';

import CloseIcon from '@material-ui/icons/Close';

const VideoPlayerDialog = (props) => {

    const { title, videoUrl, open, close, width, height } = props;

    const handleClose = () => {
        close();
    }

    return(
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg'>
            <DialogTitle>
                <Grid container justify='space-between'>
                    <Grid item>
                        {title && 
                        <Typography variant='h3'>{title}</Typography>}
                    </Grid>
                    <Grid item>
                        <IconButton aria-label='close' onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ textAlign: 'center' }}>
                <Video 
                    videoSrcUrl={videoUrl}
                    title={title}
                    width={width}
                    height={height}
                />
            </DialogContent>
        </Dialog>
    )

}

export default VideoPlayerDialog;