import React from 'react';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import MuiQuestions from './mui-questions';
import { openMuiSnackbar } from './MuiSnackbar';


const termlist = [
    {
        value: 8,
        label: 'Fall'
    },
    {
        value: 0,
        label: 'Spring'
    },
    {
        value: 4,
        label: 'Summer'
    }
]


const yearlist = () => {
    var d = new Date();
    var year = d.getFullYear() - 4;
    var narray = [];

    for(var i=0; i<9; i++){
        narray.push(year+i);
    }

    return narray;
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return(
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


const MuiProfile = (props) => {

    const { open, close, currentUser, checkUser, additionalCost, additionalSavings } = props;

    const [value, setValue] = React.useState(0);
    const [userProfile, setUserProfile] = React.useState(null);
    const [test, setTest] = React.useState('');
    const [writingScore, setWritingScore] = React.useState(0);
    const [mathScore, setMathScore] = React.useState(0);
    const [readingScore, setReadingScore] = React.useState(0);
    const [aafScore, setAafScore] = React.useState(0);
    const [testExempt, setTestExempt] = React.useState(false);

    React.useEffect(() => {
        if(userProfile && (currentUser !== null || currentUser !== '')){
            let nCurrentUser = { ...userProfile, profile: { ...userProfile.profile, addCost: additionalCost, addSave: additionalSavings }}
            setUserProfile(nCurrentUser);
            handleUpdateUserAdd(nCurrentUser);
        }
    }, [additionalCost, additionalSavings])

    const handleUpdateUser = async () => {
        const updateUserProfile = await axios({
            method: 'post',
            data: userProfile,
            withCredentials: true,
            url: '/updateprofile'
        });
        if(updateUserProfile.status === 200){
            checkUser();
            openMuiSnackbar({ message: updateUserProfile.data, variant: 'success' });
        } else {
            openMuiSnackbar({ message: updateUserProfile.data, variant: 'error' });
        }        
    }

    const handleUpdateUserAdd = async (nUserPro) => {
        const updateUserProfile = await axios({
            method: 'post',
            data: nUserPro,
            withCredentials: true,
            url: '/updateprofile'
        });
        if(updateUserProfile.status === 200){
            checkUser();
            //openMuiSnackbar({ message: updateUserProfile.data, variant: 'success' });
        } else {
            //openMuiSnackbar({ message: updateUserProfile.data, variant: 'error' });
        }        
    }

    const handleSwitchChange = (event) => {
        //changeDualEnroll(event.target.checked);
        //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, dualEnrollment: event.target.checked }});
    }

    const handleDualTerms = (event, newValue) => {
        //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, dualTerms: newValue }});
        //changeDualTerms(newValue);
    }

    const handleSwitchResidency = (event) => {
        setUserProfile({ ...userProfile, profile: { ...userProfile.profile, floridaResidency: event.target.checked }});
        //changeFloridaRes(event.target.checked);
    }

    const handleForeignLang = (event) => {
        setUserProfile({ ...userProfile, profile: { ...userProfile.profile, foreignLangExempt: event.target.checked }});
        //changeForeignLangExempt(event.target.checked);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleTestChange = (event) => {
        setTest(event.target.value);
        setWritingScore(0);
        setMathScore(0);
        setReadingScore(0);
        setAafScore(0);
        //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, testTaken: event.target.value, writingScore: 0, mathScore: 0 }});
    }

    const handleWScoreChange = (event) => {
        setWritingScore(event.target.value);
        //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, writingScore: event.target.value }});
    }

    const handleMScoreChange = (event) => {
        setMathScore(event.target.value);
        //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, mathScore: event.target.value }});
    }

    const handleRScoreChange = (event) => {
        setReadingScore(event.target.value);
        //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, readingScore: event.target.value }});
    }

    const handleAScoreChange = (event) => {
        setAafScore(event.target.value);
        //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, aafScore: event.target.value }});
    }

    const handleTestExempt = (event) => {
        if(event.target.checked){
            setTest('None')
            setWritingScore(0);
            setReadingScore(0);
            setMathScore(0);
            setAafScore(0);
            //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, testTaken: 'None', testExempt: event.target.checked, writingScore: 0, mathScore: 0 }});
        } else {
            setTest('ACT');
            setWritingScore(0);
            setMathScore(0);
            setReadingScore(0);
            setAafScore(0);
            //setUserProfile({ ...userProfile, profile: { ...userProfile.profile, testTaken: 'ACT', testExempt: event.target.checked, writingScore: 0, mathScore: 0 }});
        }        
    }

    const handleNameChange = (event) => {
        setUserProfile({ ...userProfile, fullName: event.target.value });
    }

    const handleTermChange = (event) => {
        setUserProfile({ ...userProfile, profile: { ...userProfile.profile, startTerm: event.target.value }});
    }

    const handleYearChange = (event) => {
        setUserProfile({ ...userProfile, profile: { ...userProfile.profile, startYear: event.target.value }});
    }

    const handleAddToTestArray = (testToAdd) => {
        let newTestArray = [];
        userProfile.profile.testsTaken.map((a) => newTestArray.push(a));
        newTestArray.push(testToAdd);
        setUserProfile({ ...userProfile, profile: { ...userProfile.profile, testsTaken: newTestArray }})
        //setTestArray(newTestArray);
        handleModTest('None');
    }

    const handleRemoveFromTestArray = (testToRemove) => {
        let newArr = userProfile.profile.testsTaken.filter((t) => t.Test !== testToRemove.Test);
        setUserProfile({ ...userProfile, profile: { ...userProfile.profile, testsTaken: newArr }})
        //setTestArray(newArr);
    }

    const handleModTest = (test) => {
        if(!testExempt){
            setTest(test);
            setWritingScore(0);
            setMathScore(0);
            setReadingScore(0);
            setAafScore(0);
        };
    }

    React.useEffect(() => {
        if(currentUser && currentUser.email){
            let nCurrentUser = { ...currentUser, profile: { ...currentUser.profile, addCost: additionalCost, addSave: additionalSavings }}
            
            setUserProfile(nCurrentUser);
        }        
    }, [currentUser]);

    React.useEffect(() => {
        if(currentUser && currentUser.email){
            let nCurrentUser = { ...currentUser, profile: { ...currentUser.profile, addCost: additionalCost, addSave: additionalSavings }}
            setUserProfile(nCurrentUser);
        }
    }, [open])

    //console.log(userProfile);

    return(
        <Dialog open={open} onClose={close} maxWidth='md' fullWidth>
            <DialogTitle>
                <Grid container justify='space-between'>
                    <Grid item>
                        User Profile
                    </Grid>
                    <Grid item>
                        <IconButton color='secondary' onClick={close}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <AppBar position='static' style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', backgroundColor: 'rgb(220, 220, 220)', color: '#383838', flexDirection: 'row' }}>
                    <Tabs value={value} onChange={handleChange} indicatorColor='primary'>
                        <Tab style={{ textTransform: 'capitalize' }} label='General' {...a11yProps(0)} />
                        <Tab style={{ textTransform: 'capitalize' }} label='Testing' {...a11yProps(1)} />
                        <Tab style={{ textTransform: 'capitalize' }} label='Additional Information' {...a11yProps(2)} />
                        <Tab style={{ textTransform: 'capitalize' }} label='Programs' {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <TextField 
                        label='Name'
                        value={userProfile && userProfile.fullName}
                        onChange={handleNameChange}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {userProfile && 
                    <MuiQuestions open={true} testArray={userProfile.profile.testsTaken} handleAddToTestArray={handleAddToTestArray} handleRemoveFromTestArray={handleRemoveFromTestArray} test={test} handleTestChange={handleTestChange} writingScore={writingScore} handleWScoreChange={handleWScoreChange} handleMScoreChange={handleMScoreChange} mathScore={mathScore} exempt={testExempt} handleExempt={handleTestExempt} readingScore={readingScore} handleRScoreChange={handleRScoreChange} aafScore={aafScore} handleAafScoreChange={handleAScoreChange} />}
                </TabPanel>
                <TabPanel value={value} index={2}>
                {userProfile &&
                    <Grid container alignItems="center" justify='center' spacing={3}>
                        <Grid item container xs={4} style={{ marginBottom: '10px' }}>
                            <Paper style={{ padding: '20px', width: '100%' }}>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        Florida Residency
                                    </Typography>
                                    <FormControlLabel 
                                        control={<Switch color='primary' checked={userProfile && userProfile.profile.floridaResidency} onChange={handleSwitchResidency} name='Florida Resident' />}
                                        label={userProfile && userProfile.profile.floridaResidency ? 'Florida Resident' : 'Non-Florida Resident'}
                                    />
                                    <Button color='primary' target='_blank' href='https://www.mdc.edu/admissions/tuition/florida-residency.aspx'>
                                        More Info
                                    </Button>
                                </Grid>
                            </Paper>
                        </Grid>
                        {/* <Grid item container xs={4}>
                            <Paper style={{ padding: '20px' }}>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                        Were you a Dual Enrollment student?
                                    </Typography>
                                    <FormControlLabel 
                                        control={<Switch color='primary' checked={userProfile && userProfile.profile.dualEnrollment} onChange={handleSwitchChange} name='Dual Enrollment' />}
                                        label={userProfile && userProfile.profile.dualEnrollment ? 'Yes' : 'No'}
                                    />
                                    <Button color='primary' target='_blank' href='https://www.mdc.edu/future-students/high-school/dual-enrollment.aspx'>
                                        More Info
                                    </Button>
                                </Grid>
                            </Paper>
                        </Grid> */}
                        <Grid item container xs={4}>
                            <Paper style={{ padding: '20px', width: '100%' }}>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant='body1' style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                                        Foreign Language Competence and Equivalence
                                    </Typography>
                                    <FormControlLabel 
                                        control={<Switch color='primary' checked={userProfile.profile.foreignExempt} onChange={handleForeignLang} />}
                                        label={userProfile.profile.foreignExempt ? 'Yes' : 'No' }
                                    />
                                    <Button color='primary' target='_blank' href='https://www.mdc.edu/main/testing/criteria/foreign_language_competence.aspx#:~:text=Foreign%20Language%20Equivalence,the%20Foreign%20Language%20Competence%20requirement'>
                                        More Info
                                    </Button>
                                </Grid>
                            </Paper>
                        </Grid>            
                    </Grid>
                    }
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Grid container justify='space-between'>
                        <Grid item container justify='center' xs={6}>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant='h6' style={{ textDecoration: 'underline' }}>
                                    Start Term &amp; Year
                                </Typography>
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={3} style={{ textAlign: 'center' }}>
                                <Typography variant='body1'>
                                    Start Term
                                </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'center' }}>
                                <Typography variant='body1'>
                                    Start Year
                                </Typography>
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={3} />
                            <Grid item xs={3} style={{ textAlign: 'center' }}>
                                <TextField
                                    select
                                    value={userProfile && userProfile.profile.startTerm}
                                    onChange={handleTermChange}
                                >
                                    {termlist.map((opt) => (
                                        <MenuItem key={opt.value} value={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'center' }}>
                                <TextField
                                    select
                                    value={userProfile && userProfile.profile.startYear}
                                    onChange={handleYearChange}
                                >
                                    {yearlist().map((opt) => (
                                        <MenuItem key={opt} value={opt}>
                                            {opt}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'center' }}>
                            <Typography variant='h6' style={{ textDecoration: 'underline' }}>
                                Programs
                            </Typography>
                        </Grid>
                    </Grid>
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleUpdateUser} color='primary' variant='contained' style={{ textTransform: 'capitalize' }}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MuiProfile;