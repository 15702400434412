import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import Copyright from '../../components/Copyright';
import MuiHeader from '../../components/mui-header';
import MuiSnackbar from '../../components/MuiSnackbar';
import Container from '@material-ui/core/Container';
import PasswordResetForm from '../../components/PasswordResetForm';

const PasswordReset = (props) => {

    let { token } = useParams();

    const [openProfile, setOpenProfile] = React.useState(false);
    const [loginOpen, setLoginOpen] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(null);

    const handleOpenProfile = () => {
        setOpenProfile(true);
    }

    const handleCloseProfile = () => {
        setOpenProfile(false);
    }

    const checkUser = async () => {
        const checkCurrentUser = await axios({
            method: 'get',
            withCredentials: true,
            url: '/getuser'
        });
        setCurrentUser(checkCurrentUser.data);
    }

    React.useEffect(() => {
        checkUser();
    }, []);

    return(
        <React.Fragment>
            <MuiHeader openProfileFunc={handleOpenProfile} currentUser={currentUser} setLoginOpen={setLoginOpen} />
            <Container maxWidth='md' style={{ marginTop: '20px' }}>
                <PasswordResetForm token={token} />
            </Container>
            <MuiSnackbar />
            <Copyright />
        </React.Fragment>
    )
}

export default PasswordReset;