import React from 'react';
import { Redirect } from 'react-router-dom';
import Index from '../pages/index';


const PrivateRoute = ({ component, ...rest }) => {

    const [routeLoading, setRouteLoading] = React.useState(true);
    const [redirect, setRedirect] = React.useState(false);

    React.useEffect(() => {
        fetch('/checkToken').then(res => {
            if(res.status === 200){
                setRouteLoading(false);
            } else {
                const error = new Error(res.error);
                throw error;
            }
        }).catch(err => {
            console.log(err);
            setRouteLoading(false);
            setRedirect(true);
        });
    }, [])

    if(routeLoading){
        return '';
    }

    if(redirect){
        return <Redirect to='/login' />
    }

    return <Index />
}

export default PrivateRoute;