import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import CloseIcon from '@material-ui/icons/Close';

const savingsList = [
    {
        id: 0,
        value: 'DE',
        label: 'Dual Enrollment (DE)'
    },
    {
        id: 1,
        value: 'AICE',
        label: 'Association of International Credential Evaluators (AICE)'
    },
    {
        id: 2,
        value: 'AP',
        label: 'Advanced Placement (AP)'
    },
    {
        id: 3,
        value: 'CART',
        label: 'Center for Advanced Research and Technology (CART)'
    },
    {
        id: 4,
        value: 'CLEP',
        label: 'College-Level Examination Program (CLEP)'
    },
    {
        id: 5,
        value: 'CSP',
        label: 'Computer Skills Placement (CSP)'
    },
    {
        id: 6,
        value: 'DLPT',
        label: 'Defense Language Proficiency Test (DLPT)'
    },
    {
        id: 7,
        value: 'DSST',
        label: 'DSST (DANTES)'
    },
    {
        id: 8,
        value: 'IB',
        label: 'International Baccalaureate (IB)'
    },
    {
        id: 9,
        value: 'Uexcel',
        label: 'UExcel Examinations'
    },    
    {
        id: 10,
        value: 'Exempt',
        label: 'Exemption (Exempt)'
    },
    {
        id: 11,
        value: 'ACTFL',
        label: 'American Council on the Teaching of Foreign Languages (ACTFL)'
    },
    {
        id: 12,
        value: 'CAPE',
        label: 'Caribbean Advanced Proficiency Examination Program (CAPE)'
    }
]

const ExemptDialog = (props) => {

    const { openAddSavings, closeSavingsCallback, addSavings, activeCostSavings } = props;

    const [selectedSavings, setSelectedSavings] = React.useState('DE');

    const handleSavingsChange = (event) => {
        setSelectedSavings(event.target.value);
    }

    React.useEffect(() => {

        for(let i=0; i < savingsList.length; i++){
            //let rList = savingsList.reverse();
            let inSavings = activeCostSavings.find(a => a.id === savingsList[i].id);
            if(!inSavings){
                setSelectedSavings(savingsList[i].value);
                break;
            }
        }
    }, [openAddSavings])

    const handleAddClick = () => {

        let selSavings = savingsList.find(s => s.value === selectedSavings);

        addSavings(selSavings);
    }

    return(
        <Dialog open={openAddSavings} onClose={closeSavingsCallback} fullWidth={true} maxWidth='sm' >
            <DialogTitle disableTypography>
                <Grid container justify='space-between'>
                    <Grid item>
                        <Typography variant='h4'>
                            Add Savings Option
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={closeSavingsCallback} aria-label='close'>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container alignItems="center" justify="center" style={{ marginBottom: '15px' }}>
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant='body1' style={{ fontWeight: 'bold' }}>Miami Dade College Savings Options:</Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <TextField 
                            select
                            value={selectedSavings}
                            onChange={handleSavingsChange}
                        >
                            {savingsList.map((opt) => {
                               let alreadyExempt = activeCostSavings.find(s => s.id === opt.id);
                               if(!alreadyExempt){
                                    return (
                                        <MenuItem key={opt.value} value={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    )    
                               }
                               })}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddClick} color='primary' aria-label='add program'>
                    Add
                </Button>
                <Button onClick={closeSavingsCallback} color='secondary' aria-label='cancel'>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default ExemptDialog;