import React from 'react';
import Copyright from '../components/Copyright';
import MuiHeader from '../components/mui-header';
//import MuiQuestions from '../components/mui-questions';
import CourseMatrix from '../components/mui-coursematrix';
import Container from '@material-ui/core/Container';
import MuiProfile from '../components/mui-profile';
//import MuiFooter from '../components/mui-footer';
import MuiSnackbar from '../components/MuiSnackbar';
import axios from 'axios';
import LoadingBackdrop from '../components/LoadingBackdrop';
import MaxCreditAlertDialog from '../components/MaxCreditAlertDialog';

export default function Index() {

  const [openProfile, setOpenProfile] = React.useState(false);
  //const [validUser, setValidUser] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [additionalCost, setAdditionalCost] = React.useState(0);
  const [additionalSavings, setAdditionalSavings] = React.useState(0);


  const handleOpenProfile = () => {
    setOpenProfile(true);
  }

  const handleCloseProfile = () => {
    setOpenProfile(false);
  }

  const checkUser = async () => {
    const checkCurrentUser = await axios({
      method: 'get',
      withCredentials: true,
      url: '/getuser'
    });
    setCurrentUser(checkCurrentUser.data);
  }

  React.useEffect(() => {
    checkUser();
  }, []);

  React.useEffect(() => {
    //console.log(currentUser);
  }, [currentUser]);

  return (
      <React.Fragment>
        <MuiHeader openProfileFunc={handleOpenProfile} currentUser={currentUser} setLoginOpen={setLoginOpen} />
        <Container maxWidth="xl" style={{ marginTop: '20px' }}>
          <MuiProfile open={openProfile} close={handleCloseProfile} currentUser={currentUser} checkUser={checkUser} additionalCost={additionalCost} setAdditionalCost={setAdditionalCost} additionalSavings={additionalSavings} setAdditionalSavings={setAdditionalSavings} />
          <CourseMatrix additionalCost={additionalCost} setAdditionalCost={setAdditionalCost} additionalSavings={additionalSavings} setAdditionalSavings={setAdditionalSavings} loading={loading} setLoading={setLoading} checkUser={checkUser} currentUser={currentUser} loginOpen={loginOpen} setLoginOpen={setLoginOpen}  />
        </Container>
        <Copyright />
        <MuiSnackbar />
        <MaxCreditAlertDialog />
        <LoadingBackdrop openLoading={loading} />
      </React.Fragment>
  );
}
