import React from 'react';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { openMuiSnackbar } from '../MuiSnackbar';
import { useHistory } from 'react-router-dom';

const PasswordResetForm = (props) => {

    const { token } = props;

    const history = useHistory();

    const [passwordFields, setPasswordFields] = React.useState({
        password: '',
        confirmPassword: ''
    });

    const changePasswordFields = event => {
        setPasswordFields({ ...passwordFields, [event.target.name]: event.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(passwordFields.password === passwordFields.confirmPassword){
            const resetUserPass = await axios({
                method: 'post',
                data: {
                    password: passwordFields.password,
                    token: token
                },
                withCredentials: true,
                url: '/userpassreset'
            });
            if(resetUserPass.data === 'Token Expired'){
                openMuiSnackbar({ message: 'Token has expired.  Please reset password again to receive a new token', variant: 'warning' });
                setPasswordFields({
                    password: '',
                    confirmPassword: ''
                })
            }
            if(resetUserPass.data === 'Success'){
                openMuiSnackbar({ message: 'Password has been reset!', variant: 'success' });
                setPasswordFields({
                    password: '',
                    confirmPassword: ''
                });;
                history.push('/');
            }
        } else {
            openMuiSnackbar({ message: 'Passwords do not match!', variant: 'warning' });
        }
    }

    return(
        <Grid container spacing={0} direction='column' alignItems='center' justify='center' style={{ minHeight: '50vh' }}>
            <Grid item xs={6}>
                <Paper style={{ padding: '20px' }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Typography variant='h5'>
                                    Reset a new Password
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <TextField 
                                    label='New Password'
                                    type='password'
                                    name='password'
                                    onChange={changePasswordFields}
                                    value={passwordFields.password}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <TextField 
                                    label='Confirm Password'
                                    type='password'
                                    name='confirmPassword'
                                    onChange={changePasswordFields}
                                    value={passwordFields.confirmPassword}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    type='submit'
                                >Reset Password</Button>
                            </Grid>
                        </Grid>
                    </form>           
                </Paper>
            </Grid>
        </Grid>
    )
}

export default PasswordResetForm;