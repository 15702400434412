import React from 'react';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import XLSX from 'xlsx';
import { openMuiSnackbar } from '../MuiSnackbar';

import MDCDropzone from '../MDCDropzone';

const handleReadingWorkbook = (file) => {

    return new Promise((resolve, reject) => {
        let reader = new FileReader();
    
        reader.readAsArrayBuffer(file[0]);

        let workbookArray = [];

        const sheets = [
            'ACSG',
            'BCSG',
            'ALT',
            'TRANSFER',
            'DEV'
        ]

        reader.onload = function(e){

            let data = new Uint8Array(e.target.result);

            let workbook = XLSX.read(data, { type: 'array' });    

            const readSheet = (worksheet) => {
                var result = [];
                var row;
                var rowNum;
                var colNum;
                var range = XLSX.utils.decode_range(worksheet['!ref']);
                for(rowNum = range.s.r; rowNum <= range.e.r; rowNum++){
                    row = [];
                    for(colNum=range.s.c; colNum<=range.e.c; colNum++){
                        var nextCell = worksheet[
                            XLSX.utils.encode_cell({r: rowNum, c: colNum})
                        ];
                        if( typeof nextCell === 'undefined' ){
                            row.push(void 0);
                        } else row.push(nextCell.w);
                    }
                    result.push(row);
                }
                return result;
            }

            sheets.map((sheet) => {

                let worksheet = workbook.Sheets[sheet];

                let sheetResults = readSheet(worksheet);

                let rowCount = 1;

                sheetResults.map((row) => {

                    let tempObj = {
                        sheetName: sheet,
                        PERT: row[0],
                        ACCP: row[1],
                        ACCPaaf: row[2],
                        ACT: row[3],
                        SAT: row[4],
                        testp: row[5],
                        costSaving: row[6],
                        courseSub: row[11],
                        aBehav: row[7],
                        bBehav: row[8],
                        reqGrad: row[10],
                        exempt: row[43] === 'YES' ? true : false,
                        preReq: row[12],
                        coReq: row[13],
                        postReq: row[14],
                        term: row[15],
                        resCost: row[16],
                        nonResCost: row[17],
                        transfer: row[18] === 'YES' ? true : false,
                        color: row[19],
                        coursePrefix: row[20],
                        courseNumber: row[21],
                        title: row[23],
                        component: row[24],
                        courseId: row[25],
                        units: row[26],
                        description: row[27],
                        prerequisite: row[28],
                        corequisite: row[29],
                        url: row[30],
                        exemption: row[31],
                        logo: true, 
                        videoUrl: row[32],
                        DE: row[33] === 'YES' ? true : false,
                        AICE: row[34] === 'YES' ? true : false,
                        AP: row[35] === 'YES' ? true : false,
                        CART: row[36] === 'YES' ? true : false,
                        CLEP: row[37] === 'YES' ? true : false,
                        CSP: row[38] === 'YES' ? true : false,
                        DLPT: row[39] === 'YES' ? true: false,
                        DSST: row[40] === 'YES' ? true : false,
                        IB: row[41] === 'YES' ? true : false,
                        Uexcel: row[42] === 'YES' ? true : false,
                        del: row[22] === 'YES' ? true : false,
                        fBehav: row[9],
                        ACTFL: row[44] === 'YES' ? true : false,
                        CAPE: row[45] === 'YES' ? true : false
                    }

                    if(rowCount !== 1 && tempObj.title !== ''){
                        workbookArray.push(tempObj);
                    }

                    rowCount++;
                });
            });
        }

        reader.onloadend = () => resolve(workbookArray);


        });

    

    
}


const UploadDialog = (props) => {

    const { open, close, pathway, add, partner } = props;

    const [thisPathway, setThisPathway] = React.useState({});
    const [filePathway, setFilePathway] = React.useState([]);

    React.useEffect(() => {
        if(!add){
            setThisPathway(pathway);
        } else {
            let nPathway = {
                instituteId: partner.id,
                instValue: '',
                instLabel: ''
            }
            setThisPathway(nPathway);
        }
        
    }, [partner, pathway]);


    //console.log(thisPathway);

    const handleClose = () => {
        setFilePathway([]);
        close();
    }

    const handleChange = (event, field) => {
        setThisPathway({ ...thisPathway, [field]: event.target.value });
    }

    const handleFile = async (file) => {

        let fileData = await handleReadingWorkbook(file);

        setFilePathway(fileData);
        /* var formData = new FormData();
        formData.append('file', file[0]);
        formData.append('fileName', pathway.fileName)

        axios({
            method: 'post',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            withCredentials: true,
            url: '/pathwayupload'
        }).then((res)=>{
            //console.log(res);
        }).catch((err) => {
            console.log(err);
        }) */
    }

    const handleClearFilePathway = () => {
        setFilePathway([]);
    }

    const handleUpdateClick = () => {
        if(thisPathway.instLabel.trim() === ''){
            openMuiSnackbar({ message: 'Pathway Name is Required', variant: 'warning' });
            return;
        }
        if(thisPathway.instValue.trim() === ''){
            openMuiSnackbar({ message: 'Pathway Custom Value is Required', variant: 'warning' });
            return;
        }

        let sendData = {
            instituteId: thisPathway.instituteId,
            instValue: thisPathway.instValue,
            instLabel: thisPathway.instLabel,
            instData: filePathway
        }

        axios({
            method: 'put',
            data: sendData,
            withCredentials: true,
            url: `/partnerpathway/${thisPathway.instValue}`
        }).then((res) => {
            if(res.status === 200){
                openMuiSnackbar({ variant: 'success', message: res.data });
                handleClose();
            } else {
                openMuiSnackbar({ variant: 'error', message: res.data });
            }
        }).catch((err) => {
            console.log(err);
        })
        
    }

    const handleAddClick = () => {
        if(thisPathway.instLabel.trim() === ''){
            openMuiSnackbar({ message: 'Pathway Name is Required', variant: 'warning' });
            return;
        }
        if(thisPathway.instValue.trim() === ''){
            openMuiSnackbar({ message: 'Pathway Custom Value is Required', variant: 'warning' });
            return;
        }
        if(filePathway.length === 0){
            openMuiSnackbar({ message: 'Data File Required', variant: 'warning' });
            return;
        }

        let sendData = {
            instituteId: thisPathway.instituteId,
            instValue: thisPathway.instValue,
            instLabel: thisPathway.instLabel,
            instData: filePathway
        }

        axios({
            method: 'post',
            data: sendData,
            withCredentials: true,
            url: '/addpartnerpathways'
        }).then((res) => {
            if(res.status === 200){
                openMuiSnackbar({ variant: 'success', message: res.data });
                handleClose();
            } else {
                openMuiSnackbar({ variant: 'error', message: res.data });
            }            
        }).catch((err) => {
            console.log(err);
        })
        
    }

    if(!open){
        return(
            <div />
        )
    }


    return(
        <Dialog open={open} onClose={handleClose} maxWidth='md'>
            <DialogTitle>
                {add ? `New Pathway for ${partner.instname}` : `Edit Pathway for ${partner.instname}`}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {thisPathway && <TextField label='Pathway Name' value={thisPathway.instLabel} onChange={(event) => handleChange(event, 'instLabel')} fullWidth />}
                    </Grid>
                    <Grid item xs={12}>
                        {thisPathway && <TextField disabled={add ? false : true} label='Pathway Custom Value' value={thisPathway.instValue} onChange={(event) => handleChange(event, 'instValue')} fullWidth />}
                    </Grid>
                    <Grid item xs={12}>
                        {filePathway.length > 0 ? <Button variant='contained' color='secondary' onClick={handleClearFilePathway}>Data Loaded - Click to Remove</Button> : <MDCDropzone fileReturn={handleFile} /> }
                    </Grid>
                </Grid>                
            </DialogContent>
            <DialogActions>
                {add ? <Button variant='contained' onClick={handleAddClick}>Add</Button> : <Button variant='contained' onClick={handleUpdateClick}>Update</Button>}
            </DialogActions>
        </Dialog>
    )

}

export default UploadDialog;